import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import PremiumAmount from '../elements/Values/PremiumAmount';
import DateShort from '../elements/DateTime/DateShort';
import DetailsIcon from '../elements/Icons/DetailsIcon';
import PremiumAmountSum from '../elements/Values/PremiumAmountSum';
import SwitchButton from '../elements/Form/SwitchButton';
import StickerInfoButton from './NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from './NavigationButtons/GreenCardInfoButton';
import {arrayUnique} from '../../utils.js';

const InstallmentsListWidget = ({
    product,
    installments,
    onSelectedInstallmentsChange,
    disabled,
    readOnly,
    detailed,
    select,
    onlyUnpaid,
}) => {
    const firstUnpaidInstallment = useMemo(() => installments.filter(installment =>
        !installment.paidPremiumFromClient && installment.payable
    ).shift(), [installments]);

    const [selectedInstallmentIds, setSelectedInstallmentIds] = useState(
        firstUnpaidInstallment && !select ?
        [firstUnpaidInstallment.id] : []);

    const changeSelectedInstallments = useCallback(installmentIds => {
        setSelectedInstallmentIds(installmentIds);
        onSelectedInstallmentsChange && onSelectedInstallmentsChange(installments.filter(installment =>
            installmentIds.includes(installment.id)));
    }, [installments, onSelectedInstallmentsChange]);

    const isChecked = useCallback(
        index => selectedInstallmentIds.includes(installments[index].id),
        [installments, selectedInstallmentIds]
    );

    useEffect(() => {
        onSelectedInstallmentsChange && onSelectedInstallmentsChange(
            firstUnpaidInstallment && !select ? [firstUnpaidInstallment] : []
        );
    }, [firstUnpaidInstallment, select, onSelectedInstallmentsChange]);

    const hasTax = product.insuranceType !== 'life';
    const hasMtplCover = product.hasMtplCover;
    const hasGf = hasMtplCover || product.hasPassengersAccidentCover;

    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    <th>№</th>
                    <th>Падеж</th>
                    {detailed && <>
                        <th>Покритие</th>
                        <th>Премия</th>
                        {hasTax && <th>Данък</th>}
                        {hasMtplCover && <th>Стикер</th>}
                        {hasGf && <th>ГФ/ОФ</th>}
                    </>}
                    <th>Сума</th>
                    <th>{readOnly ? 'Платено' : 'Статус'}</th>
                </tr>
            </thead>
            <tbody>
                {installments.map((installment, index) => {
                    const checked = isChecked(index);
                    const disallowed = checked ? (index < installments.length - 1 && isChecked(index + 1)) : (
                        installment !== firstUnpaidInstallment && index > 0 && !isChecked(index - 1)
                    );

                    const paidText = (() => {
                        if (installment.paidPremiumFromClient && !select) {
                            return <>
                                <i className="fas fa-check-circle text-success"/> {readOnly ? 'Да' : 'Изцяло платена'}
                            </>;
                        }

                        if (installment.paidAmount > 0 && !select) {
                            return <>
                                <i className="fas fa-exclamation-triangle text-warning"/> {readOnly ?
                                    <PremiumAmount amount={installment.paidAmount} /> :
                                    'Частично платена'}
                                    <br />
                            </>;
                        }

                        return null;
                    })();

                    const payNowText = (() => {
                        if (installment.paidPremiumFromClient || select) {
                            return null;
                        }

                        if (!installment.payable && installment.number > 1) {
                            return <>
                                <i className="fas fa-exclamation-triangle text-warning"/> Не може да се плати в момента
                            </>;
                        }

                        return 'Плащане сега';
                    })();

                    const payButton = (() => {
                        if (!installment.paidPremiumFromClient && installment.payable && !installment.automaticPayment && !select) {
                            return <SwitchButton
                                disabled={disabled || disallowed}
                                checked={checked}
                                onChange={checked => changeSelectedInstallments(checked ?
                                    // add to array
                                    arrayUnique([
                                        ...selectedInstallmentIds,
                                        installment.id,
                                    ]) :
                                    // remove from array
                                    selectedInstallmentIds.filter(v => v !== installment.id),
                                )}
                            />;
                        }
                    })();

                    const selectButton = (() => {
                        if (select && ((!installment.paidPremiumFromClient && onlyUnpaid) || !onlyUnpaid)) {
                            return <SwitchButton
                                disabled={disabled}
                                checked={false}
                                onChange={checked => changeSelectedInstallments(checked ?
                                    // add to array
                                    arrayUnique([
                                        ...selectedInstallmentIds,
                                        installment.id,
                                    ]) :
                                    // remove from array
                                    selectedInstallmentIds.filter(v => v !== installment.id),
                                )}
                            />;
                        }
                    })();

                    return <React.Fragment key={installment.id}>
                        <tr>
                            <td>{installment.number}</td>
                            <td><DateShort date={installment.dueDate}/></td>
                            {detailed && <>
                                <td>
                                    <DateShort date={installment.beginDate}/> - <DateShort date={installment.endDate}/>
                                </td>
                                <td><PremiumAmount amount={installment.premiumAmount} /></td>
                                {hasTax && <td><PremiumAmount amount={installment.taxAmount} /></td>}
                                {hasMtplCover && <td><PremiumAmount amount={installment.stickerAmount} /></td>}
                                {hasGf && <td><PremiumAmount amount={installment.guaranteeFundAmount} /></td>}
                            </>}
                            <td style={{whiteSpace: 'nowrap'}}>
                                <PremiumAmount amount={installment.totalAmount} />{' '}
                                <DetailsIcon details={installment.details} />
                            </td>
                            {readOnly ?
                                <td>{paidText || 'Не'}</td> :
                                <td>{paidText} {payNowText} {payButton} {selectButton}</td>
                            }
                        </tr>
                        {installment.stickers && installment.greenCards && (
                            installment.stickers.length > 1 || installment.greenCards.length > 1
                        ) && <>
                            <tr />
                            <tr>
                                <td colSpan={4 + (detailed ? (3 + (hasMtplCover ? 1 : 0) + (hasGf ? 1 : 0)) : 0)}>
                                    {installment.stickers.length > 1 && installment.stickers.map(sticker => <>
                                        <StickerInfoButton key={sticker.id} sticker={sticker} />{' '}
                                    </>)}
                                    {installment.greenCards.length > 1 && installment.greenCards.map(greenCard => <>
                                        <GreenCardInfoButton greenCard={greenCard} />{' '}
                                        {greenCard.policyNumber && <>
                                            <GreenCardInfoButton greenCard={greenCard} textField="policyNumber" />{' '}
                                        </>}
                                    </>)}
                                </td>
                            </tr>
                        </>}
                    </React.Fragment>;
                })}
                {detailed && <tr>
                    <th colSpan={3} className="text-end">Общо:</th>
                    <th><PremiumAmountSum arr={installments} field="premiumAmount" /></th>
                    {hasTax && <th><PremiumAmountSum arr={installments} field="taxAmount" /></th>}
                    {hasMtplCover && <th><PremiumAmountSum arr={installments} field="stickerAmount" /></th>}
                    {hasGf && <th><PremiumAmountSum arr={installments} field="guaranteeFundAmount" /></th>}
                    <th><PremiumAmountSum arr={installments} field="totalAmount" /></th>
                    <th />
                </tr>}
            </tbody>
        </Table>
    </div>);
};

InstallmentsListWidget.defaultProps = {
    disabled:   false,
    readOnly:   false,
    detailed:   false,
    select:     false,
    onlyUnpaid: false,
};

InstallmentsListWidget.propTypes = {
    product: PropTypes.shape({
        hasMtplCover: PropTypes.bool.isRequired,
        hasPassengersAccidentCover: PropTypes.bool.isRequired,
    }).isRequired,
    installments: PropTypes.arrayOf(PropTypes.shape({
        number: PropTypes.number.isRequired,
        paidPremiumFromClient: PropTypes.bool,
        dueDate: PropTypes.string,
    })).isRequired,
    onSelectedInstallmentsChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    detailed: PropTypes.bool,
    select: PropTypes.bool,
    onlyUnpaid: PropTypes.bool,
};

export default InstallmentsListWidget;
