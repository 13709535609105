import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';
import {Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import {format} from 'date-fns';
import OfficeSelector from "../../elements/Form/OfficeSelector.jsx";
import SubmitButton from "../../elements/Form/SubmitButton.jsx";

const DateRangeSelectionWidget = ({
    max,
    onSubmit,
}) => {
    const formName = 'receiveFormDateRange';
    const initialFormValues = useFormPersistedValues(formName);
    const todayFormatted = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

    return (
        <Formik initialValues={initialFormValues || {
            startDateTime: todayFormatted,
            endDateTime: todayFormatted,
            office: null,
        }} onSubmit={onSubmit}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-xl-4 g-4 align-items-end">
                        <Col>
                            <FormLabel htmlFor="startDateTime">Начална дата</FormLabel>
                            <FormControl as={Field} type="date" name="startDateTime" id="startDateTime"
                                         max={max} disabled={isSubmitting} />
                        </Col>
                        <Col>
                            <FormLabel htmlFor="endDateTime">Крайна дата</FormLabel>
                            <FormControl as={Field} type="date" name="endDateTime" id="endDateTime"
                                         max={max} disabled={isSubmitting} />
                        </Col>
                        <Col>
                            <OfficeSelector isClearable={true} />
                        </Col>
                        <Col>
                            <SubmitButton>Покажи</SubmitButton>
                        </Col>
                    </Row>
                    <AutoPersistToken formName={formName}/>
                </Container>
            )}
        </Formik>
    );
};

DateRangeSelectionWidget.defaultProps = {
    max: format(new Date(), 'yyyy-MM-dd'),
}

DateRangeSelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    max: PropTypes.string,
};

export default DateRangeSelectionWidget;
