import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';
import {formatUabCardNumber} from '../../../utils';
import UabCardNumber from '../../elements/Values/UabCardNumber';

const UabCardInfoButton = ({
    uabCard,
    textField,
    small,
}) => {
    return (<NavigationButton link={'/uab-card/' + uabCard.id} small={small}
                              copyText={formatUabCardNumber(uabCard.number)}>
        {textField && !!uabCard[textField] ? uabCard[textField] : <UabCardNumber {...uabCard} />}
    </NavigationButton>);
};

UabCardInfoButton.defaultProps = {
    small: false,
};

UabCardInfoButton.propTypes = {
    uabCard: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }),
    textField: PropTypes.string,
    small: PropTypes.bool,
};

export default UabCardInfoButton;
