import {getVehicleUrl} from '../../../url';
import PrintButton from '../ReactBootstrap/PrintButton';
import React from 'react';
import {printVehicle} from '../../../print';
import PropTypes from 'prop-types';

const VehiclePrintButton = ({
    vehicle,

    ...props
}) => {
    return (
        <PrintButton icon="file-alt" printAction={() => printVehicle(vehicle.id)}
            viewUrl={getVehicleUrl(vehicle.id)}
            downloadUrl={getVehicleUrl(vehicle.id, true)}
            objId={vehicle.id} {...props} />
    );
};

VehiclePrintButton.defaultProps = {
    label: 'Печат на СУМПС',
    objType: 'vehicle',
};

VehiclePrintButton.propTypes = {
    label: PropTypes.string,
    objType: PropTypes.string,
    vehicle: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default VehiclePrintButton;
