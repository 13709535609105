import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import PolicySearchWidget from '../widgets/Policy/PolicySearchWidget.jsx';
import ErrorWidget from '../widgets/ErrorWidget';
import {Col, Row} from 'react-bootstrap';
import {
    useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation,
    useLazyListPoliciesQuery
} from '../../features/apiSlice';
import {useNavigate, useParams} from 'react-router-dom';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const InstallmentPaymentFind = () => {
    const navigate = useNavigate();
    const {action} = useParams();

    const [list, {
        data: listResult,
        error: listError,
        isLoading: listLoadingOriginal,
        isFetching: listFetchingOriginal,
        isSuccess: listSuccessOriginal,
        isError: listIsError,
    }] = useLazyListPoliciesQuery();

    const listLoading = listLoadingOriginal || listFetchingOriginal;
    const listSuccess = listSuccessOriginal && !listLoading;

    const [create, {
        reset: createReset,
        error: createError,
        isError: createIsError,
        isLoading: createLoading,
    }] = useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation();

    return (<Content title="Плащане на поредна вноска">
        <Row className="row-cols-1 row-cols-xl-2">
            <Col>
                <Card outline>
                    <PolicySearchWidget formName="policySearchInstallment"
                        onSubmit={async values => {
                            navigate('/installment-payment', {
                                replace: true,
                            });

                            createReset();

                            const result = await list({
                                query: values.policyNumber,
                            }).unwrap();

                            if (result[0]) {
                                navigate('/installment-payment/policy/' + result[0].id);
                                return;
                            }

                            const importedPolicy = await create(values).unwrap();
                            navigate('/installment-payment/policy/' + importedPolicy.id);
                        }} autoFocus={true} autoSubmit={action === 'find'} />
                </Card>
                {listLoading && <Card>Търсене на полица... <SmallSpinner /></Card>}
                {listIsError && <ErrorWidget error={listError} />}
                {createLoading && <Card>Импортиране на полица... <SmallSpinner /></Card>}
                {createIsError && <ErrorWidget error={createError} />}
                {listSuccess && listResult.length > 1 && 'списък с полици с един и същи номер при различни ЗК'}
            </Col>
        </Row>
    </Content>);
};

export default InstallmentPaymentFind;
