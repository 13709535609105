import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useAttachPolicyAssistanceCardMutation, useGetAssistanceCardInventoryQuery} from "../../features/apiSlice.js";
import {createFilter} from "react-select";
import ErrorWidget from "./ErrorWidget.jsx";
import SelectField from "../elements/ReactSelect/SelectField.jsx";
import AssistanceCardNumber from "../elements/Values/AssistanceCardNumber.jsx";

const PolicyAssistanceCardWidget = ({
    insuranceCompanyId,
    issueOfficeId,
    policyId,
    assistanceCardRef,
}) => {
    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useAttachPolicyAssistanceCardMutation();

    const {
        data: assistanceCards,
        isSuccess: assistanceCardsIsSuccess,
    } = useGetAssistanceCardInventoryQuery([insuranceCompanyId, issueOfficeId]);

    const assistanceCardsList = useMemo(() => assistanceCardsIsSuccess ? assistanceCards.map(
        assistanceCard => ({
            value: assistanceCard.id,
            label: <AssistanceCardNumber {...assistanceCard} />,
        })
    ) : [], [assistanceCardsIsSuccess, assistanceCards]);

    return (
        <Formik
            initialValues={{
                assistanceCard: '',
            }}
            validate={values => {
                const errors = {};
                if (!values.assistanceCard) {
                    errors.assistanceCard = 'Моля изберете асистънс карта!';
                }
                return errors;
            }}
            onSubmit={(values) => {
                update([policyId, values.assistanceCard]);
            }}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <Col>
                            <Field name="assistanceCard" component={SelectField} options={assistanceCardsList}
                                   disabled={isSubmitting} isClearable={true}
                                   noOptionsMessage={() => 'Няма налични асистънс карти!'}
                                   filterOption={createFilter({
                                        stringify: option => {
                                            return option.label.props.number
                                        },
                            })} innerRef={assistanceCardRef} />
                        </Col>
                        <Col>
                            {updateIsError &&
                                <ErrorWidget error={updateError} />}
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

PolicyAssistanceCardWidget.defaultProps = {
    assistanceCardRef: null,
};

PolicyAssistanceCardWidget.propTypes = {
    insuranceCompanyId: PropTypes.string,
    issueOfficeId: PropTypes.string,
    policyId: PropTypes.string,
};
export default PolicyAssistanceCardWidget;
