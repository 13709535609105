import React, {useRef, useState} from 'react';
import {useLazyListVouchersQuery, useStoreVoucherMutation} from '../../features/apiSlice';
import {Button, Col, Container, FormControl, FormLabel, InputGroup, Row} from 'react-bootstrap';
import Card from '../elements/ReactBootstrap/Card';
import Alert from '../elements/ReactBootstrap/Alert';
import ErrorWidget from '../widgets/ErrorWidget';
import ReportTableWidget from '../widgets/Report/ReportTableWidget';
import {Field, Form, Formik} from 'formik';
import ErrorIcon from '../elements/Icons/ErrorIcon';
import {upcChecksum} from '../../utils';
import WeekAndOfficeSelectionWidget from '../widgets/Receive/WeekAndOfficeSelectionWidget';
import SuccessIcon from '../elements/Icons/SuccessIcon';
import PremiumAmount from '../elements/Values/PremiumAmount';
import StickerNumber from '../elements/Values/StickerNumber';
import GreenCardNumber from '../elements/Values/GreenCardNumber';
import DateShort from '../elements/DateTime/DateShort';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import DateRangeAndOfficeSelectionWidget from "../widgets/Receive/DateRangeAndOfficeSelectionWidget.jsx";

const Receive = () => {
    const [lastSuccessfulVoucher, setLastSuccessfulVoucher] = useState();
    const [parseError, setParseError] = useState();

    const [generate, {
        data: vouchersList,
        error,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
    }] = useLazyListVouchersQuery();

    const [store, {
        isError: storeIsError,
        error: storeError,
    }] = useStoreVoucherMutation();

    const vouchers = isSuccess ? vouchersList : [];
    const pendingVouchers = vouchers.filter(voucher =>
        !voucher.storageOffice && !voucher.storageUser && !voucher.storeDate
    );
    const receivedVouchers = vouchers.filter(voucher =>
        voucher.storageOffice || voucher.storageUser || voucher.storeDate
    );

    const barcodeField = useRef(null);

    const resetBarcodeField = () => barcodeField.current && barcodeField.current.focus();

    const parseStickerNumber = barcode => {
        if (barcode.length === 10 && upcChecksum(barcode) === 0 && barcode.substr(0, 1) === '0') {
            return barcode.substring(1, 9);
        }

        if (barcode.length === 8 || barcode.length === 9) {
            return barcode;
        }

        return null;
    };

    const handleSubmit = (values, api) => {
        setLastSuccessfulVoucher();
        setParseError();

        const stickerNumber = parseStickerNumber(values.barcode);

        if (stickerNumber !== null) {
            const stickerNumberInt = parseInt(stickerNumber, 10);
            const matchingVouchers = vouchers.filter(voucher => voucher.sticker?.number === stickerNumberInt);

            if (matchingVouchers.length === 0) {
                setParseError('Не е намерена квитанция със стикер ' + stickerNumberInt);
            }

            if (matchingVouchers.length === 1) {
                const voucher = matchingVouchers[0];

                store(voucher.id);
                setLastSuccessfulVoucher(voucher);
            }

            if (matchingVouchers.length > 1) {
                setParseError('Намерена е повече от една квитанция със стикер ' + stickerNumberInt);
            }
        }
        else {
            setParseError('Невалиден баркод: ' + values.barcode);
        }

        api.setSubmitting(false);
        api.setFieldValue('barcode', '');

        // TODO: ugly fix
        setTimeout(() => resetBarcodeField(), 0);

        return Promise.resolve();
    };

    return (<Content title="Приемане на поща">
        <Row>
            <Col>
                <Card outline header="Изберете седмица">
                    <WeekAndOfficeSelectionWidget onSubmit={values => {
                        setLastSuccessfulVoucher();
                        setParseError();
                        return generate(values);
                    }} />
                    <DateRangeAndOfficeSelectionWidget onSubmit={values => {
                        setLastSuccessfulVoucher();
                        setParseError();
                        return generate(values);}} />
                </Card>
            </Col>
        </Row>
        {pendingVouchers.length > 0 && <Row>
            <Col>
                <Card outline header="Въведете баркодовете на получените полици и сметки">
                    <Formik initialValues={{
                        barcode: '',
                    }} onSubmit={handleSubmit}>
                        {({isSubmitting}) => (
                            <Container as={Form} className="gx-0">
                                <Row className="row-cols-1 row-cols-sm-2 g-3 align-items-end">
                                    <Col>
                                        <FormLabel htmlFor="barcode">Баркод</FormLabel>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <i className="fas fa-barcode"/>
                                            </InputGroup.Text>
                                            <FormControl as={Field} type="text" name="barcode" id="barcode" required
                                                disabled={isSubmitting} innerRef={barcodeField} autoComplete="off"
                                                autoFocus />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="success" type="submit" className="w-100"
                                            disabled={isSubmitting}>
                                            <i className="fas fa-search" /> Търсене
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </Formik>
                </Card>
            </Col>
        </Row>}
        {lastSuccessfulVoucher && <Row>
            <Col>
                <Card>
                    <SuccessIcon />{' '}
                    Полица {lastSuccessfulVoucher.installments[0].policy.number.slice(0, -4)}
                    <strong>{lastSuccessfulVoucher.installments[0].policy.number.slice(-4)}</strong>{' '}
                    {lastSuccessfulVoucher.sticker && <>
                        стикер <strong><StickerNumber {...lastSuccessfulVoucher.sticker} /></strong>{' '}
                    </>}
                    {lastSuccessfulVoucher.greenCard && <>
                        СЗК <strong><GreenCardNumber {...lastSuccessfulVoucher.greenCard} /></strong>{' '}
                    </>}
                    вноска {lastSuccessfulVoucher.installments.map(installment => installment.number).join(',')}{' '}
                    от {lastSuccessfulVoucher.installments[0].policy.installmentsCount}{' '}
                    за <strong><PremiumAmount amount={lastSuccessfulVoucher.totalAmount} /></strong>{' '}
                    на {lastSuccessfulVoucher.issueUser.shortName} ({lastSuccessfulVoucher.issueOffice.name})
                    издадена на <strong><DateShort date={lastSuccessfulVoucher.issueDate} /></strong>
                </Card>
            </Col>
        </Row>}
        {parseError && <Row>
            <Col>
                <Card>
                    <ErrorIcon />{' '}{parseError}
                </Card>
            </Col>
        </Row>}
        {storeIsError && <Row>
            <Col>
                <ErrorWidget error={storeError} />
            </Col>
        </Row>}
        <Row>
            <Col>
                {isUninitialized && <Alert variant="secondary"
                                           heading="Моля, изберете седмица, за която се приема пощата." />}
                {isError && <ErrorWidget error={error} />}
                {isLoading && <Card>
                    <SmallSpinner />
                </Card>}
                {isSuccess && <>
                    {pendingVouchers.length > 0 && <>
                        <h4>Неотчетени квитанции</h4>
                        <ReportTableWidget data={pendingVouchers} isLoading={isFetching}
                            actionButton={row => <Button variant="outline-secondary"
                            onClick={() => {/* eslint-disable-next-line no-restricted-globals */
                                if (confirm('Отчитане на квитанцията?')) {
                                    store(row.id);
                                    resetBarcodeField();
                                }
                            }}>
                                <i className="fas fa-check-square" />
                        </Button>} />
                    </>}
                    {receivedVouchers.length > 0 && <>
                        <h4>Отчетени квитанции</h4>
                        <ReportTableWidget data={receivedVouchers} isLoading={isFetching} />
                    </>}
                    {vouchers.length === 0 && <ReportTableWidget data={[]} isLoading={isFetching} />}
                </>}
            </Col>
        </Row>
    </Content>);
};

export default Receive;
