import {Col, Row} from 'react-bootstrap';
import React from 'react';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import PropTypes from 'prop-types';
import ClientsListWidget from '../Client/ClientsListWidget.jsx';

const ClientSearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'Клиенти (' + length + ')'}>
                <ClientsListWidget clients={results} />
            </Card>
        </Col>
    </Row>);
};

ClientSearchResult.propTypes = {
    results: PropTypes.array,
};

export default ClientSearchResult;
