import React, {useMemo, useState} from 'react';
import {Button, Col, FormControl, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import Content from '../../partials/Content.jsx';
import {useLazyListVouchersForPaymentsQuery, useUpdateVouchersPaymentsMutation} from "../../../features/apiSlice.js";
import Alert from "../../elements/ReactBootstrap/Alert.jsx";
import ErrorWidget from "../../widgets/ErrorWidget.jsx";
import SmallSpinner from "../../elements/Spinner/SmallSpinner.jsx";
import PaymentGridWidget from "../../widgets/Report/PaymentGridWidget.jsx";
import WeekSelectionWidget from "../../widgets/Report/Selection/WeekSelectionWidget.jsx";
import DateRangeSelectionWidget from "../../widgets/Report/Selection/DateRangeSelectionWidget.jsx";
import {useNavigate, useParams} from "react-router-dom";
import {format} from "date-fns";

const SalesReports = () => {
    const {action} = useParams();
    const navigate = useNavigate();
    const [type, setType] = useState('');
    const todayFormatted = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);
    const [date, setDate] = useState(todayFormatted);

    const [generate, {
        data: vouchersList,
        error,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
    }] = useLazyListVouchersForPaymentsQuery();

    const [update] = useUpdateVouchersPaymentsMutation();

    const vouchers = isSuccess ? vouchersList.filter(voucher =>
        !voucher.void
    ) : [];

    // const showAliens = type === 'paidCommissionFromInsuranceCompany' || type === 'paidCommissionToAgent' || type === '';
    const hideAliens = type === 'paidPremiumFromClient' || type === 'paidPremiumToInsuranceCompany'

    // const vouchersData = showAliens ? vouchers : vouchers.filter(voucher => voucher.issueUser);
    const vouchersData = hideAliens ? vouchers.filter(voucher => voucher.issueUser) : vouchers;

    const gridRef = React.createRef();

    return (<Content title="Отчет плащания">
        <Row className="row-cols-1 gy-3 flex-grow-1 flex-column">
            <Col>
                <Card outline>
                    <Row className="row-cols-1 row-cols-sm-2 gy-3">
                        <Col>
                            <WeekSelectionWidget onSubmit={values => {
                                navigate('/reports/payments', {
                                    replace: true,
                                });

                                return generate(values);
                            }} autoSubmit={action === 'generate'} />
                        </Col>
                        <Col>
                            <DateRangeSelectionWidget onSubmit={values => generate(values)} />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row>
                    <Col sm={8}>
                        <Button variant={'outline-secondary' + (type === 'paidPremiumFromClient' ? ' disabled' : '')}
                                className="ms-2" onClick={() => {
                            if (!gridRef.current) {
                                return;
                            }
                            setType('paidPremiumFromClient');

                            const {api, columnApi} = gridRef.current;
                            columnApi.applyColumnState({
                                state: [{
                                    colId: 'ag-Grid-AutoColumn',
                                    sort: 'asc',
                                    sortIndex: 1,
                                },{
                                    colId:'paidPremiumFromClient',
                                    rowGroupIndex: 0,
                                    cellRenderer: 'agGroupCellRenderer',
                                    hide: true,
                                    cellRendererParams: {
                                        checkbox: true,
                                    },
                                },{
                                    colId: 'issueOffice.name',
                                    rowGroupIndex: 1,
                                    sort: 'asc',
                                    hide: true,
                                },{
                                    colId: 'paymentMethod',
                                    rowGroupIndex: 2,
                                    hide: true,
                                },{
                                    colId: 'totalAmount',
                                },{
                                    colId: 'correctionAmount',
                                },{
                                    colId: 'commissionAmount',
                                },{
                                    colId: 'userCommissionAmount',
                                }],
                                applyOrder: true,
                                defaultState: {
                                    hide: false,
                                    rowGroupIndex: null,
                                    sort: null,
                                },
                            });
                            api.forEachNode(node => {
                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                            });
                        }}>към Брокер</Button>
                        <Button variant={'outline-secondary' + (type === 'paidPremiumToInsuranceCompany' ? ' disabled' : '')}
                                className="ms-2" onClick={() => {
                            if (!gridRef.current) {
                                return;
                            }
                            setType('paidPremiumToInsuranceCompany');

                            const {api, columnApi} = gridRef.current;
                            columnApi.applyColumnState({
                                state: [{
                                    colId: 'ag-Grid-AutoColumn',
                                    sort: 'asc',
                                    sortIndex: 1,
                                },{
                                    colId:'paidPremiumToInsuranceCompany',
                                    rowGroupIndex: 0,
                                    cellRenderer: 'agGroupCellRenderer',
                                    hide: true,
                                    cellRendererParams: {
                                        checkbox: true,
                                    },
                                },{
                                    colId: 'policy.insuranceCompany.shortName',
                                    rowGroupIndex: 1,
                                    sort: 'asc',
                                    hide: true,
                                },{
                                    colId: 'paymentMethod',
                                    rowGroupIndex: 2,
                                    hide: true,
                                },{
                                    colId: 'totalAmount',
                                },{
                                    colId: 'commissionAmount',
                                },{
                                    colId: 'userCommissionAmount',
                                }],
                                applyOrder: true,
                                defaultState: {
                                    hide: false,
                                    rowGroupIndex: null,
                                    sort: null,
                                },
                            });
                            api.forEachNode(node => {
                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                            });
                        }}>към ЗК</Button>
                        <Button variant={'outline-secondary' + (type === 'paidCommissionFromInsuranceCompany' ? ' disabled' : '')}
                                className="ms-2" onClick={() => {
                            if (!gridRef.current) {
                                return;
                            }
                            setType('paidCommissionFromInsuranceCompany');

                            const {api, columnApi} = gridRef.current;
                            columnApi.applyColumnState({
                                state: [{
                                    colId: 'ag-Grid-AutoColumn',
                                    sort: 'asc',
                                    sortIndex: 1,
                                }, {
                                    colId:'paidCommissionFromInsuranceCompany',
                                    rowGroupIndex: 0,
                                    cellRenderer: 'agGroupCellRenderer',
                                    hide: true,
                                    cellRendererParams: {
                                        checkbox: true,
                                    },
                                },{
                                    colId: 'policy.insuranceCompany.shortName',
                                    rowGroupIndex: 1,
                                    sort: 'asc',
                                    hide: true,
                                },{
                                    colId: 'paymentMethod',
                                    rowGroupIndex: 2,
                                    hide: true,
                                },{
                                    colId: 'totalAmount',
                                },{
                                    colId: 'commissionAmount',
                                },{
                                    colId: 'userCommissionAmount',
                                }],
                                applyOrder: true,
                                defaultState: {
                                    hide: false,
                                    rowGroupIndex: null,
                                    sort: null,
                                },
                            });
                            api.forEachNode(node => {
                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                            });
                        }}>комисион Брокер</Button>
                        <Button variant={'outline-secondary' + (type === 'paidCommissionToAgent' ? ' disabled' : '')}
                                className="ms-2" onClick={() => {
                            if (!gridRef.current) {
                                return;
                            }
                            setType('paidCommissionToAgent');

                            const {api, columnApi} = gridRef.current;
                            columnApi.applyColumnState({
                                state: [{
                                    colId: 'ag-Grid-AutoColumn',
                                    sort: 'asc',
                                    sortIndex: 1,
                                }, {
                                    colId:'paidCommissionToAgent',
                                    rowGroupIndex: 0,
                                    cellRenderer: 'agGroupCellRenderer',
                                    hide: true,
                                    cellRendererParams: {
                                        checkbox: true,
                                    },
                                },{
                                    colId: 'issueOffice.name',
                                    rowGroupIndex: 1,
                                    sort: 'asc',
                                    hide: true,
                                },{
                                    colId: 'paymentMethod',
                                    rowGroupIndex: 2,
                                    hide: true,
                                },{
                                    colId: 'totalAmount',
                                },{
                                    colId: 'commissionAmount',
                                },{
                                    colId: 'userCommissionAmount',
                                }],
                                applyOrder: true,
                                defaultState: {
                                    hide: false,
                                    rowGroupIndex: null,
                                    sort: null,
                                },
                            });
                            api.forEachNode(node => {
                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                            });
                        }}>комисион Консултант</Button>
                    </Col>
                    <Col sm={2}>
                        <FormControl type="date" value={date} onChange={e=> setDate(e.target.value)}/>
                    </Col>
                    <Col sm={2}>
                        <Button variant="outline-danger" className="ms-2" onClick={async () => {
                            if (!gridRef.current) {
                                return;
                            }
                            const rowIds = gridRef.current.api.getSelectedRows().map(row =>row.id);
                            if (window.confirm('Моля, потвърдете плащането на квитанциите!')) {
                                await update({vouchers: rowIds, columnType: type, date: date});
                            }

                        }}>Промяна</Button>
                    </Col>
                    </Row>
                </Card>
            </Col>
            <Col className="flex-grow-1">
                {isUninitialized && <Alert variant="secondary" heading="Моля, изберете седмица или период." />}
                {isError && <ErrorWidget error={error} />}
                {(isLoading || isFetching) && <SmallSpinner />}
                {isSuccess && <PaymentGridWidget rowData={vouchersData} ref={gridRef}/>}
            </Col>
        </Row>
    </Content>);
};

export default SalesReports;
