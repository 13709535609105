import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {
    useAttachVoucherBsoMutation,
    useGetGreenCardInventoryQuery,
} from "../../features/apiSlice.js";
import {createFilter} from "react-select";
import {formatGreenCardNumber} from "../../utils.js";
import ErrorWidget from "./ErrorWidget.jsx";
import SelectField from "../elements/ReactSelect/SelectField.jsx";
import GreenCardNumber from "../elements/Values/GreenCardNumber.jsx";

const VoucherGreenCardWidget = ({
    insuranceCompanyId,
    office,
    voucherId,
    greenCardRef,
}) => {
    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useAttachVoucherBsoMutation();

    const {
        data: greenCards,
        isSuccess: greenCardsIsSuccess,
    } = useGetGreenCardInventoryQuery([insuranceCompanyId, office]);

    const greenCardsList = useMemo(() => greenCardsIsSuccess ? greenCards.map(greenCard => ({
        value: greenCard.id,
        label: <GreenCardNumber {...greenCard} />,
    })) : [], [greenCardsIsSuccess, greenCards]);

    return (
        <Formik
            initialValues={{
                greenCard: '',
            }}
            validate={values => {
                const errors = {};
                if (!values.greenCard) {
                    errors.greenCard = 'Моля изберете зелена карта!';
                }
                return errors;
            }}
            onSubmit={(values) => { update([voucherId, {
                greenCard: values.greenCard,
                }]);
            }}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <Col>
                        <Field name="greenCard" component={SelectField} options={greenCardsList}
                               disabled={isSubmitting} isClearable={true}
                               noOptionsMessage={() => 'Нямате налични стикери!'} filterOption={createFilter({
                            stringify: option => {
                                const props = option.label.props;
                                const series = props.series;
                                const number = props.number;
                                const numberFormatted = formatGreenCardNumber(number);
                                return series + ' ' + numberFormatted + '\0' + series + numberFormatted;
                            },
                        })} innerRef={greenCardRef} />
                        </Col>
                        <Col>
                        {updateIsError &&
                            <ErrorWidget error={updateError} />}
                    <Button variant="success" type="submit" disabled={isSubmitting}>
                        Запиши
                    </Button>
                            </Col>
                </Row>
                </Container>
            )}
        </Formik>
    );
};

VoucherGreenCardWidget.defaultProps = {
    greenCardRef: null,
};

VoucherGreenCardWidget.propTypes = {
    insuranceCompanyId: PropTypes.string,
    issueOfficeId: PropTypes.string,
    voucherId: PropTypes.string,
};
export default VoucherGreenCardWidget;
