import React from 'react';
import {Form, Formik} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import SubmitButton from '../../elements/Form/SubmitButton';
import UserSelector from "../../elements/Form/UserSelector.jsx";

const ReferrerSelectionWidget = ({
    referrerId,
    onSubmit,
}) => {
    const formName = 'UserLevelSelector';

    return (
        <Formik initialValues={{
            referrer: referrerId,
        }} onSubmit={onSubmit}>
            <Container as={Form} className="gx-0">
                <Row>
                    <Col>
                        <UserSelector defaultValueFilter={values => values.filter(referrer =>
                            referrer.value === referrerId
                        )} name='referrer' referrer isClearable/>
                    </Col>
                    <Col>
                        <SubmitButton>Запиши</SubmitButton>
                    </Col>
                </Row>
                <AutoPersistToken formName={formName}/>
            </Container>
        </Formik>
    );
};

ReferrerSelectionWidget.propTypes = {
    referrerId: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};

export default ReferrerSelectionWidget;
