import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, FormCheck, FormControl, FormLabel, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import {format} from 'date-fns';
import InsuranceCompanySelector from "../../../elements/Form/InsuranceCompanySelector.jsx";

const DateRangeAndInsuranceCompanySelectionWidget = ({
    max,
    onSubmit,
    addFilterPaidPremiumFromClient,
    addFilterUnpaidPremiumToInsuranceCompany,
}) => {
    const formName = 'reportFormDateRange';
    const initialFormValues = useFormPersistedValues(formName);
    const todayFormatted = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

    return (
        <Formik initialValues={initialFormValues || {
            insuranceCompany: '',
            startDateTime: todayFormatted,
            endDateTime: todayFormatted,
            filterPaidPremiumFromClient: false,
            filterUnpaidPremiumToIC: false
        }} onSubmit={onSubmit}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-xl-5 g-5 align-items-end">
                        <Col>
                            <FormLabel htmlFor="startDateTime">Начална дата</FormLabel>
                            <FormControl as={Field} type="date" name="startDateTime" id="startDateTime"
                                         max={max} disabled={isSubmitting} />
                        </Col>
                        <Col>
                            <FormLabel htmlFor="endDateTime">Крайна дата</FormLabel>
                            <FormControl as={Field} type="date" name="endDateTime" id="endDateTime"
                                         max={max} disabled={isSubmitting} />
                        </Col>
                        <Col>
                            <InsuranceCompanySelector required={true} />
                        </Col>
                        {(addFilterPaidPremiumFromClient || addFilterUnpaidPremiumToInsuranceCompany) && <Col>
                            {addFilterPaidPremiumFromClient &&
                                <FormCheck as={Field} name="filterPaidPremiumFromClient" id="filterPaidPremiumFromClient"
                                           label="Отчетени към БР" disabled={isSubmitting}>
                                </FormCheck>
                            }
                            {addFilterUnpaidPremiumToInsuranceCompany &&
                                <FormCheck as={Field} name="filterUnpaidPremiumToIC" id="filterUnpaidPremiumToIC"
                                           label="Неотчетени към ЗК" disabled={isSubmitting}>
                                </FormCheck>
                            }
                        </Col>}
                        <Col>
                            <Button variant="success" type="submit" className="w-100" disabled={isSubmitting}>
                                Изготвяне
                            </Button>
                        </Col>
                    </Row>
                    <AutoPersistToken formName={formName}/>
                </Container>
            )}
        </Formik>
    );
};

DateRangeAndInsuranceCompanySelectionWidget.defaultProps = {
    max: format(new Date(), 'yyyy-MM-dd'),
    addFilterPaidPremiumFromClient: false,
    addFilterUnpaidPremiumToInsuranceCompany : false,
}

DateRangeAndInsuranceCompanySelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    max: PropTypes.string,
};

export default DateRangeAndInsuranceCompanySelectionWidget;
