import React from 'react';
import PremiumAmount from '../elements/Values/PremiumAmount.jsx';
import Percent from '../elements/Values/Percent.jsx';
import useUsersVoucherCommission from "../../hooks/useUsersVoucherCommission.js";

const AgentCommissionListWidget = ({
    voucher,
    policy,
}) => {
    const {
        getFilteredUsers,
        getUserVoucherCommissionPercent,
        getUserVoucherCommissionAmount,
    } = useUsersVoucherCommission();

    const voucherIssueUser = voucher.issueUser ? voucher.issueUser : policy.issueUser;
    const filteredUsers = getFilteredUsers(voucher);

    const usersCommissionInfo = filteredUsers.map(user => {
        if (user?.id) {
            return ({
                id: user.id,
                name: user.id === voucherIssueUser?.id ? 'консултант' : user.shortName,
                commissionSum: getUserVoucherCommissionAmount(voucher, user),
                commissionCoefficient: getUserVoucherCommissionPercent(voucher, user),
            });
        }
        return [];
    });

    return usersCommissionInfo.map(user => {
        if (user?.id) {
            return (<div key={user.id}>
                Комисион {user.name}:{' '}
                <PremiumAmount amount={user.commissionSum}/>
                {' '}(<Percent coefficient={user.commissionCoefficient} toFixed={2}/>)
            </div>);
        }
        return '';
    });
};

export default AgentCommissionListWidget;
