import Alert from '../../elements/ReactBootstrap/Alert';
import React, {useMemo} from 'react';
import StickerNumber from '../../elements/Values/StickerNumber';
import {Button, Col, Container, Row} from 'react-bootstrap';
import Step from '../../elements/BsStepper/Step';
import {useListInsuranceCompaniesQuery} from '../../../features/apiSlice';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import GreenCardNumber from '../../elements/Values/GreenCardNumber';
import ValuationCertificateNumber from '../../elements/Values/ValuationCertificateNumber';
import UabCardNumber from '../../elements/Values/UabCardNumber';
import AssistanceCardNumber from "../../elements/Values/AssistanceCardNumber.jsx";

const BsoSaveWidget = ({
    type,
}) => {
    const {
        values: {
            startNumber,
            endNumber,
            ...values
        },
        isSubmitting,
    } = useFormikContext();

    const {
        data: insuranceCompanies,
        isSuccess: insuranceCompaniesIsSuccess,
        isError: insuranceCompaniesIsError,
    } = useListInsuranceCompaniesQuery(undefined, {
        skip: !values.insuranceCompany,
    });

    const selectedInsuranceCompany = useMemo(
        () => !!values.insuranceCompany && insuranceCompaniesIsSuccess && insuranceCompanies
            .filter(ic => ic.id === values.insuranceCompany).shift(),
        [values.insuranceCompany, insuranceCompaniesIsSuccess, insuranceCompanies]
    );

    if (insuranceCompaniesIsError || startNumber.length !== endNumber.length) {
        return null;
    }

    if (['стикери', 'СЗК', 'асистънс карти'].indexOf(type) !== -1 && !selectedInsuranceCompany) {
        return null;
    }

    const startNumberInt = parseInt(type === 'асистънс карти' ? startNumber.slice(1) : startNumber, 10);
    const endNumberInt = parseInt(type === 'асистънс карти' ? endNumber.slice(1) :endNumber, 10);

    if (isNaN(startNumberInt) || isNaN(endNumberInt)) {
        return null;
    }

    const count = endNumberInt - startNumberInt + 1;

    if (count < 1) {
        return null;
    }

    const tooMany = count > 100;

    return (<>
        <Col>
            <Step number="3" label={'Потвърдете въведените ' + type} />
        </Col>
        <Col lg={6}>
            <Alert variant={tooMany ? "warning" : "secondary"} icon={null} className="my-0">
                <Container fluid className="gx-0">
                    {tooMany && <Row>
                        <Col>
                            <strong>Не можете да въведете повече от 100 {type} едновременно!</strong>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            Регистрация на <strong>{count}</strong> бр. {type}
                            {selectedInsuranceCompany && <>
                                {' '}на <strong>{selectedInsuranceCompany.shortName}</strong>{' '}
                            </>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>от номер</Col>
                        <Col>
                            <strong>
                                {type === 'стикери' && <StickerNumber number={startNumberInt} />}
                                {type === 'СЗК' && <GreenCardNumber series={values.series} number={startNumberInt} />}
                                {type === 'УЗС' && <ValuationCertificateNumber number={startNumberInt} />}
                                {type === 'Карти СБА' && <UabCardNumber number={startNumberInt} />}
                                {type === 'асистънс карти' && <AssistanceCardNumber series={values.series} number={startNumber} />}
                            </strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col>до номер</Col>
                        <Col>
                            <strong>
                                {type === 'стикери' && <StickerNumber number={endNumberInt} />}
                                {type === 'СЗК' && <GreenCardNumber series={values.series} number={endNumberInt} />}
                                {type === 'УЗС' && <ValuationCertificateNumber number={endNumberInt} />}
                                {type === 'Карти СБА' && <UabCardNumber number={endNumberInt} />}
                                {type === 'асистънс карти' && <AssistanceCardNumber series={values.series} number={endNumber} />}
                            </strong>
                        </Col>
                    </Row>
                </Container>
            </Alert>
        </Col>
        <div className="w-100 gy-0" />
        <Col lg={6}>
            <Button variant="success" type="submit" disabled={isSubmitting} className="w-100">
                <i className="fas fa-upload"/> Регистрирай бланките
            </Button>
        </Col>
    </>);
};

BsoSaveWidget.propTypes = {
    type: PropTypes.string.isRequired,
};

export default BsoSaveWidget;
