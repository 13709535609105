import {getGreenCardUrl} from '../../../url';
import PrintButton from '../ReactBootstrap/PrintButton';
import React from 'react';
import {printGreenCard} from '../../../print';
import PropTypes from 'prop-types';

const GreenCardPrintButton = ({
    greenCard,

    ...props
}) => {
    return (
        <PrintButton icon="money-check" printAction={() => printGreenCard(greenCard.id)}
            viewUrl={getGreenCardUrl(greenCard.id)}
            downloadUrl={getGreenCardUrl(greenCard.id, true)}
            objId={greenCard.id} {...props}/>
    );
};

GreenCardPrintButton.defaultProps = {
    label: 'Печат на Зелена карта',
    objType: 'greenCard'

};

GreenCardPrintButton.propTypes = {
    label: PropTypes.string,
    objType: PropTypes.string,
    greenCard: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default GreenCardPrintButton;
