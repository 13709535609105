import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import WrappedCopyButton from '../SimpleButtons/WrappedCopyButton.jsx';
import useCurrentBrokerIsDemo from "../../../hooks/useCurrentBrokerIsDemo.js";

const NavigationButton = ({
    children,

    link,
    copyText,
    small,
    isWarning,
}) => {
    const isDemo = useCurrentBrokerIsDemo();

    return (<WrappedCopyButton text={copyText} small={small}>
        <Link to={link} className={'btn btn-outline-'+ (isWarning ? 'warning' : 'secondary' )  + (small ? ' p-1' : '')
            + (isDemo && link.startsWith('/client/') ? ' demo' : '')}>{children}</Link>
    </WrappedCopyButton>);
};

NavigationButton.defaultProps = {
    small: false,
    isWarning: false,
};

NavigationButton.propTypes = {
    link: PropTypes.string.isRequired,
    copyText: PropTypes.string,
    small: PropTypes.bool,
};

export default NavigationButton;
