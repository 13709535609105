import {getPolicyUrl} from '../../../url';
import PrintButton from '../ReactBootstrap/PrintButton';
import React from 'react';
import {printPolicy} from '../../../print';
import PropTypes from 'prop-types';

const PolicyPrintButton = ({
    policy,

    ...props
}) => {
    return (
        <PrintButton icon="file-alt" printAction={() => printPolicy(policy.id)}
            viewUrl={getPolicyUrl(policy.id)}
            downloadUrl={getPolicyUrl(policy.id, true)}
            objId={policy.id} {...props} />
    );
};

PolicyPrintButton.defaultProps = {
    label: 'Печат на полица',
    objType: 'policy',
};

PolicyPrintButton.propTypes = {
    label: PropTypes.string,
    objType: PropTypes.string,
    policy: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default PolicyPrintButton;
