import {Col} from 'react-bootstrap';
import React, {useMemo} from 'react';
import {useGetCurrentInsuranceBrokerQuery, useGetCurrentUserQuery} from "../../features/apiSlice.js";
import {flattenUsers} from "../../utils.js";
import {Field, useFormikContext} from "formik";
import SelectField from "../elements/ReactSelect/SelectField.jsx";
import {createFilter} from "react-select";
import ErrorWidget from "./ErrorWidget.jsx";
import useCurrentUserHasRole from "../../hooks/useCurrentUserHasRole.js";

const IssueUserSelectionWidget = () => {
    const hasRoleShowBroker = useCurrentUserHasRole('ROLE_SHOW_BROKER');


    const {
        data: insuranceBroker,
        error,
        isSuccess,
        isError,
    } = useGetCurrentInsuranceBrokerQuery();

    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const users = useMemo(
        () => isSuccess ? flattenUsers(insuranceBroker) : [{
            value: currentUser.id,
            label: currentUser.fullName + ' (офис ' + currentUser.office.name + ')',
        }],
        [isSuccess, insuranceBroker, currentUser]
    );

    const { isSubmitting } = useFormikContext();

    return (<Col>
            {isError && hasRoleShowBroker ? <ErrorWidget error={error} /> : <Field component={SelectField} name="issueUser"
                options={users} required disabled={isSubmitting} isClearable={true}
                placeholder="Не е избран консултант"
                filterOption={createFilter({
                    stringify: option => option.label,
                })}/>}
        </Col>
    );
};

export default IssueUserSelectionWidget;
