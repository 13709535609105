import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {useListCitiesQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const CitySelector = ({
    region,
    required,
    isClearable,
    defaultValueFilter,
    ...props
}) => {
    const {
        data: cityList,
        error: cityListError,
        isLoading: cityListLoading,
        isSuccess: cityListSuccess,
        isError: cityListIsError,
    } = useListCitiesQuery(region);

    const cityOptions = useMemo(
        () => cityListSuccess ? cityList.map(city => ({
            value: city.cityId,
            label: city.cityName + ' - ' + city.postCode,
        })): [],
        [cityListSuccess, cityList]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {cityListLoading && <SmallSpinner />}
        {cityListSuccess && <Field component={SelectField} name="city" options={cityOptions}
            disabled={isSubmitting} isClearable={isClearable} required={required}
            defaultValue={defaultValueFilter ? defaultValueFilter(cityOptions) : []}
            placeholder={required ? 'Изберете град - ПК' : 'Всички градове'}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {cityListIsError && <ErrorIcon error={cityListError} />}
    </>);
};

CitySelector.defaultProps = {
    required: false,
    isClearable: false,
};

CitySelector.propTypes = {
    region: PropTypes.string,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default CitySelector;
