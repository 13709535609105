import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {useListRegionsQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const RegionSelector = ({
    municipality,
    required,
    isClearable,
    defaultValueFilter,
    ...props
}) => {
    const {
        data: regionList,
        error: regionListError,
        isLoading: regionListLoading,
        isSuccess: regionListSuccess,
        isError: regionListIsError,
    } = useListRegionsQuery(municipality);

    const regionOptions = useMemo(
        () => regionListSuccess ? regionList.map(region => ({
            value: region.regionId,
            label: region.regionName,
        })): [],
        [regionListSuccess, regionList]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {regionListLoading && <SmallSpinner />}
        {regionListSuccess && <Field component={SelectField} name="region" options={regionOptions}
            disabled={isSubmitting} isClearable={isClearable} required={required}
            defaultValue={defaultValueFilter ? defaultValueFilter(regionOptions) : []}
            placeholder={required ? 'Изберете община' : 'Всички общини'}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {regionListIsError && <ErrorIcon error={regionListError} />}
    </>);
};

RegionSelector.defaultProps = {
    required: false,
    isClearable: false,
};

RegionSelector.propTypes = {
    municipality: PropTypes.string,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default RegionSelector;
