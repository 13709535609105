import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card as BaseCard, Collapse, Fade} from 'react-bootstrap';
import LargeSpinner from '../Spinner/LargeSpinner.jsx';

const Card = ({
    children,
    outline,
    noPadding,
    header,
    title,
    baseClassName,
    headerClassName,
    collapse,
    initialClosed,
    isLoading,
    isSimpleHeader,

    ...props
}) => {
    const [open, setOpen] = useState(!initialClosed);

    return (
        <BaseCard className={(outline ? 'card-primary card-outline ' : '') + baseClassName}>
            {header && (
                <BaseCard.Header className={headerClassName}>
                    {isSimpleHeader ? <h5 className="card-title">{header}</h5> : <>{header}</>}
                    {collapse && <div className="card-tools">
                        <Button variant="tool" onClick={() => setOpen(oldState => !oldState)}>
                            <i className={'fas fa-' + (open ? 'minus' : 'plus')} />
                        </Button>
                    </div>}
                </BaseCard.Header>
            )}
            <Collapse in={open}>
                <BaseCard.Body className={noPadding ? 'p-0' : ''} {...props}>
                    {title && (
                        <h6 className="card-title">{title}</h6>
                    )}
                    {children}
                </BaseCard.Body>
            </Collapse>
            <Fade in={isLoading} unmountOnExit={true}>
                <div className="overlay">
                    <LargeSpinner />
                </div>
            </Fade>
        </BaseCard>
    );
};

Card.defaultProps = {
    baseClassName: '',
    headerClassName: undefined,
    outline: false,
    noPadding: false,
    collapse: false,
    initialClosed: false,
    isLoading: false,
    isSimpleHeader: true,
};

Card.propTypes = {
    outline: PropTypes.bool,
    header: PropTypes.node,
    noPadding: PropTypes.bool,
    isSimpleHeader: PropTypes.bool,
    title: PropTypes.node,
};

export default Card;
