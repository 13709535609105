import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormControl, Row} from 'react-bootstrap';
import PremiumAmount from '../../elements/Values/PremiumAmount.jsx';
import DateTimeLong from '../../elements/DateTime/DateTimeLong.jsx';
import PolicyPrintButton from '../../elements/PrintButtons/PolicyPrintButton.jsx';
import {Link} from 'react-router-dom';
import MtplOfferButton from '../OfferButtons/MtplOfferButton.jsx';
import ClientInfoButton from '../NavigationButtons/ClientInfoButton.jsx';
import VehicleInfoButton from '../NavigationButtons/VehicleInfoButton.jsx';
import PolicyInfoButton from '../NavigationButtons/PolicyInfoButton.jsx';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import {
    useAttachPolicyParticipantsMutation,
    useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation,
    useUpdatePolicyMutation
} from '../../../features/apiSlice.js';
import ErrorWidget from '../ErrorWidget.jsx';
import Alert from '../../elements/ReactBootstrap/Alert.jsx';
import Percent from '../../elements/Values/Percent.jsx';
import RequireRole from '../../elements/AccessControl/RequireRole.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import TextWithCopyButton from '../TextWithCopyButton.jsx';
import EditInputGroup from "../../elements/ReactBootstrap/EditInputGroup.jsx";
import EditButton from "../SimpleButtons/EditButton.jsx";
import SaveButton from "../SimpleButtons/SaveButton.jsx";
import CloseButton from "../SimpleButtons/CloseButton.jsx";
import PolicyAssistanceCardWidget from "../PolicyAssistanceCardWidget.jsx";
import AssistanceCardInfoButton from "../NavigationButtons/AssistanceCardInfoButton.jsx";
import {vehicleTypes} from "../../../utils.js";

const PolicyInfoWidget = ({
    policy,
    header,
    noInfoButton,
    detailed,
    printing,
    setPrinting,
}) => {
    const [create, {
        isSuccess: createSuccess,
        error: createError,
        isError: createIsError,
        isLoading: createLoading,
    }] = useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation();

    const [update, {
        isSuccess: updateSuccess,
        error: updateError,
        isError: updateIsError,
    }
    ] = useUpdatePolicyMutation();

    const [attachPolicyParticipants, {
        isSuccess: attachSuccess,
        error: attachError,
        isError: attachIsError,
    }] = useAttachPolicyParticipantsMutation();

    const [editIssueDate, setEditIssueDate] = useState(false);
    const [editBeginDate, setEditBeginDate] = useState(false);
    const [editEndDate, setEditEndDate] = useState(false);
    const [editPolicyHolder, setEditPolicyHolder] = useState(false);
    const [editContactClient, setEditContactClient] = useState(false);
    const [editInsuredVehicle, setEditInsuredVehicle] = useState(false);
    const [editInsuredClients, setEditInsuredClients] = useState(false);
    const [editPolicyCommissionCoefficient, setEditPolicyCommissionCoefficient] = useState(false);
    const [editVoucherCommissionCoefficient, setEditVoucherCommissionCoefficient] = useState(false);
    const [editCorrectionCoefficient, setEditCorrectionCoefficient] = useState(false);
    const [editCorrectionDescription, setEditCorrectionDescription] = useState(false);
    const [editAssistanceCard, setEditAssistanceCard] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [newValue, setNewValue] = useState('');

    const addInputField = ()=>{
        setInputFields([...inputFields, ''])
    }
    const removeInputFields = (index)=>{
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, value)=>{
        const newInputFields = inputFields.map((inputFieldItem, inputFieldIndex)=>{
            return inputFieldIndex === index ? value : inputFieldItem;
        });
        setInputFields(newInputFields);
    }

    const filterInsuredClients = useCallback((insuredClients, isPrevious) => insuredClients.filter(
        insuredClient => insuredClient.isPrevious === isPrevious).map(
            insuredClient => insuredClient.insuredClient
    ), []
    );

    const hasUnpaidInstallment = useMemo(() => policy.installments.filter(
        installment => !installment.paidPremiumFromClient).length > 0, [policy.installments]
    );
    const previousInsuredClients = useMemo(() =>
        filterInsuredClients(policy.insuredClients, true), [filterInsuredClients, policy.insuredClients]
    );
    const currentInsuredClients = useMemo(() =>
        filterInsuredClients(policy.insuredClients, false), [filterInsuredClients, policy.insuredClients]
    );

    const hasTax = policy.product.insuranceType !== 'life';
    const hasMtplCover = policy.product.hasMtplCover;
    const hasGf = hasMtplCover || policy.product.hasPassengersAccidentCover;
    // TODO ugly workaround
    const mustHaveVehicle = hasMtplCover || policy.product.insuranceType === 'casco' ||
        policy.product.insuranceType === 'assistance-bg' || policy.product.insuranceType === 'accident-passengers' ||
        policy.product.insuranceType === 'accident-seats' || policy.product.insuranceType === 'assistance-eu' ||
        policy.product.insuranceType === 'tpl-carrier'

    const canHaveAssistanceCard = policy.product.insuranceType === 'casco' && policy.insuranceCompany.number === 3;

    return (<>
        <Card header={header}>
            <Row as="dl">
                <Col sm={4} as="dt">Номер</Col>
                <Col sm={8} as="dd">{noInfoButton ?
                    <TextWithCopyButton text={policy.number} /> :
                    <PolicyInfoButton policy={policy} />
                }</Col>
                <Col sm={4} as="dt">Продукт</Col>
                <Col sm={8} as="dd">{policy.productName}</Col>
                <Col sm={4} as="dt">Брой вноски</Col>
                <Col sm={8} as="dd">{policy.installmentsCount}</Col>
                {policy.insuranceCompany?.shortName && <>
                    <Col sm={4} as="dt">Застрахователна компания</Col>
                    <Col sm={8} as="dd">{policy.insuranceCompany.shortName}</Col>
                </>}
                {detailed && <>
                    {policy.issueInsuranceBroker?.shortName && <>
                        <Col sm={4} as="dt">Издадена от брокер</Col>
                        <Col sm={8} as="dd">{policy.issueInsuranceBroker?.shortName}</Col>
                    </>}
                    {policy.issueOffice?.name && <>
                        <Col sm={4} as="dt">Офис</Col>
                        <Col sm={8} as="dd">{policy.issueOffice?.name}</Col>
                    </>}
                    {policy.issueUser?.shortName && <>
                        <Col sm={4} as="dt">Консултант</Col>
                        <Col sm={8} as="dd">{policy.issueUser?.shortName}</Col>
                    </>}
                </>}
                <Col sm={4} as="dt">Застраховащо лице</Col>
                <Col sm={8} as="dd">
                    {policy.policyHolder && <ClientInfoButton client={policy.policyHolder} textField="name" />}
                    {!editPolicyHolder && <EditButton onClick={() =>{
                        setEditPolicyHolder(oldState => !oldState);
                        setNewValue('');
                    }}/>}
                </Col>
                {policy.policyHolder?.name && <Col sm={{ span: 8, offset: 4 }} as="dd"><ClientInfoButton client={policy.policyHolder} /></Col>}
                {editPolicyHolder && <Col sm={{ span: 8, offset: 4 }} as="dd">
                    <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                                    placeholder="въведете ЕГН/ЕИК"
                        saveOnClick={() => {
                            if (!/^\d{9,10}$/i.test(newValue)) {
                                alert('Моля, въведете коректно ЕГН/ЕИК!');
                            }
                            else {
                                attachPolicyParticipants([policy.id, {
                                    policyHolderPin: newValue
                                }]);
                                setEditPolicyHolder(oldState => !oldState);
                                setNewValue('');
                            }
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditPolicyHolder(oldState => !oldState)} />
                </Col>}
                <Col sm={4} as="dt">Лице за контакт</Col>
                <Col sm={8} as="dd">
                    {policy.contactClient && <ClientInfoButton client={policy.contactClient} textField="name" />}
                    {!editContactClient && <EditButton onClick={() =>{
                        setEditContactClient(oldState => !oldState);
                        setNewValue('');
                    }}/>}
                </Col>
                {policy.contactClient?.name && <Col sm={{ span: 8, offset: 4 }} as="dd"><ClientInfoButton client={policy.contactClient} /></Col>}
                {editContactClient && <Col sm={{ span: 8, offset: 4 }} as="dd">
                    <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                                    placeholder="въведете ЕГН/ЕИК"
                                    saveOnClick={() => {
                                        if (!/^\d{9,10}$/i.test(newValue)) {
                                            alert('Моля, въведете коректно ЕГН/ЕИК!');
                                        }
                                        else {
                                            attachPolicyParticipants([policy.id, {
                                                contactClientPin: newValue
                                            }]);
                                            setEditContactClient(oldState => !oldState);
                                            setNewValue('');
                                        }
                                    }}
                                    fieldValue={newValue}
                                    closeOnClick={() => setEditContactClient(oldState => !oldState)} />
                </Col>}
                <Col sm={4} as="dt">Застраховани лица</Col>
                <Col sm={8} as="dd">{currentInsuredClients.length < 1 && !editInsuredClients ?
                    <EditButton onClick={() =>{
                    setEditInsuredClients(oldState => !oldState);
                    setInputFields([]); }}/> : ' '}
                {editInsuredClients && <>
                    <Button variant="outline-success" onClick={addInputField}>+</Button>
                    <SaveButton onClick={() => {
                        let isMatch = inputFields.map(inputField => {
                            return /^\d{9,10}$/i.test(inputField);
                        });
                        if (!isMatch) {
                            alert('Моля, въведете коректно ЕГН/ЕИК!');
                        }
                        else {
                            attachPolicyParticipants([policy.id, {
                                insuredClientsPin: inputFields
                            }]);
                            setEditInsuredClients(oldState => !oldState);
                            setInputFields([]);
                        }
                        }}/>
                    <CloseButton onClick={() => {
                        setEditInsuredClients(oldState => !oldState)
                        setInputFields([]);
                    }} />
                    </>}
                </Col>
                {inputFields.map((data, index)=>{return(
                    <React.Fragment key={index}>
                        <Col sm={4} as="dt">{index+1}</Col>
                        <Col sm={4} as="dd">
                            <FormControl type='text' style={{display: 'flex'}} placeholder="въведете ЕГН/ЕИК"
                                  onChange={(e)=> handleChange(index, e.target.value)}/>
                        </Col>
                        <Col sm={4} as="dd">
                            <Button variant="outline-danger" onClick={removeInputFields}>-</Button>
                        </Col>
                    </React.Fragment>
                )})}
                {currentInsuredClients.map(insuredClient =>
                    <React.Fragment key={insuredClient.id}>
                        <Col sm={4} as="dt">{''}</Col>
                        <Col sm={8} as="dd"><ClientInfoButton client={insuredClient} textField="name" /></Col>
                        {insuredClient.name && <Col sm={{ span: 8, offset: 4 }} as="dd">
                            <ClientInfoButton client={insuredClient} />
                        </Col>}
                    </React.Fragment>
                )}
                {previousInsuredClients.length > 0 && <>
                    <Col sm={4} as="dt">Предишни застраховани лица</Col>
                    <Col sm={8} as="dd">{''}</Col>
                </>
                }
                {previousInsuredClients.map(previousInsuredClient =>
                    <React.Fragment key={previousInsuredClient.id}>
                        <Col sm={4} as="dt">{''}</Col>
                        <Col sm={8} as="dd"><ClientInfoButton client={previousInsuredClient} textField="name" /></Col>
                        {previousInsuredClient.name && <Col sm={{ span: 8, offset: 4 }} as="dd">
                            <ClientInfoButton client={previousInsuredClient} />
                        </Col>}
                    </React.Fragment>
                )}
                {mustHaveVehicle && <>
                    <Col sm={4} as="dt">МПС</Col>
                    {policy.insuredVehicle ? <>
                        <Col sm={8} as="dd"><VehicleInfoButton vehicle={policy.insuredVehicle} textField="plateNumber" /></Col>
                        {policy.insuredVehicle.modelName && <Col sm={{ span: 8, offset: 4 }} as="dd">
                            <VehicleInfoButton vehicle={policy.insuredVehicle} textField="modelName" /><br/>
                            {vehicleTypes[policy.insuredVehicle.type]}
                        </Col>}
                    </> : !editInsuredVehicle && <Col sm={8} as="dd"><EditButton onClick={() =>{
                        setEditInsuredVehicle(oldState => !oldState);
                        setNewValue(policy.insuredVehicle);
                    }}/></Col>
                    }
                     {editInsuredVehicle && <Col sm={8} as="dd">
                         <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                                         placeholder="въведете VIN/шаси"
                            saveOnClick={() => {
                                if (newValue.length < 4 || newValue.length > 17) {
                                    alert('Моля, въведете коректно шаси!');
                                }
                                else {
                                    attachPolicyParticipants([policy.id, {
                                        vehicleVin: newValue
                                    }]);
                                    setEditInsuredVehicle(oldState => !oldState);
                                    setNewValue('');
                                }
                            }}
                            fieldValue={newValue}
                            closeOnClick={() => setEditInsuredVehicle(oldState => !oldState)}
                         />
                     </Col>}
                </>}
                <Col sm={4} as="dt">Дата на сключване</Col>
                <Col sm={8} as="dd">{editIssueDate ?
                        <EditInputGroup type="datetime-local" formControlOnChange={e=>setNewValue(e.target.value)}
                                saveOnClick={() => {
                                    update([policy.id, {
                                        issueDate: newValue
                                    }]);
                                    setEditIssueDate(oldState => !oldState);
                                    setNewValue('');
                                }}
                                fieldValue={newValue}
                                closeOnClick={() => setEditIssueDate(oldState => !oldState)} />
                        : <> {policy.issueDate ? <DateTimeLong date={policy.issueDate} /> :
                            <EditButton onClick={() =>{
                                setEditIssueDate(oldState => !oldState);
                                setNewValue(policy.issueDate);
                            }}/>}
                        </>}
                </Col>
                <Col sm={4} as="dt">Начална дата</Col>
                <Col sm={8} as="dd">{editBeginDate ?
                    <EditInputGroup type="datetime-local" formControlOnChange={e=>setNewValue(e.target.value)}
                        saveOnClick={() => {
                            update([policy.id, {
                                beginDate: newValue
                            }]);
                            setEditBeginDate(oldState => !oldState);
                            setNewValue('');
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditBeginDate(oldState => !oldState)} />
                    : <> {policy.beginDate ? <DateTimeLong date={policy.beginDate} /> :
                        <EditButton onClick={() =>{
                            setEditBeginDate(oldState => !oldState);
                            setNewValue(policy.beginDate);
                        }}/>}
                    </>}
                </Col>
                <Col sm={4} as="dt">Крайна дата</Col>
                <Col sm={8} as="dd">{editEndDate ?
                    <EditInputGroup type="date" formControlOnChange={e=>setNewValue(e.target.value)}
                        saveOnClick={() => {
                            update([policy.id, {
                                endDate: newValue + ' 23:59:59'
                            }]);
                            setEditEndDate(oldState => !oldState);
                            setNewValue('');
                        }}
                        fieldValue={newValue}
                        closeOnClick={() => setEditEndDate(oldState => !oldState)} />
                    : <> {policy.endDate ? <DateTimeLong date={policy.endDate} /> :
                        <EditButton onClick={() =>{
                            setEditEndDate(oldState => !oldState);
                            setNewValue(policy.endDate);
                        }}/>}
                    </>}
                </Col>
                {detailed && <>
                    <Col sm={4} as="dt">Базова премия</Col>
                    <Col sm={8} as="dd">
                        <PremiumAmount amount={policy.premiumAmount} />
                    </Col>
                    {hasTax && <>
                        <Col sm={4} as="dt">Данък по чл. 9 от ЗДЗП (2%)</Col>
                        <Col sm={8} as="dd">
                            <PremiumAmount amount={policy.taxAmount} />
                        </Col>
                    </>}
                    {hasMtplCover && <>
                        <Col sm={4} as="dt">Такса стикер</Col>
                        <Col sm={8} as="dd">
                            <PremiumAmount amount={policy.stickerAmount} />
                        </Col>
                    </>}
                    {hasGf && <>
                        <Col sm={4} as="dt">Такса ГФ и ОФ</Col>
                        <Col sm={8} as="dd">
                            <PremiumAmount amount={policy.guaranteeFundAmount} />
                        </Col>
                    </>}
                </>}
                <Col sm={4} as="dt">Корекция</Col>
                <Col sm={8} as="dd">
                    {policy.correctionCommissionCoefficient !== undefined &&
                        <Percent coefficient={policy.correctionCommissionCoefficient} toFixed={2}/>}
                    {policy.correctionCommissionCoefficient === undefined && <>
                    {editCorrectionCoefficient ?
                        <EditInputGroup type="number" min={1} max={200} inputMode="decimal"
                                        formControlOnChange={e=>setNewValue(e.target.value)}
                                        saveOnClick={() => {
                                            update([policy.id, {
                                                correctionCommissionCoefficient: parseFloat(newValue/100)
                                            }]);
                                            setEditCorrectionCoefficient(oldState => !oldState);
                                            setNewValue('');
                                        }}
                                        fieldValue={newValue}
                                        closeOnClick={() => setEditCorrectionCoefficient(oldState => !oldState)} />
                        : <EditButton onClick={() =>{
                            setEditCorrectionCoefficient(oldState => !oldState);
                            setNewValue(policy.correctionCommissionCoefficient);
                        }}/>}
                    </>}
                </Col>
                <RequireRole name="ROLE_SHOW_CORRECTION_DESCRIPTION">
                    <Col sm={4} as="dt">Партиден номер</Col>
                    <Col sm={8} as="dd"> {policy.correctionDescription !== undefined &&
                        <>{policy.correctionDescription} </>}
                        {policy.correctionDescription === undefined && <>
                            {editCorrectionDescription ?
                                    <EditInputGroup type="number" maxLength={10} inputMode="decimal"
                                        formControlOnChange={e=>setNewValue(e.target.value)}
                                        saveOnClick={() => {
                                            update([policy.id, {
                                                correctionDescription: parseInt(newValue)
                                            }]);
                                            setEditCorrectionDescription(oldState => !oldState);
                                            setNewValue('');
                                        }}
                                        fieldValue={newValue}
                                        closeOnClick={() => setEditCorrectionDescription(oldState => !oldState)} />
                                    : <EditButton onClick={() =>{
                                        setEditCorrectionDescription(oldState => !oldState);
                                        setNewValue(policy.correctionDescription);
                                    }}/>}
                            </>}
                    </Col>
                </RequireRole>
                <Col sm={4} as="dt">Обща дължима премия</Col>
                <Col sm={8} as="dd">
                    <PremiumAmount amount={policy.totalAmount} />
                </Col>
                {detailed && <RequireRole name="ROLE_SHOW_COMMISSION">
                    {policy.policyCommissionCoefficient !== undefined && <>
                        <Col sm={4} as="dt">Преференциален комисион (полица)</Col>
                        <Col sm={8} as="dd">
                            <Percent coefficient={policy.policyCommissionCoefficient} />
                        </Col>
                    </>}
                    {policy.policyCommissionCoefficient === undefined && <RequireRole name="ROLE_BROKER_MANAGER">
                        <Col sm={4} as="dt">Преференциален комисион (полица)</Col>
                        <Col sm={8} as="dd">{editPolicyCommissionCoefficient ?
                            <EditInputGroup type="number" min={0} max={1} step={0.01} inputMode="decimal"
                                formControlOnChange={e=>setNewValue(e.target.value)}
                                saveOnClick={() => {
                                    update([policy.id, {
                                        policyCommissionCoefficient: parseFloat(newValue)
                                    }]);
                                    setEditPolicyCommissionCoefficient(oldState => !oldState);
                                    setNewValue('');
                                }}
                                fieldValue={newValue}
                                closeOnClick={() => setEditPolicyCommissionCoefficient(oldState => !oldState)} />
                            : <EditButton onClick={() =>{
                                setEditPolicyCommissionCoefficient(oldState => !oldState);
                                setNewValue(policy.policyCommissionCoefficient);
                            }}/>}
                        </Col>
                    </RequireRole>}
                    {policy.voucherCommissionCoefficient !== undefined && <>
                        <Col sm={4} as="dt">Преференциален комисион (квитанция)</Col>
                        <Col sm={8} as="dd">
                            <Percent coefficient={policy.voucherCommissionCoefficient} />
                        </Col>
                    </>}
                    {policy.voucherCommissionCoefficient === undefined && <RequireRole name="ROLE_BROKER_MANAGER">
                        <Col sm={4} as="dt">Преференциален комисион (квитанция)</Col>
                        <Col sm={8} as="dd">{editVoucherCommissionCoefficient ?
                            <EditInputGroup type="number" min={0} max={1} step={0.01} inputMode="decimal"
                                formControlOnChange={e=>setNewValue(e.target.value)}
                                saveOnClick={() => {
                                    update([policy.id, {
                                        voucherCommissionCoefficient: parseFloat(newValue)
                                    }]);
                                    setEditVoucherCommissionCoefficient(oldState => !oldState);
                                    setNewValue('');
                                }}
                                fieldValue={newValue}
                                closeOnClick={() => setEditVoucherCommissionCoefficient(oldState => !oldState)} />
                            : <EditButton onClick={() =>{
                                setEditVoucherCommissionCoefficient(oldState => !oldState);
                                setNewValue(policy.voucherCommissionCoefficient);
                            }}/>}
                        </Col>
                    </RequireRole>}
                </RequireRole>}
                {policy.assistanceCard !== undefined && <>
                    <Col sm={4} as="dt">Асистънс карта</Col>
                    <Col sm={8} as="dd"><AssistanceCardInfoButton assistanceCard={policy.assistanceCard}/></Col>
                </>
                }
                {canHaveAssistanceCard && policy.assistanceCard === undefined && <RequireRole name="ROLE_UPDATE_POLICY">
                    <Col sm={4} as="dt">Асистънс карта</Col>
                    <Col sm={8} as="dd">{editAssistanceCard &&
                        <PolicyAssistanceCardWidget policyId={policy.id} insuranceCompanyId={policy.insuranceCompany.id}
                           issueOfficeId={policy.issueOffice.id} />}
                        <EditButton onClick={() =>{setEditAssistanceCard(oldState => !oldState)}}/>
                    </Col>
                </RequireRole>}
                <Col sm={4} as="dt">Последна редакция от</Col>
                <Col sm={8} as="dd">{policy.updatedBy?.fullName && policy.updatedBy.fullName}</Col>
                <Col sm={4} as="dt">Последна редакция на</Col>
                <Col sm={8} as="dd">{policy.updatedAt && <DateTimeLong date={policy.updatedAt} />}</Col>
            </Row>
            <Row className="g-3">
                {detailed && <>
                    <Col sm={6} xl={3}><PolicyPrintButton policy={policy} printing={false} disabled={printing}
                                                   setDisabled={setPrinting} /></Col>
                    {(hasUnpaidInstallment || policy.insuredVehicle) && <Col sm={6} xl={3}>
                        {hasUnpaidInstallment ?
                            <Link to={'/installment-payment/policy/' + policy.id}
                              className="btn btn-outline-secondary w-100">
                                <i className="fas fa-dollar-sign" /> Плащане на вноска
                            </Link> : (policy.insuredVehicle &&
                                <MtplOfferButton
                                    client={policy.policyHolder || policy.insuredClients?.[0]?.insuredClient}
                                    vehicle={policy.insuredVehicle} />)
                        }
                    </Col>}
                </>}
                {policy.updatable &&
                <Col sm={detailed ? 6 : 12} xl={detailed ? 3 : 6}>
                    <Button variant="outline-secondary" className="w-100" onClick={() => create({
                        policyNumber: policy.number,
                    })}>
                        <i className="fas fa-retweet" /> Обновяване от ЗК
                    </Button>
                </Col>
                }
                {policy.insuranceCompany.shortName === 'Групама' && policy.insuranceCompanyReference1 &&
                    <RequireRole name="ROLE_SUPER_ADMIN">
                    <Col sm={detailed ? 6 : 12} xl={detailed ? 3 : 6}>
                        <Button variant="outline-secondary" className="w-100" onClick={() => window.open(
                            'https://online.groupama.bg/bg/auto/solo/policy/print/' +
                            Buffer.from(
                                policy.insuranceCompanyReference1 + '_' + policy.insuranceCompanyReference1)
                                .toString('base64') + '/policy'
                            , "_blank")}>
                            <i className="fas fa-retweet" /> Допълнителен URL за печат
                        </Button>
                    </Col>
                </RequireRole>}
            </Row>
        </Card>
        {(createLoading || createSuccess || createIsError) && <Row>
            <Col>
                {createLoading && <Card>
                    <SmallSpinner />
                </Card>}
                {createSuccess && <Alert variant="success" heading="Успешно обновяване от ЗК" />}
                {createIsError && <ErrorWidget error={createError} />}
            </Col>
        </Row>}
        {(updateSuccess || updateIsError || attachSuccess || attachIsError) && <Row>
            <Col>
                {(updateSuccess || attachSuccess) &&
                    <Alert variant="success" heading="Успешно добавяне на данни по полица" />}
                {updateIsError && <ErrorWidget error={updateError} />}
                {attachIsError && <ErrorWidget error={attachError} />}
            </Col>
        </Row>
        }
    </>);
};

PolicyInfoWidget.defaultProps = {
    noInfoButton: false,
    detailed: false,
};

PolicyInfoWidget.propTypes = {
    policy: PropTypes.shape({
        productName: PropTypes.string,
        insuranceCompany: PropTypes.shape({
            shortName: PropTypes.string.isRequired,
            number: PropTypes.number,
        }),
        installmentsCount: PropTypes.number,
        policyHolder: PropTypes.shape({
            name: PropTypes.string,
            pin: PropTypes.string,
        }),
        contactClient: PropTypes.shape({
            name: PropTypes.string,
            pin: PropTypes.string,
        }),
        insuredVehicle: PropTypes.shape({
            plateNumber: PropTypes.string,
            modelName: PropTypes.string,
        }),
        issueDate: PropTypes.string,
        beginDate: PropTypes.string,
        endDate: PropTypes.string,
        totalAmount: PropTypes.number,
        policyCommissionCoefficient: PropTypes.number,
        voucherCommissionCoefficient: PropTypes.number,
        correctionCommissionCoefficient: PropTypes.number,
        correctionDescription: PropTypes.number,
}).isRequired,
    header: PropTypes.node,
    noInfoButton: PropTypes.bool,
    detailed: PropTypes.bool,
    insuranceCompanyReference1: PropTypes.string,
};

export default PolicyInfoWidget;
