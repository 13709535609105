import {Col, Row} from "react-bootstrap";
import React from "react";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import PropTypes from "prop-types";
import AssistanceCardsListWidget from "../AssistanceCardsListWidget.jsx";

const AssistanceCardsSearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'Асистънс карти (' + length + ')'}>
                <AssistanceCardsListWidget assistanceCards={results} />
            </Card>
        </Col>
    </Row>);
};

AssistanceCardsSearchResult.propTypes = {
    results: PropTypes.array,
};

export default AssistanceCardsSearchResult;
