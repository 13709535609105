import PropTypes from "prop-types";
import {Container, Table} from "react-bootstrap";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import React, {useState} from "react";
import IconField from "../../elements/Formik/IconField.jsx";
import {Form, Formik} from "formik";
import SubmitButton from "../../elements/Form/SubmitButton.jsx";
import useFormPersistedValues from "../../../hooks/useFormPersistedValues.js";
import AutoPersistToken from "../../elements/Formik/AutoPersistToken.jsx";
import SwitchButton from "../../elements/Form/SwitchButton.jsx";
import {formatInTimeZone} from "date-fns-tz";

const AddUserLimitWidget = ({
    onSubmit,
}) => {

    const formName = 'addUserLimit';
    const initialFormValues = useFormPersistedValues(formName);
    const [disabled, setDisabled] = useState(false);
    const [loan, setLoan] = useState(false);
    const todayFormatted= formatInTimeZone(new Date(), 'Europe/Sofia', 'yyyy-MM-dd HH:mm')

    return (
        <Card noPadding header={<>Добавяне на лимит</>}>
            <Formik
                initialValues={initialFormValues || {
                    loadDate: todayFormatted,
                    amountFromBroker: 0,
                    amountFromCommission: 0,
                    amountFromUser: 0,
                    loan: false
                }}
                validate={values => {
                    const errors = {};
                    if (values.amountFromBroker === 0
                        && values.amountFromCommission  === 0
                        && values.amountFromUser === 0 ) {
                        errors.loadDate = 'Моля, въведете сума на лимита!';
                    }

                    if(loan) {
                        if (values.amountFromUser === 0) {
                            errors.amountFromUser = 'Моля, въведете сумата за заем в поле Захранване!';
                        }

                        if (values.amountFromBroker !== 0) {
                            errors.amountFromBroker = 'Моля, не въвеждайте сума в поле Кредит!';
                        }

                        if (values.amountFromCommission !== 0) {
                            errors.amountFromCommission = 'Моля, не въвеждайте сума в поле Комисион!';
                        }
                    }

                    return errors;
                }}
                onSubmit={values => onSubmit({
                    ...values,
                    ...(loan ? {
                        loan: true,
                    } : {}),
                })}>
                {({isSubmitting}) => (
            <Container as={Form} fluid className="gx-0">
                <div className="table-responsive">
                    <Table striped className="mb-0">
                        <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Кредит</th>
                            <th>Комисион</th>
                            <th>Захранване</th>
                            <th>Заем</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <IconField name="loadDate" type="datetime-local" disabled={isSubmitting || disabled}
                                           max={todayFormatted} />
                            </td>
                            <td>
                                <IconField type="number" name="amountFromBroker" inputMode="decimal" maxLength={10}
                                           disabled={isSubmitting || disabled} autoComplete="off" />
                            </td>
                            <td>
                                <IconField type="number" name="amountFromCommission" inputMode="decimal" maxLength={10}
                                           disabled={isSubmitting || disabled} autoComplete="off" />
                            </td>
                            <td>
                                <IconField type="number" name="amountFromUser" inputMode="decimal" disabled={isSubmitting}
                                           maxLength={10} autoComplete="off"/>
                            </td>
                            <td>
                                <SwitchButton name="loan" checked={loan} value={loan}
                                              onChange={checked => {setDisabled(checked); setLoan(checked)}}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                    <div className="m-3">
                        <SubmitButton>Запиши</SubmitButton>
                    </div>
                <AutoPersistToken formName={formName}/>
            </Container>
                )}
            </Formik>
        </Card>
    );
}
AddUserLimitWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default AddUserLimitWidget;