import useCurrentUserHasRole from '../../hooks/useCurrentUserHasRole.js';
import SwitchButton from '../elements/Form/SwitchButton.jsx';
import BadgeSwitchButton from '../elements/Form/BadgeSwitchButton.jsx';
import {
    useUpdateAssistanceCardMutation,
    useUpdateGreenCardMutation,
    useUpdateStickerMutation,
    useUpdateUabCardMutation,
    useUpdateValuationCertificateMutation
} from '../../features/apiSlice.js';
import {useCallback} from 'react';

const BsoStatusWidget = ({
    id,
    type,
    assigned,
    expended,
    inTransit,
    defective,
    lost,
    returned,
    reserved,
    updateRole,
}) => {
    const [ updateSticker ] = useUpdateStickerMutation();
    const [ updateGreenCard ] = useUpdateGreenCardMutation();
    const [ updateUabCard ] = useUpdateUabCardMutation();
    const [ updateValuationCertificate ] = useUpdateValuationCertificateMutation();
    const [ updateAssistanceCard ] = useUpdateAssistanceCardMutation();

    const update = useCallback((key, value) => {
        const values = {
            [key]: value,
        };

        switch (type) {
            case 'sticker':
                return updateSticker([id, values]);
            case 'greenCard':
                return updateGreenCard([id, values]);
            case 'uabCard':
                return updateUabCard([id, values]);
            case 'valuationCertificate':
                return updateValuationCertificate([id, values]);
            case 'assistanceCard':
                return updateAssistanceCard([id, values]);
            default:
                return Promise.reject();
        }
    }, [id, type, updateGreenCard, updateSticker, updateUabCard, updateValuationCertificate, updateAssistanceCard]);

    const hasUpdateRole = useCurrentUserHasRole(updateRole);
    const isSuperAdmin = useCurrentUserHasRole('ROLE_SUPER_ADMIN');
    const lineHeight = hasUpdateRole ? 1.3 : 1;

    return (<div className="d-flex gap-1 flex-wrap">
        {type && hasUpdateRole ? <>
            <SwitchButton styleOn=" bg-green" labelOn="разпределена" width={126} checked={assigned}
                          styleOff=" bg-blue" labelOff="неразпределена"
                          onChange={checked => update('assigned', checked)} />
            <SwitchButton styleOn=" bg-teal" labelOn="използвана" width={111} checked={expended}
                          styleOff=" bg-indigo" labelOff="неизползвана"
                          onChange={checked => update('expended', checked)} />
            <BadgeSwitchButton badgeClassName="bg-pink" invertColors={true} checked={defective}
                onChange={checked => update('defective', checked)}>
                похабена/унищожена
            </BadgeSwitchButton>
            <BadgeSwitchButton badgeClassName="bg-red" invertColors={true} checked={lost}
                onChange={checked => update('lost', checked)}>
                изгубена
            </BadgeSwitchButton>
            <BadgeSwitchButton badgeClassName="bg-secondary" styleOn="secondary" styleOff="success" checked={returned}
                onChange={checked => update('returned', checked)}>
                върната на издателя
            </BadgeSwitchButton>
        </> : <>
            {assigned ?
                <span className="badge bg-green">разпределена</span> :
                <span className="badge bg-blue">неразпределена</span>
            }
            {expended ?
                <span className="badge bg-teal">използвана</span> :
                <span className="badge bg-indigo">неизползвана</span>
            }
            {defective && <span className="badge bg-pink">похабена/унищожена</span>}
            {lost && <span className="badge bg-red">изгубена</span>}
            {returned && <span className="badge bg-secondary">върната на издателя</span>}
        </>}
        {type && hasUpdateRole && isSuperAdmin ? <>
            <BadgeSwitchButton badgeClassName="bg-orange" checked={inTransit}
                onChange={checked => update('inTransit', checked)}>
                трансфер
            </BadgeSwitchButton>
            <BadgeSwitchButton badgeClassName="bg-yellow" checked={reserved}
                onChange={checked => update('reserved', checked)}>
                в протокол
            </BadgeSwitchButton>
        </> : <>
            {inTransit && <span className="badge bg-orange" style={{ lineHeight }}>трансфер</span>}
            {reserved && <span className="badge bg-yellow" style={{ lineHeight }}>в протокол</span>}
        </>}
    </div>);
};

export default BsoStatusWidget;
