import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import CopyButton from './SimpleButtons/CopyButton.jsx';
import PolicyInfoButton from './NavigationButtons/PolicyInfoButton';
import BsoStatusWidget from './BsoStatusWidget.jsx';
import AssistanceCardNumber from "../elements/Values/AssistanceCardNumber.jsx";

const AssistanceCardInfoWidget = ({
    assistanceCard,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            <Col sm={4} as="dt">Номер</Col>
            <Col sm={8} as="dd">
                <AssistanceCardNumber {...assistanceCard} />
                <CopyButton text={assistanceCard.series + assistanceCard.number} />
            </Col>
            <Col sm={4} as="dt">Статус</Col>
            <Col sm={8} as="dd"><BsoStatusWidget type="assistanceCard" updateRole="ROLE_UPDATE_ASSISTANCE_CARD" {...assistanceCard} /></Col>
            {assistanceCard.insuranceCompany && <>
                <Col sm={4} as="dt">Застрахователна компания</Col>
                <Col sm={8} as="dd">{assistanceCard.insuranceCompany.shortName}</Col>
            </>}
            {assistanceCard.insuranceBroker && <>
                <Col sm={4} as="dt">Брокер</Col>
                <Col sm={8} as="dd">{assistanceCard.insuranceBroker.shortName}</Col>
            </>}
            {assistanceCard.office && <>
                <Col sm={4} as="dt">Офис</Col>
                <Col sm={8} as="dd">{assistanceCard.office.name}</Col>
            </>}
            {assistanceCard.user && <>
                <Col sm={4} as="dt">Отговорно лице</Col>
                <Col sm={8} as="dd">{assistanceCard.user.shortName}</Col>
            </>}
            {assistanceCard.policy && <>
                <Col sm={4} as="dt">Използвана по полица</Col>
                <Col sm={8} as="dd"><PolicyInfoButton policy={assistanceCard.policy} /></Col>
            </>}
        </Row>
    </>);
};

AssistanceCardInfoWidget.propTypes = {
    assistanceCard: PropTypes.shape({
        number: PropTypes.string.isRequired,
    }).isRequired,
};

export default AssistanceCardInfoWidget;
