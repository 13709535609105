import React from 'react';
import Content from '../partials/Content.jsx';
import {Navigate, useSearchParams} from 'react-router-dom';
import PolicySearchResult from '../widgets/SearchResults/PolicySearchResult';
import StickerSearchResult from '../widgets/SearchResults/StickerSearchResult.jsx';
import GreenCardSearchResult from '../widgets/SearchResults/GreenCardSearchResult.jsx';
import useSearchResults from '../../hooks/useSearchResults.js';
import ErrorWidget from '../widgets/ErrorWidget.jsx';
import Card from '../elements/ReactBootstrap/Card.jsx';
import VoucherSearchResult from '../widgets/SearchResults/VoucherSearchResult.jsx';
import ClientSearchResult from '../widgets/SearchResults/ClientSearchResult.jsx';
import VehicleSearchResult from '../widgets/SearchResults/VehicleSearchResult.jsx';
import ApplicationSearchResult from '../widgets/SearchResults/ApplicationSearchResult.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import UabCardSearchResult from '../widgets/SearchResults/UabCardSearchResult.jsx';
import AssistanceCardsSearchResult from "../widgets/SearchResults/AssistanceCardsSearchResult.jsx";

const Search = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get('query');
    const {
        results,
        errors,
        inProgress,
        singleResult,
        noResults,
    } = useSearchResults(query);

    if (singleResult) {
        const singleResultType = Object.keys(singleResult).shift();
        const singleResultEntity = singleResult[singleResultType][0];
        const singleResultPath = (() => {
            switch (singleResultType) {
                case 'policies':
                    return '/policy/' + singleResultEntity.id;
                case 'stickers':
                    return '/sticker/' + singleResultEntity.id;
                case 'greenCards':
                    return '/green-card/' + singleResultEntity.id;
                case 'uabCards':
                    return '/uab-card/' + singleResultEntity.id;
                case 'assistanceCards':
                    return '/assistance-card/' + singleResultEntity.id;
                case 'vouchers':
                    return '/policy/' + singleResultEntity.installments?.[0]?.policy?.id;
                case 'clients':
                    return '/client/' + singleResultEntity.id;
                case 'vehicles':
                    return '/vehicle/' + singleResultEntity.id;
                case 'applications':
                    return '/' + singleResultEntity.product.insuranceType + '/new/application/' + singleResultEntity.id;
            }
        })();

        if (singleResultPath) {
            return <Navigate to={singleResultPath} replace />;
        }
    }

    return (<Content title={'Търсене на "' + query + '"'}>
        {inProgress && <Card>
            <SmallSpinner />
        </Card>}
        {noResults && <Card>
            Няма намерени резултати <i className="fa-regular fa-face-frown-open" />
        </Card>}
        {inProgress || <>
            <PolicySearchResult results={results.policies} />
            <StickerSearchResult results={results.stickers} />
            <GreenCardSearchResult results={results.greenCards} />
            <AssistanceCardsSearchResult results={results.assistanceCards} />
            <UabCardSearchResult results={results.uabCards} />
            <VoucherSearchResult results={results.vouchers} />
            <ClientSearchResult results={results.clients} />
            <VehicleSearchResult results={results.vehicles} />
            <ApplicationSearchResult results={results.applications} />
        </>}
        {Object.entries(errors).map(([error, key]) => <ErrorWidget key={key} error={error} />)}
    </Content>);
};

export default Search;
