import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import PolicySearchWidget from '../widgets/Policy/PolicySearchWidget.jsx';
import {Button, Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';
import SmallSpinner from "../elements/Spinner/SmallSpinner.jsx";
import ErrorWidget from "../widgets/ErrorWidget.jsx";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {
    useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation,
    useLazyListPoliciesQuery
} from "../../features/apiSlice.js";

const IssueAnnexFind = () => {
    const {annexType} = useParams();
    const navigate = useNavigate();


    const {action} = useParams();

    const [list, {
        data: listResult,
        error: listError,
        isLoading: listLoadingOriginal,
        isFetching: listFetchingOriginal,
        isSuccess: listSuccessOriginal,
        isError: listIsError,
    }] = useLazyListPoliciesQuery();

    const listLoading = listLoadingOriginal || listFetchingOriginal;
    const listSuccess = listSuccessOriginal && !listLoading;

    const [create, {
        reset: createReset,
        error: createError,
        isError: createIsError,
        isLoading: createLoading,
    }] = useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation();

    return (<Content title="Въвеждане на плащане към полица">
        <Row>
            <Col lg={12}>
                <Card outline>
                    <NavLink to="/annex/issue/plate-number">
                        <Button variant="app" active={annexType === 'plate-number'}>
                            <i className="fas  fa-file text-teal" />{' '}Анекс смяна на СРМПС
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/insured">
                        <Button variant="app" active={annexType === 'insured'}>
                            <i className="fas  fa-id-card text-warning" />{' '}Анекс смяна на собственост
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/sticker">
                        <Button variant="app" active={annexType === 'sticker'}>
                            <i className="fas fa-shield" />{' '}Анекс за стикер
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/green-card">
                        <Button variant="app" active={annexType === 'green-card'}>
                            <i className="fas fa-money-check text-success" />{' '}Зелена карта
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/other">
                        <Button variant="app" active={annexType === 'other'}>
                            <i className="fas fa-file-circle-plus text-blue" />{' '}Добавък
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/cancellation">
                        <Button variant="app" active={annexType === 'cancellation'}>
                            <i className="fas fa-file-circle-xmark text-dark" />{' '}Анулиране
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/voucher">
                        <Button variant="app" active={annexType === 'voucher'}>
                            <i className="fas fa-ticket text-red" />{' '}Квитанция
                        </Button>
                    </NavLink>
                </Card>
                {annexType !== undefined && <Card>
                    <PolicySearchWidget formName="policySearchInstallment"
                                        onSubmit={async values => {
                                            navigate('/annex/issue/' + annexType, {
                                                replace: true,
                                            });

                                            createReset();

                                            const result = await list({
                                                query: values.policyNumber,
                                            }).unwrap();

                                            if (result[0]) {
                                                navigate('/annex/issue/'+ annexType + '/' + result[0].id);
                                                return;
                                            }

                                            const importedPolicy = await create(values).unwrap();
                                            navigate('/annex/issue/'+ annexType + '/' + importedPolicy.id);
                                        }} autoFocus={true} autoSubmit={action === 'find'} />
                    {listLoading && <Card>Търсене на полица... <SmallSpinner /></Card>}
                    {listIsError && <ErrorWidget error={listError} />}
                    {createLoading && <Card>Импортиране на полица... <SmallSpinner /></Card>}
                    {createIsError && <ErrorWidget error={createError} />}
                    {listSuccess && listResult.length > 1 && 'списък с полици с един и същи номер при различни ЗК'}
                </Card>}
            </Col>
        </Row>
    </Content>);
};

export default IssueAnnexFind;
