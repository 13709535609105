import {useGetCurrentUserQuery} from '../features/apiSlice';

const useCurrentBrokerIsDemo = () => {
    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    return currentUser.insuranceBroker.demo;
};

export default useCurrentBrokerIsDemo;
