import React from 'react';
import {useListProductsQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import {Table} from "react-bootstrap";

const ProductsWidget = () => {
    const {
        data: products,
        error: productsError,
        isSuccess: productsSuccess,
        isLoading: productsLoading,
        isError: productsIsError,
    } = useListProductsQuery();

    return (<>
        {productsLoading && <>Зареждане на информация за продукти... <SmallSpinner /></>}
        {productsIsError && <ErrorWidget error={productsError} />}
        {productsSuccess && <div className="table-responsive">
            <Table striped bordered>
                <thead>
                <tr>
                    <th>Продукт</th>
                    <th>Описание</th>
                    <th>Тип</th>
                    <th>ГО покритие</th>
                    <th>ЗП покритие</th>
                    <th>KФН Категория</th>
                </tr>
                </thead>
                <tbody>
            {products.map(product => {
                return (<tr key={product.id}>
                    <td>{product.shortName}</td>
                    <td>{product.name}</td>
                    <td>{product.insuranceType}</td>
                    <td>{product.hasMtplCover ? 'да' : 'не'}</td>
                    <td>{product.hasPassengersAccidentCover ? 'да' : 'не'}</td>
                    <td>{product.fscCategory?.name}</td>
                </tr>);
            })}
                </tbody>
            </Table>
        </div>}
    </>);
};

export default ProductsWidget;
