import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {useListUserLevelsQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const UserLevelSelector = ({
    required,
    isClearable,
    defaultValueFilter,
    ...props
}) => {
    const {
        data: userLevelsList,
        error: userLevelsLisError,
        isLoading: userLevelsLisLoading,
        isSuccess: userLevelsLisSuccess,
        isError: userLevelsLisIsError,
    } = useListUserLevelsQuery();

    const userLevelsOptions = useMemo(
        () => userLevelsLisSuccess ? userLevelsList.map(userLevel => ({
            value: userLevel.id,
            label: userLevel.name,
        })).sort((a, b) => a.label.localeCompare(b.label)) : [],
        [userLevelsLisSuccess, userLevelsList]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {userLevelsLisLoading && <SmallSpinner />}
        {userLevelsLisSuccess && <Field component={SelectField} name="userLevel" options={userLevelsOptions}
            disabled={isSubmitting} isClearable={isClearable} required={required}
            defaultValue={defaultValueFilter ? defaultValueFilter(userLevelsOptions) : []}
            placeholder={required ? 'Изберете ниво' : 'Всички нива'}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {userLevelsLisIsError && <ErrorIcon error={userLevelsLisError} />}
    </>);
};

UserLevelSelector.defaultProps = {
    required: false,
    isClearable: false,
};

UserLevelSelector.propTypes = {
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default UserLevelSelector;
