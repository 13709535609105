import React, {useMemo} from 'react';
import {createFilter} from 'react-select';
import {useGetCurrentInsuranceBrokerQuery, useGetCurrentUserQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import SelectField from '../ReactSelect/SelectField';
import {Field, useFormikContext} from 'formik';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';
import useCurrentUserHasRole from "../../../hooks/useCurrentUserHasRole.js";

const OfficeSelector = ({
    defaultValueFilter,

    ...props
}) => {
    const hasRoleShowBroker = useCurrentUserHasRole('ROLE_SHOW_BROKER');

    const {
        data: insuranceBroker,
        error: insuranceBrokerError,
        isLoading: insuranceBrokerIsLoading,
        isSuccess: insuranceBrokerIsSuccess,
        isError: insuranceBrokerIsError,
    } = useGetCurrentInsuranceBrokerQuery();

    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const offices = useMemo(
        () => insuranceBrokerIsSuccess ? insuranceBroker.offices.filter(
            office => office.active
        ).map(office => ({
            value: office.id,
            label: 'Oфис ' + office.name,
        })).sort((a, b) => a.label.localeCompare(b.label)) : [{
            value: currentUser.office.id,
            label: 'Oфис ' + currentUser.office.name,
        }],
        [insuranceBrokerIsSuccess, insuranceBroker, currentUser]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {insuranceBrokerIsLoading && <SmallSpinner />}
        {insuranceBrokerIsError && hasRoleShowBroker ? <ErrorIcon error={insuranceBrokerError} /> :
        <Field component={SelectField} options={offices}
            disabled={isSubmitting} placeholder={props.required ? 'Изберете офис' : 'Всички офиси'}
            defaultValue={defaultValueFilter ? defaultValueFilter(offices) : []}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
    </>);
};

OfficeSelector.defaultProps = {
    name: 'office',
    required: false,
    isClearable: false,
};

OfficeSelector.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
    defaultValueFilter: PropTypes.func,
};

export default OfficeSelector;
