import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import UserInfoButton from './NavigationButtons/UserInfoButton.jsx';
import ApplicationInfoButton from './NavigationButtons/ApplicationInfoButton.jsx';
import DateTimeShort from '../elements/DateTime/DateTimeShort.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';
import useCurrentBrokerIsDemo from "../../hooks/useCurrentBrokerIsDemo.js";

const ApplicationsListWidget = ({
    applications,
}) => {
    const isDemo = useCurrentBrokerIsDemo();

    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    <th>ДКН</th>
                    <th>СРМПС</th>
                    <th>ЕГН/ЕИК</th>
                    <th>Изготвено от</th>
                    <th>Дата</th>
                </tr>
            </thead>
            <tbody>
                {applications.map(application => <tr key={application.id}>
                    <td>
                        <ApplicationInfoButton application={application} />
                    </td>
                    <td>
                        <TextWithCopyButton text={application.registrationCertificateNumber} />
                    </td>
                    <td className={isDemo ? 'demo' : null}>
                        <TextWithCopyButton text={application.personalNumber} />
                    </td>
                    <td>
                        <UserInfoButton user={application.user} />
                    </td>
                    <td>
                        <DateTimeShort date={application.createdAt} />
                    </td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

ApplicationsListWidget.propTypes = {
    applications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        plateNumber: PropTypes.string.isRequired,
        registrationCertificateNumber: PropTypes.string.isRequired,
        personalNumber: PropTypes.string.isRequired,
    }).isRequired),
};

export default ApplicationsListWidget;
