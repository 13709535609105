import PropTypes from 'prop-types';
import {useGetCurrentUserQuery} from "../../../features/apiSlice.js";

const PremiumAmount = ({
                                amount,
                                withCurrency,
                            }) => {
    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const isDemo = currentUser.insuranceBroker.demo;

    return (<span className={isDemo ? 'demo' : null}>{new Intl.NumberFormat('bg-BG', withCurrency ? {
        currency: 'BGN',
        style: 'currency',
    } : {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount)}</span>)
};

PremiumAmount.defaultProps = {
    withCurrency: true,
};

PremiumAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    withCurrency: PropTypes.bool,
};

export default PremiumAmount;