import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';
import AssistanceCardNumber from "../../elements/Values/AssistanceCardNumber.jsx";

const AssistanceCardInfoButton = ({
    assistanceCard,
    textField,
    small,
}) => {
    return (<NavigationButton link={'/assistance-card/' + assistanceCard.id} copyText={assistanceCard.number}
                              isWarning={!assistanceCard.assigned} small={small}>
        {textField && !!assistanceCard[textField] ? assistanceCard[textField] : <AssistanceCardNumber {...assistanceCard} />}
    </NavigationButton>);
};

AssistanceCardInfoButton.defaultProps = {
    small: false,
};

AssistanceCardInfoButton.propTypes = {
    assistanceCard: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
    }),
    textField: PropTypes.string,
    small: PropTypes.bool,
};

export default AssistanceCardInfoButton;
