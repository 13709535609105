import React from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useAttachVoucherPublishersMutation} from "../../features/apiSlice.js";
import ErrorWidget from "./ErrorWidget.jsx";
import IssueUserSelectionWidget from "./IssueUserSelectionWidget.jsx";

const VoucherIssueUserWidget = ({
    voucherId
}) => {
    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useAttachVoucherPublishersMutation();

    return (
        <Formik
            initialValues={{
                issueUser: '',
            }}
            validate={values => {
                const errors = {};
                if (!values.issueUser) {
                    errors.issueUser = 'Моля изберете консултант!';
                }
                return errors;
            }}
            onSubmit={(values) => { update([voucherId, values.issueUser]);
            }}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <IssueUserSelectionWidget/>
                        <Col>
                            {updateIsError && <ErrorWidget error={updateError} />}
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

VoucherIssueUserWidget.propTypes = {
    voucherId: PropTypes.string,
};
export default VoucherIssueUserWidget;
