import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import React from 'react';
import {useCreateVehicleMutation, useLazyListVehiclesQuery} from '../../features/apiSlice';
import {useNavigate} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import VehicleSearchWidget from "../widgets/Vehicle/VehicleSearchWidget.jsx";

const CreateVehicle = () => {
    const navigate = useNavigate();

    const [list, {
        data: listResult,
        error: listError,
        isLoading: listLoadingOriginal,
        isFetching: listFetchingOriginal,
        isSuccess: listSuccessOriginal,
        isError: listIsError,
    }] = useLazyListVehiclesQuery();

    const listLoading = listLoadingOriginal || listFetchingOriginal;
    const listSuccess = listSuccessOriginal && !listLoading;

    const [create, {
        reset: createReset,
        error: createError,
        isError: createIsError,
        isLoading: createLoading,
    }] = useCreateVehicleMutation();

    return (<Content title="Въвеждане на МПС">
        <Row className="row-cols row-cols-1 row-cols-xl-2">
            <Col>
                <Card outline>
                    <VehicleSearchWidget formName="vehicleCreate" findButtonIcon="fas fa-plus" findButtonLabel="Въвеждане"
                        onSubmit={async values => {
                            createReset();

                            const result = await list({
                                query: values.vin,
                            }).unwrap();

                            if (result[0]) {
                                navigate('/vehicle/' + result[0].id);
                                return;
                            }

                            const createdVehicle = await create({
                                vin: values.vin,
                            }).unwrap();
                            navigate('/vehicle/' + createdVehicle.id);
                        }} >
                    </VehicleSearchWidget>
                </Card>
                {listLoading && <Card>Търсене на МПС... <SmallSpinner /></Card>}
                {listIsError && <ErrorWidget error={listError} />}
                {createLoading && <Card>Въвеждане на МПС... <SmallSpinner /></Card>}
                {createIsError && <ErrorWidget error={createError} />}
                {listSuccess && listResult.length > 1 && 'списък на МПС-та с един и същи идентификатор'}
            </Col>
        </Row>
    </Content>);
};

export default CreateVehicle;
