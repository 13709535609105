import {Nav} from 'react-bootstrap';
import SidebarNavItem from '../elements/SidebarNavItem.jsx';
import RequireNonProdEnv from '../elements/AccessControl/RequireNonProdEnv.jsx';
import RequireRole from '../elements/AccessControl/RequireRole.jsx';
import React from 'react';
import {useGetCurrentUserQuery} from '../../features/apiSlice.js';

const SidebarNavWidget = () => {
    const {
        data: user,
        isSuccess: userSuccess,
    } = useGetCurrentUserQuery();

    return (<nav className="mt-2">
        <Nav as="ul" className="nav-pills nav-sidebar flex-column" role="menu">
            <SidebarNavItem to="/" menuIcon="fas fa-home">Начална страница</SidebarNavItem>
            <SidebarNavItem to="/mtpl/new" menuIcon="fas fa-car-alt">Нова полица ГО</SidebarNavItem>
            <RequireNonProdEnv>
                <SidebarNavItem to="/casco/offer" menuIcon="fas fa-car-crash">Автокаско</SidebarNavItem>
                <SidebarNavItem to="/property/offer" menuIcon="fas fa-laptop-house">Имот и имущество</SidebarNavItem>
                <SidebarNavItem to="/travel/offer" menuIcon="fas fa-plane">Помощ при пътуване</SidebarNavItem>
                <SidebarNavItem to="/cancel-travel/offer" menuIcon="fas fa-plane-slash">Отмяна на пътуване</SidebarNavItem>
            </RequireNonProdEnv>
            <SidebarNavItem to="/installment-payment" menuIcon="fas fa-dollar-sign">Плащане на вноска</SidebarNavItem>
            <SidebarNavItem to="/uab-card/new" menuIcon="fas fa-address-card">Издаване на карта СБА</SidebarNavItem>
            <RequireNonProdEnv>
                <SidebarNavItem to="/valuation-certificate/new" menuIcon="fas fa-file-invoice-dollar">Издаване на УЗС</SidebarNavItem>
                <SidebarNavItem to="/policy/void" menuIcon="fas fa-trash">Анулиране на полица</SidebarNavItem>
                <SidebarNavItem to="/clients" menuIcon="fas fa-address-card">Клиенти</SidebarNavItem>
            </RequireNonProdEnv>
            <SidebarNavItem to="/import" menuIcon="fas fa-file-import">Импортиране от ЗК</SidebarNavItem>
            <RequireRole name="ROLE_STORE_VOUCHER">
                <SidebarNavItem to="/receive" menuIcon="far fa-envelope">Приемане на поща</SidebarNavItem>
            </RequireRole>
            <SidebarNavItem to="/client/new" menuIcon="fas fa-person-circle-plus">Въвеждане на клиент</SidebarNavItem>
            <SidebarNavItem to="/vehicle/new" menuIcon="fas fa-car-side">Въвеждане на МПС</SidebarNavItem>
            <RequireRole name="ROLE_UPDATE_VOUCHER">
                <SidebarNavItem to="/annex/issue" menuIcon="fas fa-sticky-note">Въвеждане на плащане</SidebarNavItem>
            </RequireRole>
            <RequireRole name="ROLE_UPDATE_POLICY">
                <SidebarNavItem to="/policy/new" menuIcon="fas fa-file-medical">Въвеждане на полица</SidebarNavItem>
            </RequireRole>
            <SidebarNavItem to="/reports" menuIcon="fas fa-search-dollar">Справки и отчети</SidebarNavItem>
            <SidebarNavItem to="/bso" menuIcon="fas fa-money-check">БСО</SidebarNavItem>
            <RequireRole name="ROLE_SHOW_BROKER">
                <SidebarNavItem to="/users" menuIcon="fas fa-users">Потребители</SidebarNavItem>
            </RequireRole>
            <RequireRole name="ROLE_UPDATE_BROKER">
                <SidebarNavItem to="/settings" menuIcon="fas fa-cogs">Настройки</SidebarNavItem>
            </RequireRole>
            <RequireRole name="ROLE_BROKER_MANAGER">
                <SidebarNavItem to="/nomenclatures" menuIcon="fas fa-list-check">Номенклатури</SidebarNavItem>
            </RequireRole>
            {userSuccess && user.insuranceBroker?.supportUrl && <SidebarNavItem to="/help"
                menuIcon="far fa-question-circle">Помощ и поддръжка</SidebarNavItem>}
        </Nav>
    </nav>);
};

export default SidebarNavWidget;
