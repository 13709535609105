import React from 'react';
import {useListCurrentUserLevelCommissionSettingsQuery, useListUserLevelsQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import {Button, Table} from "react-bootstrap";
import Card from '../../elements/ReactBootstrap/Card.jsx';
import {Link} from "react-router-dom";
import Percent from "../../elements/Values/Percent.jsx";
import {commissionTypes} from "../../../utils.js";

const UserLevelCommissionSettingsWidget = () => {
    const {
        data: userLevelCommissionSettings,
        error: userLevelCommissionSettingError,
        isSuccess: userLevelCommissionSettingSuccess,
        isLoading: userLevelCommissionSettingLoading,
        isError: userLevelCommissionSettingIsError,
    } = useListCurrentUserLevelCommissionSettingsQuery();

    const {
        data: userLevelsList,
        error: userLevelsLisError,
        isLoading: userLevelsLisLoading,
        isSuccess: userLevelsLisSuccess,
        isError: userLevelsLisIsError,
    } = useListUserLevelsQuery();

    return (<>
        {userLevelCommissionSettingLoading && userLevelsLisLoading &&
            <>Зареждане на комисионите по нива... <SmallSpinner /></>}
        {userLevelCommissionSettingIsError && userLevelsLisIsError &&
            <ErrorWidget error={userLevelCommissionSettingError + ' ' +  userLevelsLisError} />}
        {userLevelCommissionSettingSuccess && userLevelsLisSuccess && <>
            <div>
                Комисионите са в проценти, както следва:
                <ul>
                    <li>наша</li>
                    <li>чужда</li>
                    <li>наша, платена другаде</li>
                </ul>
            </div>
            {userLevelsList.map(userLevel =>
            {const currentUserLevelCommissionSettings = userLevelCommissionSettings.filter(userLevelCommissionSetting =>
                userLevelCommissionSetting.userLevel.id === userLevel.id);
                return(<Card header={userLevel.name} collapse noPadding key={userLevel.id} initialClosed outline baseClassName='card-success'>
                    <div className="table-responsive">
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>Продукт</th>
                                <th>Застрахователна компания</th>
                                <th>Комисион</th>
                                <th>Проценти</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentUserLevelCommissionSettings.map(userLevelCommissionSetting => {
                                return (<tr key={userLevelCommissionSetting.id}>
                                    <td>{userLevelCommissionSetting.product?.shortName || userLevelCommissionSetting.product?.name}</td>
                                    <td>{userLevelCommissionSetting.insuranceCompany?.shortName}</td>
                                    <td>{commissionTypes[userLevelCommissionSetting.type]}</td>
                                    <td><Button as={Link}
                                                key={userLevelCommissionSetting.id} variant="outline-success" className="text-success"
                                                to={'/settings/user-level/' + userLevelCommissionSetting.id}>
                                        <Percent coefficient={
                                            userLevelCommissionSetting.policyCoefficient +
                                            userLevelCommissionSetting.voucherCoefficient
                                        } showPercentSign={false} toFixed={2}/>/
                                        <Percent coefficient={userLevelCommissionSetting.voucherCoefficient}
                                                 showPercentSign={false} toFixed={2}/>/
                                        <Percent coefficient={userLevelCommissionSetting.independentPolicyCoefficient ?
                                            userLevelCommissionSetting.policyCoefficient : 0.0
                                        } showPercentSign={false} toFixed={2}/>
                                    </Button></td>
                                </tr>)})}
                            </tbody>
                        </Table>
                    </div>
                    </Card>)})}
        </>}
    </>);
};

export default UserLevelCommissionSettingsWidget;
