import React from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

const CloseButton = ({
    onClick,
    small,
}) => {
    return (<Button variant="outline-success" className={'text-success ms-1' + (small ? ' p-1' : '')}
        onClick={onClick}>
        <i className="fa-solid fa-rectangle-xmark"/>
    </Button>);
};

CloseButton.defaultProps = {
    small: false,
};

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    small: PropTypes.bool,
};
export default CloseButton;
