import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {useListMunicipalitiesQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const MunicipalitySelector = ({
    country,
    required,
    isClearable,
    defaultValueFilter,
    ...props
}) => {
    const {
        data: municipalityList,
        error: municipalityListError,
        isLoading: municipalityListLoading,
        isSuccess: municipalityListSuccess,
        isError: municipalityListIsError,
    } = useListMunicipalitiesQuery(country);

    const municipalityOptions = useMemo(
        () => municipalityListSuccess ? municipalityList.map(municipality => ({
            value: municipality.stateId,
            label: municipality.stateName,
        })): [],
        [municipalityListSuccess, municipalityList]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {municipalityListLoading && <SmallSpinner />}
        {municipalityListSuccess && <Field component={SelectField} name="municipality" options={municipalityOptions}
            disabled={isSubmitting} isClearable={isClearable} required={required}
            defaultValue={defaultValueFilter ? defaultValueFilter(municipalityOptions) : []}
            placeholder={required ? 'Изберете област' : 'Всички области'}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {municipalityListIsError && <ErrorIcon error={municipalityListError} />}
    </>);
};

MunicipalitySelector.defaultProps = {
    country: 'BG',
    required: false,
    isClearable: false,
};

MunicipalitySelector.propTypes = {
    country: PropTypes.string,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default MunicipalitySelector;
