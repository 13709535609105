import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';

const Content = ({
    children,
    title,
}) => {
    useEffect(() => {
        if (title) {
            document.title = title + ' - IBMS';
        }
    }, [title]);

    return (<>
        {title && <div className="content-header">
            <Container fluid>
                <Row className="mb-2">
                    <Col>
                        <div className="fs-3">{title}</div>
                    </Col>
                </Row>
            </Container>
        </div>}
        <div className="content flex-grow-1 d-flex">
            <Container fluid className="flex-grow-1 d-flex flex-column">
                {children}
            </Container>
        </div>
    </>);
};

Content.propTypes = {
    title: PropTypes.string,
};

export default Content;
