import React, {forwardRef, lazy, Suspense, useCallback, useMemo} from 'react';
import {agMultiFilter, agParseDate, agSort, uabCardTypes} from '../../../utils.js';
import PremiumAmount from '../../elements/Values/PremiumAmount.jsx';
import {format} from 'date-fns';
import PropTypes from 'prop-types';
import DateShort from '../../elements/DateTime/DateShort.jsx';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import localeText from '../../elements/AgGrid/locale.bg';
import {defaultComparator} from '@ag-grid-community/core/dist/esm/es6/utils/generic';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import UabCardInfoButton from "../NavigationButtons/UabCardInfoButton.jsx";

const AgGridEnterprise = lazy(() => import('../../elements/AgGrid/AgGridEnterprise.js'));
const AgGridReact = lazy(() => import('../../elements/AgGrid/AgGridReactWrapper'));

const UabReportGridWidget = forwardRef(({
    rowData,
    actionButton,
    showExtraControls,
}, ref) => {
    const animateRows = true;

    const onGridReady = useCallback(event => agSort(event, [{
        colId: 'issueDate',
        sort: 'asc',
    }]), []);

    const premiumColumnDefs = useMemo(() => ({
        aggFunc: 'sum',
        cellRenderer: params => <PremiumAmount amount={params.value || 0} />,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        width: 118,
    }), []);

    const multiFilter = agMultiFilter;
    const parseDate = useCallback((date, resetTime) => agParseDate(date, resetTime), []);

    const columnDefs = useMemo(() => [...(actionButton ? [{
        cellRenderer: actionButton,
    }] : []), {
        colId: 'issueDate.week',
        filter: 'agNumberColumnFilter',
        headerName: 'Седмица',
        valueGetter: params => params.data ? parseInt(format(new Date(params.data.issueDate), 'I')) : null,
        width: 133,
    }, {
        headerName: 'Издал квитанция',
        field: 'issueUser.shortName',
        ...multiFilter,
    }, {
        headerName: 'Офис',
        field: 'issueOffice.name',
        ...multiFilter,
    }, {
        colId: 'uabCard.type',
        headerName: 'Тип',
        valueGetter: params => params.data ? uabCardTypes[params.data.uabCard.type].name : null,
        ...multiFilter,
    }, {
        colId: 'uabCard.number',
        filter: 'agTextColumnFilter',
        headerName: 'Номер',
        valueGetter: params => params.data ? params.data.uabCard.number : null,
        cellRenderer: params => params.data ?
            <UabCardInfoButton uabCard={params.data.uabCard} small/> : params.value,
    }, {
        headerName: 'Общо',
        field: 'totalAmount',
        ...premiumColumnDefs,
    }, {
        colId: 'issueDate',
        filter: 'agDateColumnFilter',
        headerName: 'Платено',
        valueGetter: params => parseDate(params.data?.issueDate),
        cellRenderer: params => <DateShort date={params.value} html />,
        width: 129,
    }, {
        colId: 'beginDate',
        filter: 'agDateColumnFilter',
        headerName: 'Начало',
        valueGetter: params => parseDate(params.data?.beginDate),
        cellRenderer: params => <DateShort date={params.value} html />,
        width: 129,
    }, {
        colId: 'endDate',
        filter: 'agDateColumnFilter',
        headerName: 'Край',
        valueGetter: params => parseDate(params.data?.endDate),
        cellRenderer: params => <DateShort date={params.value} html />,
        width: 129,
    }], [
        actionButton,
        premiumColumnDefs,
        multiFilter,
        parseDate
    ]);

    const defaultColDef = useMemo(() => ({
        comparator: (valueA, valueB) => defaultComparator(
            valueA?.toLowerCase ? valueA.toLowerCase() : valueA,
            valueB?.toLowerCase ? valueB.toLowerCase() : valueB,
        ),
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
        },
        floatingFilter: showExtraControls,
        resizable: true,
        sortable: true,
    }), [showExtraControls]);

    const rowGroupPanelShow = showExtraControls ? 'always' : 'never';

    const rowClassRules = useMemo(() => ({
        'grid-row-danger': params => params.data && params.data.void,
        'progress-bar-striped': params => params.data && params.data.void,
    }), []);

    return (<Suspense fallback={<Card><SmallSpinner /></Card>}>
        <AgGridEnterprise>
            <AgGridReact ref={ref} className="ag-theme-alpine" {...{
                animateRows, columnDefs, defaultColDef, localeText, onGridReady, rowData, rowGroupPanelShow,
                rowClassRules,
            }} />
        </AgGridEnterprise>
    </Suspense>);
});

UabReportGridWidget.defaultProps = {
    showExtraControls: false,
};

UabReportGridWidget.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
    actionButton: PropTypes.func,
    showExtraControls: PropTypes.bool,
};

export default UabReportGridWidget;
