import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useGetCurrentInsuranceBrokerQuery, useAttachVoucherCommissionUserMutation} from "../../features/apiSlice.js";
import {createFilter} from "react-select";
import {flattenUsers} from "../../utils.js";
import ErrorWidget from "./ErrorWidget.jsx";
import SelectField from "../elements/ReactSelect/SelectField.jsx";

const VoucherCommissionUserWidget = ({
    voucherId,
     setEdit
}) => {
    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useAttachVoucherCommissionUserMutation();

    const {
        data: insuranceBroker,
        error: insuranceBrokerError,
        isSuccess,
        isError: insuranceBrokerIsError,
    } = useGetCurrentInsuranceBrokerQuery();

    const users = useMemo(
        () => isSuccess ? flattenUsers(insuranceBroker) : [],
        [isSuccess, insuranceBroker]
    );

    return (
        <Formik
            initialValues={{
                commissionUser: '',
            }}
            validate={values => {
                const errors = {};
                if (!values.commissionUser) {
                    errors.commissionUser = 'Моля изберете консултант!';
                }
                return errors;
            }}
            onSubmit={(values) => { update([voucherId, values.commissionUser], setEdit(false));
            }}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0 mt-1">
                    <Row className="row-cols-12">
                        <Col>
                            {insuranceBrokerIsError ? <ErrorWidget error={insuranceBrokerError} /> :
                                <Field component={SelectField} name="commissionUser"
                                      options={users} required disabled={isSubmitting} isClearable={true}
                                      placeholder="консултант"
                                      filterOption={createFilter({
                                          stringify: option => option.label,
                                      })}/>}
                        </Col>
                        <Col>
                            {updateIsError && <ErrorWidget error={updateError} />}
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

VoucherCommissionUserWidget.propTypes = {
    voucherId: PropTypes.string.isRequired,
    setEdit: PropTypes.func,
};
export default VoucherCommissionUserWidget;
