import React from 'react';
import {useListFscCategoriesQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import {Table} from "react-bootstrap";
import {addendumTypes} from "../../../utils.js";

const FscCategoriesWidget = () => {
    const {
        data: fscCategories,
        error: fscCategoriesError,
        isSuccess: fscCategoriesSuccess,
        isLoading: fscCategoriesLoading,
        isError: fscCategoriesIsError,
    } = useListFscCategoriesQuery();

    return (<>
        {fscCategoriesLoading && <>Зареждане на информация за КФН категории... <SmallSpinner /></>}
        {fscCategoriesIsError && <ErrorWidget error={fscCategoriesError} />}
        {fscCategoriesSuccess && <div className="table-responsive">
            <Table striped bordered>
                <thead>
                <tr>
                    <th>Категория</th>
                    <th>Приложения</th>
                    <th>Подредба</th>
                </tr>
                </thead>
                <tbody>
            {fscCategories.map(fscCategory => {
                return (<tr key={fscCategory.id}>
                    <td>{fscCategory.name}</td>
                    <td>{fscCategory.addendumTypes.map(addendumType => addendumTypes[addendumType] + '; ')}</td>
                    <td>{fscCategory.sort}</td>
                </tr>);
            })}
                </tbody>
            </Table>
        </div>}
    </>);
};

export default FscCategoriesWidget;
