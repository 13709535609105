import {Col, FormCheck, FormControl, FormLabel} from 'react-bootstrap';
import React from 'react';
import {Field, useFormikContext} from 'formik';
import IconField from "../elements/Formik/IconField.jsx";
import IssueUserSelectionWidget from "./IssueUserSelectionWidget.jsx";
import {format} from "date-fns";

const CreatePolicyWidget = () => {
    const {values} = useFormikContext();
    const installmentsCount = (parseInt(values.installmentsCount, 10) || 0);

    return (<Col sm={12}>
        <FormLabel>Брой вноски:</FormLabel>
        <div className="w-100" />
        {[1, 2, 4, 12, 120].map(installmentsCount => <React.Fragment key={installmentsCount}>
            <Field type="radio" required name="installmentsCount" value={installmentsCount.toString(10)}
                   id={'installmentsCount' + installmentsCount} className="btn-check" />
            <FormLabel htmlFor={'installmentsCount' + installmentsCount} className="btn btn-app">
                <span className="lead fw-bold">{installmentsCount}</span>
            </FormLabel>
        </React.Fragment>)}
        {installmentsCount > 0 && <>
            <div className="w-100" />
            <FormLabel>Платени вноски: {values.paidInstallmentsCount} / {installmentsCount}</FormLabel>
            <Field type="range" name="paidInstallmentsCount" max={installmentsCount} className="form-range" />
            <FormLabel>Дата на издаване на полицата</FormLabel>
            <FormControl as={Field} type="date" name="issueDate" id="issueDate"
                         max={format(new Date(), 'yyyy-MM-dd')} />
            <FormLabel>Обща сума по полица:</FormLabel>
            <IconField type="number" inputMode="decimal" name="totalAmount" min={0.01} step={0.01} />
            <FormLabel>Собствена полица:</FormLabel>
            <FormCheck as={Field} name="ownPolicy"/>
            <FormLabel>Издал полица/квитанция:</FormLabel>
            <IssueUserSelectionWidget/>
        </>}
    </Col>);
};

export default CreatePolicyWidget;
