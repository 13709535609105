import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {useListCountriesQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const CountrySelector = ({
    required,
    isClearable,
    defaultValueFilter,
    ...props
}) => {
    const {
        data: countryList,
        error: countryListError,
        isLoading: countryListLoading,
        isSuccess: countryListSuccess,
        isError: countryListIsError,
    } = useListCountriesQuery();

    const countryOptions = useMemo(
        () => countryListSuccess ? countryList.map(country => ({
            value: country.CountryId,
            label: country.CountryName,
        })): [],
        [countryListSuccess, countryList]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {countryListLoading && <SmallSpinner />}
        {countryListSuccess && <Field component={SelectField} name="country" options={countryOptions}
            disabled={isSubmitting} isClearable={isClearable} required={required}
            defaultValue={defaultValueFilter ? defaultValueFilter(countryOptions) : []}
            placeholder={required ? 'Изберете държава' : 'Всички държави'}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {countryListIsError && <ErrorIcon error={countryListError} />}
    </>);
};

CountrySelector.defaultProps = {
    required: false,
    isClearable: false,
};

CountrySelector.propTypes = {
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default CountrySelector;
