import React, {useCallback, useMemo} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useGetClientQuery} from '../../features/apiSlice';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import ClientInfoWidget from '../widgets/Client/ClientInfoWidget.jsx';
import PoliciesListWidget from '../widgets/Policy/PoliciesListWidget.jsx';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import ClientAddressesWidget from "../widgets/Client/ClientAddressesWidget.jsx";

const Client = () => {
    const {clientId} = useParams();

    const {
        data: client,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetClientQuery(clientId);

    const filterInsuredPolicies = useCallback((client, isPrevious) =>
        client.insuredPolicies.filter(insuredPolicy =>
            insuredPolicy.isPrevious === isPrevious
        ).map(insuredPolicy => insuredPolicy.policy),
        [],
    );

    const currentInsuredPolicies = useMemo(
        () => isSuccess ? filterInsuredPolicies(client, false) : [],
        [isSuccess, client, filterInsuredPolicies],
    );

    const previousInsuredPolicies = useMemo(
        () => isSuccess ? filterInsuredPolicies(client, true) : [],
        [isSuccess, client, filterInsuredPolicies],
    );

    return (<Content title="Информация за клиент">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на клиента... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <ClientInfoWidget client={client} />
                </Card>}
                {isSuccess && <Card noPadding header={'Адреси (' +
                    client.addresses.length + ')'}>
                    <ClientAddressesWidget addresses={client.addresses} client={clientId}/>
                </Card>
                }
            </Col>
            {isSuccess && <>
                <Col>
                    <Card noPadding header={'Полици, сключени от клиента (' +
                        client.heldPolicies.length + ')'}>
                        <PoliciesListWidget policies={client.heldPolicies} showClient={false} />
                    </Card>
                </Col>
                <Col>
                    <Card noPadding header={'Полици, по които клиентът е застраховано лице (' +
                        currentInsuredPolicies.length + ')'}>
                        <PoliciesListWidget policies={currentInsuredPolicies} showClient={false} />
                    </Card>
                </Col>
                <Col>
                    <Card noPadding header={'Полици, по които клиентът е бил застраховано лице (' +
                        previousInsuredPolicies.length + ')'}>
                        <PoliciesListWidget policies={previousInsuredPolicies} showClient={false} />
                    </Card>
                </Col>
                <Col>
                    <Card noPadding header={'Полици, по които клиентът е лице за контакт (' +
                        client.contactPolicies.length + ')'}>
                        <PoliciesListWidget policies={client.contactPolicies} showClient={false} />
                    </Card>
                </Col>
            </>}
        </Row>
    </Content>);
};

export default Client;
