import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';
import StickerNumber from '../../elements/Values/StickerNumber';
import {formatStickerNumber} from '../../../utils';

const StickerInfoButton = ({
    sticker,
    textField,
    small,
}) => {
    return (<NavigationButton link={'/sticker/' + sticker.id} copyText={formatStickerNumber(sticker.number)}
        small={small} isWarning={!sticker.assigned}>
        {textField && !!sticker[textField] ? sticker[textField] : <StickerNumber {...sticker} />}
    </NavigationButton>);
};

StickerInfoButton.defaultProps = {
    small: false,
};

StickerInfoButton.propTypes = {
    sticker: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
    }),
    textField: PropTypes.string,
    small: PropTypes.bool,
};

export default StickerInfoButton;
