import {sum} from '../utils.js';

const useUsersVoucherCommission = () => {
        const getFilteredUsers  = (voucher)=> {
            const details = voucher?.details || [];
            const users = details.map(detail =>
                (detail.userVoucherDetails || []).map(uvd => uvd.user)
            ).flat();
            return users.filter((v, i, a) =>
                a.map(user => user?.id).indexOf(v?.id) === i);
    };
    const getUserVoucherCommissionAmount = (voucher, user) => {
        const details = voucher?.details || [];
        const uvds = details.map(detail =>
            (detail.userVoucherDetails || []).filter(uvd => uvd.user?.id === user?.id)
        ).flat();
        return sum(uvds.map(uvd => uvd.commissionAmount));
    };
    const getUserVoucherCommissionPercent = (voucher, user) => {
        const details = voucher?.details || [];
        const uvds = details.map(detail =>
            (detail.userVoucherDetails || []).filter(uvd => uvd.user?.id === user?.id)
        ).flat();
        return uvds.length === 1 ? sum(uvds.map(uvd => uvd.commissionCoefficient)) : null;
    };

    return {
        getFilteredUsers,
        getUserVoucherCommissionAmount,
        getUserVoucherCommissionPercent,
    };
};

export default useUsersVoucherCommission;
