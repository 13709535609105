import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useGetVehicleQuery} from '../../features/apiSlice';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import PoliciesListWidget from '../widgets/Policy/PoliciesListWidget.jsx';
import VehicleInfoWidget from '../widgets/Vehicle/VehicleInfoWidget.jsx';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const Vehicle = () => {
    const {vehicleId} = useParams();
    const [printing, setPrinting] = useState(false);

    const {
        data: vehicle,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetVehicleQuery(vehicleId);

    return (<Content title="Информация за МПС">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на МПС... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <VehicleInfoWidget vehicle={vehicle} printing={printing} setPrinting={setPrinting}/>
                </Card>}
            </Col>
            <Col>
                {isSuccess && vehicle.policies && <>
                    <Card noPadding header={'Полици за МПС (' + vehicle.policies.length + ')'}>
                        <PoliciesListWidget policies={vehicle.policies} showVehicle={false} />
                    </Card>
                </>}
            </Col>
        </Row>
    </Content>);
};

export default Vehicle;
