import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import CopyButton from './SimpleButtons/CopyButton.jsx';
import UabCardNumber from '../elements/Values/UabCardNumber';
import PremiumAmount from '../elements/Values/PremiumAmount';
import {formatUabCardNumber} from '../../utils.js';
import PhoneButton from './SimpleButtons/PhoneButton.jsx';
import BsoStatusWidget from './BsoStatusWidget.jsx';

const UabCardInfoWidget = ({
    uabCard,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            <Col sm={4} as="dt">Номер</Col>
            <Col sm={8} as="dd">
                <UabCardNumber {...uabCard} />
                <CopyButton text={formatUabCardNumber(uabCard.number)} />
            </Col>
            <Col sm={4} as="dt">Статус</Col>
            <Col sm={8} as="dd"><BsoStatusWidget type="uabCard" updateRole="ROLE_UPDATE_UAB_CARD" {...uabCard} /></Col>
            {uabCard.insuranceBroker && <>
                <Col sm={4} as="dt">Брокер</Col>
                <Col sm={8} as="dd">{uabCard.insuranceBroker.shortName}</Col>
            </>}
            {uabCard.office && <>
                <Col sm={4} as="dt">Офис</Col>
                <Col sm={8} as="dd">{uabCard.office.name}</Col>
            </>}
            {uabCard.user && <>
                <Col sm={4} as="dt">Отговорно лице</Col>
                <Col sm={8} as="dd">{uabCard.user.shortName}</Col>
            </>}
            {uabCard.totalAmount && <>
                <Col sm={4} as="dt">Цена</Col>
                <Col sm={8} as="dd"><PremiumAmount amount={uabCard.totalAmount} /></Col>
            </>}
            {(uabCard.clientFirstName || uabCard.clientMiddleName || uabCard.clientLastName) && <>
                <Col sm={4} as="dt">Клиент</Col>
                <Col sm={8} as="dd">
                    {uabCard.clientFirstName} {uabCard.clientMiddleName} {uabCard.clientLastName}
                </Col>
            </>}
            {uabCard.clientPhone && <>
                <Col sm={4} as="dt">Телефонен номер на клиента</Col>
                <Col sm={8} as="dd"><PhoneButton phone={uabCard.clientPhone} /></Col>
            </>}
        </Row>
    </>);
};

UabCardInfoWidget.propTypes = {
    uabCard: PropTypes.shape({
        number: PropTypes.number.isRequired,
    }).isRequired,
};

export default UabCardInfoWidget;
