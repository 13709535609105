import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DateTimeLong from '../../elements/DateTime/DateTimeLong.jsx';
import {useUpdateUserLimitMutation} from "../../../features/apiSlice.js";
import ErrorWidget from "../ErrorWidget.jsx";
import SwitchButton from "../../elements/Form/SwitchButton.jsx";
import useCurrentUserHasRole from "../../../hooks/useCurrentUserHasRole.js";
import {Button, Collapse} from "react-bootstrap";
import InfoIcon from "../../elements/Icons/InfoIcon.jsx";
import {getUserLimitAuditReportUrl} from "../../../url.js";
import RequireRole from "../../elements/AccessControl/RequireRole.jsx";

const UserLimitInfoRowWidget = ({
    limit,
}) => {
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loan, setLoan] = useState(false);
    const [newLoadDate, setNewLoadDate] = useState('');
    const hasAddLimitRole = useCurrentUserHasRole('ROLE_ADD_LIMIT');

    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useUpdateUserLimitMutation();

    const handleChange = event => {
        setNewLoadDate(event.target.value);
    };

    function toggleLoan() {
        // alert(JSON.stringify(newLoadDate,null,1));
        update([limit.id, {loan: loan, loadDate: newLoadDate}]);
        setOpenModal(!openModal);
    }

    return (
        <React.Fragment key={limit.id}><tr>
            <td><DateTimeLong date={newLoadDate === '' ? limit.loadDate : newLoadDate} /></td>
            <td>{limit.loadFromBroker}</td>
            <td>{limit.loadFromCommission}</td>
            <td>{limit.loadFromUser}</td>
            <td>{limit.remain}</td>
            <td>{limit.loan && hasAddLimitRole ?
                <SwitchButton checked={limit.loan}
                              onChange={checked => {
                                  setLoan(checked);
                                  setOpenModal(!openModal);
                              }}/> : ''}
                {updateIsError &&
                    <ErrorWidget error={updateError} />}
                {limit.loan && !hasAddLimitRole ? 'да' : '' }</td>
                {openModal && hasAddLimitRole ?
                    <>
                    <td width='243px'><input value={newLoadDate} type="datetime-local" onChange={handleChange} className="form-control"/></td>
                    <td width='100px'><Button variant="success" onClick={toggleLoan}>Save</Button></td>
                    </> : <><td width='243px'></td><td width='100px'></td></>
                }
        <td>{limit.limitAudits?.length > 0 &&
            <InfoIcon onClick={() => setOpen(!open)} aria-controls="example-collapse-text"
                      aria-expanded={open} />}
        </td>
        </tr>
        {limit.limitAudits?.length > 0 && <Collapse in={open}>
            <tr>
                <td colSpan={8}>
                    {limit.limitAudits.map(limitAudit =>
                    <div key={limitAudit.id}>
                        <DateTimeLong date={limitAudit.createdAt}/>
                        - {limitAudit.description} - {limitAudit.amount} лв.
                    </div>
                    )}
                </td>
                <RequireRole name="ROLE_ADD_LIMIT">
                    <td>
                        <Button variant="outline-success" className={'text-success ms-1  p-1'}
                                onClick={() => window.open(getUserLimitAuditReportUrl(limit.id, true))}>
                            <i className="fa-solid fa-download" />
                        </Button>
                    </td>
                </RequireRole>
            </tr>
            </Collapse>}
        </React.Fragment>
    );
};

UserLimitInfoRowWidget.propTypes = {
    limit: PropTypes.shape({
        loadDate: PropTypes.string.isRequired,
        loadFromBroker: PropTypes.number.isRequired,
        loadFromCommission: PropTypes.number.isRequired,
        loadFromUser: PropTypes.number.isRequired,
        remain: PropTypes.number.isRequired,
        loan: PropTypes.bool.isRequired,
        limitAudits: PropTypes.arrayOf(PropTypes.shape({
            createdAt: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
        }))
    }).isRequired
};

export default UserLimitInfoRowWidget;
