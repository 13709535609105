import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Accordion, Button, Col, Row} from 'react-bootstrap';
import DateTimeLong from '../../../elements/DateTime/DateTimeLong.jsx';
import {
    useCompleteBsoProtocolMutation,
    useGetCurrentUserQuery,
    useReceiveBsoProtocolMutation,
    useSendBsoProtocolMutation
} from '../../../../features/apiSlice.js';
import RequireRole from '../../../elements/AccessControl/RequireRole.jsx';
import {getBsoProtocolStatus} from '../../../../utils.js';
import BackButton from '../../../elements/BackButton.jsx';
import TextWithCopyButton from '../../TextWithCopyButton.jsx';

const BsoProtocolInfoWidget = ({
    bsoProtocol,
    disabled,
    setDisabled,
}) => {
    const {
        data: user,
    } = useGetCurrentUserQuery();

    const [complete] = useCompleteBsoProtocolMutation();
    const [send] = useSendBsoProtocolMutation();
    const [receive] = useReceiveBsoProtocolMutation();

    const status = useMemo(() => getBsoProtocolStatus(bsoProtocol), [bsoProtocol]);

    const doComplete = useCallback(async () => {
        setDisabled && setDisabled(true);

        // eslint-disable-next-line no-restricted-globals
        if (confirm('Потвърдете финализирането. Няма да можете да променяте протокола след това.')) {
            await complete(bsoProtocol.id);
        }

        setDisabled && setDisabled(false);
    }, [bsoProtocol.id, setDisabled]);

    const doSend = useCallback(async () => {
        setDisabled && setDisabled(true);

        // eslint-disable-next-line no-restricted-globals
        if (confirm('Потвърдете че сте изпратили всички бланки от протокола')) {
            await send(bsoProtocol.id);
        }

        setDisabled && setDisabled(false);
    }, [bsoProtocol.id, setDisabled]);

    const doReceive = useCallback(async () => {
        setDisabled && setDisabled(true);

        // eslint-disable-next-line no-restricted-globals
        if (confirm('Приемам всички бланки от протокола и нося отговорност за съхранението им')) {
            await receive(bsoProtocol.id);
        }

        setDisabled && setDisabled(false);
    }, [bsoProtocol.id, setDisabled]);

    return (<Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="0">
            <Accordion.Header>Информация за протокола</Accordion.Header>
            <Accordion.Body>
                <Row className="mb-3">
                    <Col sm={6} lg={4}>
                        <BackButton to="/bso/protocols" />
                    </Col>
                </Row>
                <Row as="dl">
                    <Col sm={4} as="dt">Номер</Col>
                    <Col sm={8} as="dd">
                        <TextWithCopyButton text={bsoProtocol.number.toString(10)} />
                    </Col>
                    <Col sm={4} as="dt">Дата на създаване</Col>
                    <Col sm={8} as="dd">
                        <DateTimeLong date={bsoProtocol.createdAt} />
                    </Col>
                    <Col sm={4} as="dt">Брокер</Col>
                    <Col sm={8} as="dd">{bsoProtocol.insuranceBroker?.shortName}</Col>
                    <Col sm={4} as="dt">Създал</Col>
                    <Col sm={8} as="dd">{bsoProtocol.createUser?.shortName} ({bsoProtocol.createOffice?.name})</Col>
                    <Col sm={4} as="dt">Офис</Col>
                    <Col sm={8} as="dd">
                        {bsoProtocol.sendOffice?.name} <i
                            className="fa-regular fa-circle-right" /> {bsoProtocol.receiveOffice?.name}
                    </Col>
                    {bsoProtocol.sentAt && <>
                        <Col sm={4} as="dt">Дата на предаване</Col>
                        <Col sm={8} as="dd">
                            <DateTimeLong date={bsoProtocol.sentAt} />
                        </Col>
                    </>}
                    {bsoProtocol.sendUser?.shortName && <>
                        <Col sm={4} as="dt">Предал</Col>
                        <Col sm={8} as="dd">{bsoProtocol.sendUser?.shortName}</Col>
                    </>}
                    {bsoProtocol.receivedAt && <>
                        <Col sm={4} as="dt">Дата на приемане</Col>
                        <Col sm={8} as="dd">
                            <DateTimeLong date={bsoProtocol.receivedAt} />
                        </Col>
                    </>}
                    {bsoProtocol.receiveUser?.shortName && <>
                        <Col sm={4} as="dt">Приел</Col>
                        <Col sm={8} as="dd">{bsoProtocol.receiveUser?.shortName}</Col>
                    </>}
                    <Col sm={4} as="dt">Статус</Col>
                    <Col sm={8} as="dd">{status}</Col>
                </Row>
                <Row className="g-3">
                    <RequireRole name="ROLE_BSO_PROTOCOL_CREATE">
                        {!bsoProtocol.completed && ((
                                bsoProtocol.stickers.length > 0 ||
                                bsoProtocol.greenCards.length > 0 ||
                                bsoProtocol.uabCards.length > 0 ||
                                bsoProtocol.assistanceCards.length > 0
                            ) ? <Col sm={6} xl={3}>
                            <Button variant="success" className="w-100" onClick={doComplete} disabled={disabled}>
                                <i className="fa-solid fa-flag-checkered me-2" />
                                Финализирай
                            </Button>
                        </Col> : <Col>Моля, изберете бланки за прехвърляне</Col>)}
                    </RequireRole>
                    {bsoProtocol.completed && <>
                        {!bsoProtocol.sentAt && user.office.id === bsoProtocol.sendOffice.id &&
                            <Col sm={6} xl={3}>
                                <Button variant="success" className="w-100" onClick={doSend} disabled={disabled}>
                                    Предай
                                </Button>
                            </Col>
                        }
                        {bsoProtocol.sentAt && !bsoProtocol.receivedAt && user.office.id === bsoProtocol.receiveOffice.id &&
                            <Col sm={6} xl={3}>
                                <Button variant="success" className="w-100" onClick={doReceive} disabled={disabled}>
                                    Приеми
                                </Button>
                            </Col>
                        }
                    </>}
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>);
};

BsoProtocolInfoWidget.defaultProps = {
    disabled: false,
};

BsoProtocolInfoWidget.propTypes = {
    bsoProtocol: PropTypes.shape({
        number: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        insuranceBroker: PropTypes.shape({
            shortName: PropTypes.string.isRequired,
        }).isRequired,
        createOffice: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        createUser: PropTypes.shape({
            shortName: PropTypes.string.isRequired,
        }),
        sentAt: PropTypes.string,
        sendOffice: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        sendUser: PropTypes.shape({
            shortName: PropTypes.string.isRequired,
        }),
        receivedAt: PropTypes.string,
        receiveOffice: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        receiveUser: PropTypes.shape({
            shortName: PropTypes.string.isRequired,
        }),
        completed: PropTypes.bool.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    setDisabled: PropTypes.func,
};

export default BsoProtocolInfoWidget;
