import React from 'react';
import { saveAs } from 'file-saver';
import {Button} from "react-bootstrap";
import * as XLSX from "xlsx";

const ExcelExport = ({ data, fileName }) => {
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `${fileName}.xlsx`);
    };

    return (
        <Button variant="success"  onClick={exportToExcel}><i className="fa-solid fa-download" /></Button>
    );
}

export default ExcelExport;
