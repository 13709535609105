import React from 'react';
import {Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import InsuranceCompanySelector from '../../elements/Form/InsuranceCompanySelector';
import BsoSaveWidget from './BsoSaveWidget';
import {useCreateAssistanceCardsMutation} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import Step from "../../elements/BsStepper/Step.jsx";
import AssistanceCardSeriesSelector from "../../elements/Form/AssistanceCardSeriesSelector.jsx";

const AssistanceCardCreateWidget = () => {
    const [create, {
        data: assistanceCards,
        error,
        isError,
    }] = useCreateAssistanceCardsMutation();

    const formName = 'assistanceCardCreateForm';
    const formInitialValues = useFormPersistedValues(formName);

    return (<Formik
        initialValues={formInitialValues || {
            insuranceCompany: '',
            series: '',
            startNumber: '',
            endNumber: '',
        }}
        onSubmit={values => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Потвърдете регистрацията на асистънс карти')) {
                return Promise.reject(new Error('Регистрацията беше отказана'));
            }

            return create({...values});
        }}
    >
        {({isSubmitting}) => (<Container fluid as={Form} className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Step number="2" label="Въведете информация"/>
                </Col>
                <Col lg={6}>
                    <InsuranceCompanySelector required={true} bulstradOnly={true} />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">Серия</FormLabel>
                    <AssistanceCardSeriesSelector required={true} disabled={isSubmitting} name="series" />
                </Col>
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">От номер</FormLabel>
                    <FormControl as={Field} type="text" name="startNumber" autoComplete="off"
                                 id="startNumber" required pattern="\d{7}"
                                 disabled={isSubmitting} maxLength={7} inputMode="text"/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="endNumber">До номер</FormLabel>
                    <FormControl as={Field} type="text" name="endNumber" autoComplete="off"
                                 id="endNumber" required pattern="\d{7}"
                                 disabled={isSubmitting} maxLength={7} inputMode="text"/>
                </Col>
                <BsoSaveWidget type="асистънс карти" />
                <div className="w-100 gy-0" />
                {assistanceCards && <Col lg={6}>
                    <Alert variant="success" heading={<>Успешно записахте {assistanceCards.length} асистънс карти!</>} />
                </Col>}
                {isError && <Col lg={6}>
                    <ErrorWidget error={error} />
                </Col>}
            </Row>
            <AutoPersistToken formName={formName}/>
        </Container>)}
    </Formik>);
};

export default AssistanceCardCreateWidget;
