import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import logo_125x103 from '../images/logo-125x103.png';
import {Button, Card, Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import {useLoginMutation} from '../features/apiSlice';
import {setTokens} from '../authStorage.js';
import PropTypes from 'prop-types';

const LoginView = ({
    onSuccess,
}) => {
    const [login] = useLoginMutation();

    return (
        <div id="view-login" className="login-page">
            <div className="login-box">
                <Card className="card-primary card-outline">
                    <Card.Header className="text-center">
                        <img src={logo_125x103} alt="IBMS" id="login-logo" />
                    </Card.Header>
                    <Card.Body>
                        <p className="login-box-msg">Вход за консултанти</p>
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validate={values => {
                                const errors = {};

                                if (!values.username) {
                                    errors.username = 'Моля попълнете Вашия e-mail';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
                                ) {
                                    errors.username = 'E-mail адресът е невалиден';
                                }

                                if (!values.password) {
                                    errors.password = 'Моля попълнете Вашата парола';
                                }

                                return errors;
                            }}
                            onSubmit={async (values, {setFieldError}) => {
                                try {
                                    setTokens(await login(values).unwrap());
                                    onSuccess && onSuccess();
                                }
                                catch (e) {
                                    const message = e.status === 500 ?
                                        'Грешка в системата. Моля свържете се с отдел Поддръжка' : (
                                            e.data?.message === 'Invalid credentials.' ?
                                                'Грешно потребителско име или парола' :
                                                (e.data?.message || 'Грешка при опит за влизане в системата')
                                        );
                                    setFieldError('password', message);
                                    throw e;
                                }
                            }}
                        >
                            {({isSubmitting}) => (
                                <Form>
                                    <div className="mb-3">
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <i className="fas fa-envelope"/>
                                            </InputGroup.Text>
                                            <FormControl type="email" name="username" placeholder="E-mail" as={Field}
                                                         autoComplete="email" disabled={isSubmitting} />
                                        </InputGroup>
                                        <ErrorMessage name="username" component="div"/>
                                    </div>
                                    <div className="mb-3">
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <i className="fas fa-lock"/>
                                            </InputGroup.Text>
                                            <FormControl type="password" name="password" placeholder="Парола" as={Field}
                                                         autoComplete="current-password" disabled={isSubmitting}/>
                                        </InputGroup>
                                        <ErrorMessage name="password" component="div"/>
                                    </div>
                                    <Row>
                                        <Col sm={{ span: 4, offset: 8 }} className="d-grid gap-2">
                                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                                Влез
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

LoginView.propTypes = {
    onSuccess: PropTypes.func,
};

export default LoginView;
