import React from 'react';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken.jsx';
import PropTypes from 'prop-types';
import useFormPersistedValues from "../../../hooks/useFormPersistedValues.js";
import {Button, Col, Container, FormControl, FormLabel, InputGroup, Row} from "react-bootstrap";
import AutoSubmitToken from "../../elements/Formik/AutoSubmitToken.jsx";

// TODO: react-bootstrap

const ClientSearchWidget = ({
    onSubmit,
    autoFocus,
    autoSubmit,
    formName,
    findButtonIcon,
    findButtonLabel,
    defaultFormInitialValues,
    validationFunction,
    children,
}) => {
    const formInitialValues = useFormPersistedValues(formName);

    return (
        <Formik
            initialValues={formInitialValues || {
                ...({
                    pin: '',
                }),
                ...(defaultFormInitialValues ? defaultFormInitialValues : {})
            }}
            validate={values => {
                const errors = {};

                if (values.pin.length === 0) {
                    errors.pin = 'Полето е задължително';
                }

                return {
                    ...errors,
                    ...(validationFunction ? validationFunction(values) : {}),
                };
            }}
            onSubmit={onSubmit}
        >
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-sm-2 g-3 align-items-end">
                        <Col>
                            <FormLabel htmlFor="pin">ЕГН, ЛНЧ или ЕИК</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="fas fa-person-circle-plus"/>
                                </InputGroup.Text>
                                <FormControl as={Field} name="pin" id="pin" required
                                             disabled={isSubmitting} autoFocus={autoFocus}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Button variant="success" type="submit" disabled={isSubmitting} className="w-100">
                                <i className={findButtonIcon} /> {findButtonLabel}
                            </Button>
                        </Col>
                        {children}
                    </Row>
                    {autoSubmit && <AutoSubmitToken />}
                    <AutoPersistToken formName={formName} />
                </Container>
            )}
        </Formik>
    );
};

ClientSearchWidget.propTypes = {
    formName: PropTypes.string.isRequired,
    findButtonLabel: PropTypes.node,
    findButtonIcon: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    autoSubmit: PropTypes.bool,
    defaultFormInitialValues: PropTypes.object,
    validationFunction: PropTypes.func,
};

export default ClientSearchWidget;
