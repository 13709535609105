import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetUserQuery} from '../../features/apiSlice';
import UserInfoWidget from '../widgets/User/UserInfoWidget.jsx';
import UserSessionsInfoWidget from '../widgets/User/UserSessionsInfoWidget.jsx';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import UserLimitsInfoWidget from "../widgets/User/UserLimitsInfoWidget.jsx";

const User = () => {
    const {userId} = useParams();

    const {
        data: user,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetUserQuery(userId);

    return (<Content title="Информация за служител">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за служителя... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <>
                    <UserInfoWidget user={user} />
                    {user.hasLimit && <UserLimitsInfoWidget initialLimits={user.limits}/>}
                    {user.sessions.length > 0 && <UserSessionsInfoWidget sessions={user.sessions} />}
                </>}
            </Col>
        </Row>
    </Content>);
};

export default User;
