import PropTypes from 'prop-types';
import React from "react";

const AssistanceCardNumber = ({
      series,
      number,
}) => <>
    {series}
    <span className="me-1"/>
    {number}
</>;

AssistanceCardNumber.propTypes = {
    series: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
};

export default AssistanceCardNumber;
