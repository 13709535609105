import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import BsoStatusWidget from "./BsoStatusWidget.jsx";
import AssistanceCardInfoButton from "./NavigationButtons/AssistanceCardInfoButton.jsx";

const AssistanceCardsListWidget = ({
    assistanceCards,
    actionButton,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    {actionButton && <th width={72} />}
                    <th>Номер</th>
                    <th>Статус</th>
                    <th>Консултант</th>
                </tr>
            </thead>
            <tbody>
                {assistanceCards.map(assistanceCard => <tr key={assistanceCard.id}>
                    {actionButton && <td>{actionButton(assistanceCard)}</td>}
                    <td><AssistanceCardInfoButton assistanceCard={assistanceCard} /></td>
                    <td><BsoStatusWidget {...assistanceCard} /></td>
                    <td>{assistanceCard.user?.shortName}</td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

AssistanceCardsListWidget.propTypes = {
    assistanceCards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceBroker: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
    }).isRequired),
    actionButton: PropTypes.func,
};

export default AssistanceCardsListWidget;
