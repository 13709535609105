import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import GreenCardInfoButton from './NavigationButtons/GreenCardInfoButton.jsx';
import BsoStatusWidget from "./BsoStatusWidget.jsx";
import {useListInsuranceCompaniesQuery} from "../../features/apiSlice.js";
import Card from '../elements/ReactBootstrap/Card.jsx';
import SmallSpinner from "../elements/Spinner/SmallSpinner.jsx";
import React from "react";

const GreenCardsListWidget = ({
    greenCards,
    actionButton,
}) => {
    const {
        data: insuranceCompanies,
        isSuccess,
        isLoading,
    } = useListInsuranceCompaniesQuery();

    return (<>
        {isLoading && <SmallSpinner />}
        {isSuccess && <>
            {/* eslint-disable-next-line array-callback-return */}
            {insuranceCompanies.map(insuranceCompany =>
            {const currentInsuranceCompanyGreenCards = greenCards.filter(greenCard =>
                greenCard.insuranceCompany.id === insuranceCompany.id);
                if(currentInsuranceCompanyGreenCards.length > 0) {
                return( <Card header={insuranceCompany?.shortName + ' (' + currentInsuranceCompanyGreenCards.length + ')'}
                              initialClosed collapse noPadding
                              outline baseClassName='card-primary'>
                            <div className="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    {actionButton && <th width={72} />}
                                    <th>Номер</th>
                                    <th>ЗК</th>
                                    <th>Статус</th>
                                    <th>Консултант</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentInsuranceCompanyGreenCards.map(greenCard => <tr key={greenCard.id}>
                                    {actionButton && <td>{actionButton(greenCard)}</td>}
                                    <td><GreenCardInfoButton greenCard={greenCard} /></td>
                                    <td>{greenCard.insuranceCompany?.shortName}</td>
                                    <td><BsoStatusWidget {...greenCard} /></td>
                                    <td>{greenCard.user?.shortName}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                        </Card>
                )}})}
        </>}
    </>);
};

GreenCardsListWidget.propTypes = {
    greenCards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        series: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceBroker: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceCompany: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
    }).isRequired),
    actionButton: PropTypes.func,
};

export default GreenCardsListWidget;
