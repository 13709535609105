import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Button, Col, FormCheck, FormControl, FormLabel, Row, Table} from 'react-bootstrap';
import {useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation} from '../../features/apiSlice';
import RequireNonProdEnv from '../elements/AccessControl/RequireNonProdEnv';
import {Field} from 'formik';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import PoliciesSearchWidget from '../widgets/Policy/PoliciesSearchWidget.jsx';
import {getErrorMessage, matchPolicyNumbers} from '../../utils.js';
import PolicyInfoButton from '../widgets/NavigationButtons/PolicyInfoButton.jsx';
import CopyButton from '../widgets/SimpleButtons/CopyButton.jsx';
import TextWithCopyButton from '../widgets/TextWithCopyButton.jsx';

const InsuranceCompanyImport = () => {
    const [create] = useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation();
    const [policies, setPolicies] = useState({});

    return (<Content title="Импортиране на полици от застрахователните компании">
        <Row>
            <Col lg={6}>
                <Card outline>
                    <PoliciesSearchWidget formName="policiesSearchImport" findButtonLabel="Търсене в ЗК"
                        searchWithClientPin={true}
                        onSubmit={async values => {
                            const policyNumbers = matchPolicyNumbers(values.policyNumbers);

                            setPolicies(Object.fromEntries(policyNumbers.map(policyNumber => [policyNumber, {
                                status: 'pending',
                            }])));

                            for (const policyNumber of policyNumbers) {
                                setPolicies(policies => ({
                                    ...policies,
                                    [policyNumber]: {
                                        status: 'loading',
                                    }
                                }));

                                try {
                                    const policy = await create({
                                        policyNumber,
                                        clientPin: values.clientPin,
                                        importDocuments: values.importDocuments,
                                    }).unwrap();

                                    setPolicies(policies => ({
                                        ...policies,
                                        [policyNumber]: {
                                            status: 'success',
                                            policy,
                                        }
                                    }));
                                }
                                catch (error) {
                                    setPolicies(policies => ({
                                        ...policies,
                                        [policyNumber]: {
                                            status: 'error',
                                            error,
                                        }
                                    }));
                                }
                            }
                        }} defaultFormInitialValues={{
                            importDocuments: false,
                        }}>
                        <Col sm={12}>
                            <FormLabel>Допълнителни настройки</FormLabel>
                            <FormCheck as={Field} label="Импортиране на файловете за печат" name="importDocuments"
                                id="importDocuments" />
                        </Col>
                    </PoliciesSearchWidget>
                </Card>
                {Object.keys(policies).length > 0 && <Card noPadding header={'Резултат от импортирането (' +
                    Object.entries(policies).filter(([_, { status }]) =>
                        ['success', 'error'].indexOf(status) > -1
                    ).length + ' от ' + Object.keys(policies).length + ' завършени)'}>
                    <Table striped className="mb-0">
                        <thead>
                            <tr>
                                <th width="1">Номер на полица</th>
                                <th>Резултат</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(policies).map(([policyNumber, {
                                status,
                                ...state
                            }]) => {
                                const errorMessage = getErrorMessage(state?.error);

                                return (<tr key={policyNumber}>
                                    <td valign="middle">
                                        <TextWithCopyButton text={policyNumber} />
                                    </td>
                                    <td valign="middle">
                                        {status === 'pending' && <i className="fa-solid fa-hourglass-half" />}
                                        {status === 'loading' && <SmallSpinner />}
                                        {status === 'success' && <>
                                            Успешно импортиране на
                                            <span className="ms-2">
                                                <PolicyInfoButton policy={state.policy} />
                                            </span>
                                        </>}
                                        {errorMessage}
                                    </td>
                                    <td>
                                        <CopyButton text={errorMessage} />
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </Table>
                </Card>}
            </Col>
            <RequireNonProdEnv>
                <Col lg={6}>
                    <Card outline header="Файл за импорт">
                        <Row>
                            <Col>
                                <FormControl type="file" />
                            </Col>
                            <Col>
                                <Button variant="success" type="submit" className="w-100">Импортиране от файл</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </RequireNonProdEnv>
        </Row>
    </Content>);
};

export default InsuranceCompanyImport;
