import React from 'react';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken.jsx';
import PropTypes from 'prop-types';
import {Button, Col, Container, FormControl, FormLabel, InputGroup, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues.js';
import AutoSubmitToken from '../../elements/Formik/AutoSubmitToken.jsx';

const PolicySearchWidget = ({
    children,

    autoFocus,
    autoSubmit,
    findButtonLabel,
    findButtonIcon,
    formName,
    onSubmit,
    defaultFormInitialValues,
    validationFunction,
}) => {
    const formInitialValues = useFormPersistedValues(formName);

    return (
        <Formik
            initialValues={formInitialValues || {
                ...({
                    policyNumber: '',
                }),
                ...(defaultFormInitialValues ? defaultFormInitialValues : {})
            }}
            validate={values => {
                const errors = {};

                if (values.policyNumber.length === 0) {
                    errors.policyNumber = 'Полето е задължително';
                }

                return {
                    ...errors,
                    ...(validationFunction ? validationFunction(values) : {}),
                };
            }}
            onSubmit={onSubmit}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-sm-2 g-3 align-items-end">
                        <Col>
                            <FormLabel htmlFor="policyNumber">Номер на полица</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="fas fa-file-alt"/>
                                </InputGroup.Text>
                                <FormControl as={Field} name="policyNumber" id="policyNumber" required
                                             disabled={isSubmitting} autoFocus={autoFocus}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Button variant="success" type="submit" disabled={isSubmitting} className="w-100">
                                <i className={findButtonIcon} /> {findButtonLabel}
                            </Button>
                        </Col>
                        {children}
                    </Row>
                    {autoSubmit && <AutoSubmitToken />}
                    <AutoPersistToken formName={formName} />
                </Container>
            )}
        </Formik>
    );
};

PolicySearchWidget.propTypes = {
    formName: PropTypes.string.isRequired,
    findButtonLabel: PropTypes.node,
    findButtonIcon: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    autoSubmit: PropTypes.bool,
    defaultFormInitialValues: PropTypes.object,
    validationFunction: PropTypes.func,
};

PolicySearchWidget.defaultProps = {
    autoFocus: false,
    autoSubmit: false,
    findButtonLabel: 'Търсене',
    findButtonIcon: 'fas fa-search',
};

export default PolicySearchWidget;
