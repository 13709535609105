import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import PolicySearchWidget from '../widgets/Policy/PolicySearchWidget.jsx';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';
import SmallSpinner from "../elements/Spinner/SmallSpinner.jsx";
import ErrorWidget from "../widgets/ErrorWidget.jsx";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useIssueAnnexMutation, useGetPolicyQuery} from "../../features/apiSlice.js";
import InstallmentsListWidget from "../widgets/InstallmentsListWidget.jsx";
import PolicyInfoWidget from "../widgets/Policy/PolicyInfoWidget.jsx";
import IssueAnnexWidget from "../widgets/IssueAnnexWidget.jsx";
import SlowSpinner from "../elements/Spinner/SlowSpinner.jsx";

const IssueAnnex = () => {
    const {annexType} = useParams();
    const {policyId} = useParams();
    const navigate = useNavigate();

    const {
        data: policy,
        error: policyError,
        isLoading: policyLoading,
        isSuccess: policySuccess,
        isError: policyIsError,
    } = useGetPolicyQuery(policyId);

    const [selectedInstallments, setSelectedInstallments] = useState([]);
    const hasSelectedInstallments = selectedInstallments.length > 0;

    const dbalAnnexType = annexType === 'plate-number' ? 'ANNEX_PLATE_NUMBER' :
                                 annexType === 'insured' ? 'ANNEX_INSURED' :
                                 annexType === 'sticker' ? 'ANNEX_STICKER' :
                                 annexType === 'green-card' ? 'ANNEX_GRC' :
                                 annexType === 'other' ? 'ANNEX_OTHER' :
                                 annexType === 'cancellation' ? 'ANNEX_CANCELLATION' :
                                 annexType === 'voucher' ? 'STANDARD' : '';

    const [issueAnnex, {
        error: issueAnnexError,
        isLoading: issueAnnexLoading,
        isError: issueAnnexIsError,
    }] = useIssueAnnexMutation();

    return (<Content title="Въвеждане на плащане към полица">
        <Row>
            <Col lg={12}>
                <Card outline>
                    <NavLink to="/annex/issue/plate-number">
                        <Button variant="app" active={annexType === 'plate-number'}>
                            <i className="fas  fa-file text-teal" />{' '}Анекс смяна на СРМПС
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/insured">
                        <Button variant="app" active={annexType === 'insured'}>
                            <i className="fas  fa-id-card text-warning" />{' '}Анекс смяна на собственост
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/sticker">
                        <Button variant="app" active={annexType === 'sticker'}>
                            <i className="fas fa-shield" />{' '}Анекс за стикер
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/green-card">
                        <Button variant="app" active={annexType === 'green-card'}>
                            <i className="fas fa-money-check text-success" />{' '}Анекс за зелена карта
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/other">
                        <Button variant="app" active={annexType === 'other'}>
                            <i className="fas fa-file-circle-plus text-blue" />{' '}Добавък
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/cancellation">
                        <Button variant="app" active={annexType === 'cancellation'}>
                            <i className="fas fa-file-circle-xmark text-dark" />{' '}Анулиране
                        </Button>
                    </NavLink>
                    <NavLink to="/annex/issue/voucher">
                        <Button variant="app" active={annexType === 'voucher'}>
                            <i className="fas fa-ticket text-red" />{' '}Квитанция
                        </Button>
                    </NavLink>
                </Card>
                {annexType !== undefined && <Card>
                    <PolicySearchWidget formName="policySearchInstallment"
                                        onSubmit={() => {
                                            navigate('/annex/issue/' + annexType);
                                        }} />

                </Card>}
                {policyLoading && <Card>Зареждане на полица... <SmallSpinner /></Card>}
                {policyIsError && <ErrorWidget error={policyError} />}
                {policySuccess && <PolicyInfoWidget policy={policy} header="Информация за полицата" />}
                {policySuccess && <>
                <Card noPadding header={<>Вноски по полицата</>}>
                    <InstallmentsListWidget product={policy.product} installments={policy.installments}
                                            onSelectedInstallmentsChange={setSelectedInstallments}
                                            detailed select onlyUnpaid={annexType === 'voucher'}/>
                </Card>
                </>}
            </Col>
        </Row>
        {hasSelectedInstallments && <IssueAnnexWidget
            annexType={dbalAnnexType}
            selectedInstallments={selectedInstallments}
            onSubmit={async (values) => {
                // alert(JSON.stringify(values));
                const result = await issueAnnex({
                    installments: selectedInstallments.map(installment => installment.id),
                    type: dbalAnnexType,
                    ...values,
                }).unwrap();

                if (result.id) {
                    navigate('/policy/' + policy.id);
                }
            }}
        />}
        {issueAnnexLoading && <Alert variant="secondary" icon={null} heading={<>
                    <span className="me-2">
                        <SlowSpinner />
                    </span>
            Моля, изчакайте...
        </>}>
            </Alert>}
        {issueAnnexIsError && <ErrorWidget error={issueAnnexError} />}
    </Content>);
};

export default IssueAnnex;
