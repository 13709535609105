import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';
import {Col, Container, FormCheck, Row} from 'react-bootstrap';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues';
import PropTypes from 'prop-types';
import {formatWeekCode} from '../../../../utils';
import WeekSelector from '../../../elements/Form/WeekSelector';
import SubmitButton from '../../../elements/Form/SubmitButton';
import InsuranceCompanySelector from '../../../elements/Form/InsuranceCompanySelector';
import {subWeeks} from 'date-fns';

const WeekAndInsuranceCompanySelectionWidget = ({
    onSubmit,
    addFilterPaidPremiumFromClient,
    addFilterUnpaidPremiumToInsuranceCompany,
}) => {
    const formName = 'insuranceCompanyReport';
    const initialFormValues = useFormPersistedValues(formName);
    const currentWeekFormatted = useMemo(() => formatWeekCode(subWeeks(new Date(), 1)), []);

    return (
        <Formik initialValues={initialFormValues || {
            weekCode: currentWeekFormatted,
            insuranceCompany: '',
            filterPaidPremiumFromClient: false,
            filterUnpaidPremiumToIC: false
        }} onSubmit={onSubmit}>
            <Container as={Form} className="gx-0">
                <Row className="row-cols-1 row-cols-xl-4 g-4 align-items-end">
                    <Col>
                        <WeekSelector required={true} />
                    </Col>
                    <Col>
                        <InsuranceCompanySelector required={true} />
                    </Col>
                    {(addFilterPaidPremiumFromClient || addFilterUnpaidPremiumToInsuranceCompany) && <Col>
                        {addFilterPaidPremiumFromClient &&
                            <FormCheck as={Field} name="filterPaidPremiumFromClient" id="filterPaidPremiumFromClient"
                                       label="Отчетени към БР">
                            </FormCheck>
                        }
                        {addFilterUnpaidPremiumToInsuranceCompany &&
                            <FormCheck as={Field} name="filterUnpaidPremiumToIC" id="filterUnpaidPremiumToIC"
                                       label="Неотчетени към ЗК">
                            </FormCheck>
                        }
                    </Col>}
                    <Col>
                        <SubmitButton>Изготвяне</SubmitButton>
                    </Col>
                </Row>
                <AutoPersistToken formName={formName}/>
            </Container>
        </Formik>
    );
};
WeekAndInsuranceCompanySelectionWidget.defaultProps = {
    addFilterPaidPremiumFromClient: false,
}

WeekAndInsuranceCompanySelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    addFilterPaidPremiumFromClient: PropTypes.bool
};

export default WeekAndInsuranceCompanySelectionWidget;
