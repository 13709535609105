import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {
    useAttachVoucherBsoMutation,
    useGetStickerInventoryQuery
} from "../../features/apiSlice.js";
import {createFilter} from "react-select";
import {formatStickerNumber} from "../../utils.js";
import StickerNumber from "../elements/Values/StickerNumber.jsx";
import ErrorWidget from "./ErrorWidget.jsx";
import SelectField from "../elements/ReactSelect/SelectField.jsx";

const VoucherStickerWidget = ({
    insuranceCompanyId,
    office,
    voucherId,
    stickerRef,
}) => {
    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useAttachVoucherBsoMutation();

    const {
        data: stickers,
        isSuccess: stickersIsSuccess,
    } = useGetStickerInventoryQuery([insuranceCompanyId, office]);

    const stickersList = useMemo(() => stickersIsSuccess ? stickers.map(sticker => ({
        value: sticker.id,
        label: <StickerNumber {...sticker} />,
    })) : [], [stickersIsSuccess, stickers]);

    return (
        <Formik
            initialValues={{
                sticker: '',
            }}
            validate={values => {
                const errors = {};
                if (!values.sticker) {
                    errors.sticker = 'Моля изберете стикер!';
                }
                return errors;
            }}
            onSubmit={(values) => { update([voucherId, {
                    sticker: values.sticker,
                }]);
            }}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <Col>
                            <Field name="sticker" component={SelectField} options={stickersList}
                                   disabled={isSubmitting} isClearable={true}
                                   noOptionsMessage={() => 'Нямате налични стикери!'} filterOption={createFilter({
                                stringify: option => {
                                    const number = option.label.props.number;
                                    return number + '\0' + formatStickerNumber(number);
                                },
                            })} innerRef={stickerRef} />
                        </Col>
                        <Col>
                            {updateIsError &&
                                <ErrorWidget error={updateError} />}
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

VoucherStickerWidget.defaultProps = {
    stickerRef: null,
};

VoucherStickerWidget.propTypes = {
    insuranceCompanyId: PropTypes.string,
    issueOfficeId: PropTypes.string,
    voucherId: PropTypes.string,
};
export default VoucherStickerWidget;
