import React, {useMemo} from 'react';
import {Button, Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import {getJournalUrl} from '../../../url';
import Content from '../../partials/Content.jsx';
import {Field, Form, Formik} from "formik";
import AutoPersistToken from "../../elements/Formik/AutoPersistToken.jsx";
import useFormPersistedValues from "../../../hooks/useFormPersistedValues.js";
import {format} from "date-fns";

const Journal = () => {

    const formName = 'journalReport';
    const initialFormValues = useFormPersistedValues(formName);
    const todayFormatted = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

    return (<Content title="Дневник на Застрахователния Брокер">
        <Row>
            <Col>
                <Card outline>
                    <Formik initialValues={initialFormValues || {issueDate: todayFormatted}}
                            onSubmit={values => window.open(getJournalUrl(
                        values.issueDate + ' 00:00:00',
                        true,
                        ))}>
                        {({isSubmitting}) => (
                            <Container as={Form} className="gx-0">
                                <Row className="row-cols-1 row-cols-xl-4 g-4 align-items-end">
                                    <Col>
                                        <FormLabel htmlFor="startDateTime">Дата на издаване</FormLabel>
                                        <FormControl as={Field} type="date" name="issueDate" id="issueDate"
                                                     max={format(new Date(), 'yyyy-MM-dd')} disabled={isSubmitting} />
                                    </Col>
                                    <Col>
                                        <Button variant="success" type="submit" className="w-100" disabled={isSubmitting}>
                                            Изготвяне
                                        </Button>
                                    </Col>
                                </Row>
                                <AutoPersistToken formName={formName}/>
                            </Container>
                        )}
                    </Formik>
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default Journal;
