import PropTypes from 'prop-types';
import {isNumeric} from "../../../utils.js";
import {useGetCurrentUserQuery} from "../../../features/apiSlice.js";

const Percent = ({
    coefficient,
    toFixed,
    showPercentSign,
}) =>
{
    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const isDemo = currentUser.insuranceBroker.demo;

    return (<><span className={isDemo ? 'demo' : null}>
        {isNumeric(coefficient) ? (coefficient * 100).toFixed(toFixed) : null}
    </span>{(showPercentSign ? '%' : '')}</>)
};

Percent.defaultProps = {
    showPercentSign: true,
    toFixed: 0,
};

Percent.propTypes = {
    coefficient: PropTypes.number,
    toFixed: PropTypes.number,
    showPercentSign: PropTypes.bool,
};

export default Percent;
