import React from 'react';
import {FormControl, InputGroup} from 'react-bootstrap';
import SaveButton from "../../widgets/SimpleButtons/SaveButton.jsx";
import PropTypes from "prop-types";
import CloseButton from "../../widgets/SimpleButtons/CloseButton.jsx";

const EditInputGroup = ({
    fieldType,
    fieldValue,
    formControlOnChange,
    saveOnClick,
    closeOnClick,

    ...props
}) => {
    return (<InputGroup>
        <FormControl type={fieldType} value={fieldValue} {...props}
                     onChange={formControlOnChange}/>
        <div className="input-group-append">
            <SaveButton onClick={saveOnClick} />
            <CloseButton onClick={closeOnClick} />
        </div>
    </InputGroup>);
};

EditInputGroup.defaultProps = {
    fieldType: 'text',
    fieldValue: '',
}

EditInputGroup.propTypes = {
    fieldValue: PropTypes.string,
    formControlOnChange: PropTypes.func.isRequired,
    saveOnClick: PropTypes.func.isRequired,
    closeOnClick: PropTypes.func.isRequired,
};
export default EditInputGroup;
