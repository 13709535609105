import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';
import GreenCardNumber from '../../elements/Values/GreenCardNumber';
import {formatGreenCardNumber} from '../../../utils';

const GreenCardInfoButton = ({
    greenCard,
    textField,
    small,
}) => {
    const value = textField && !!greenCard[textField] && greenCard[textField];

    return (<NavigationButton link={'/green-card/' + greenCard.id} isWarning={!greenCard.assigned}
              copyText={value || (greenCard.series + formatGreenCardNumber(greenCard.number))} small={small}>
        {value || <GreenCardNumber {...greenCard} />}
    </NavigationButton>);
};

GreenCardInfoButton.defaultProps = {
    small: false,
};

GreenCardInfoButton.propTypes = {
    greenCard: PropTypes.shape({
        id: PropTypes.string.isRequired,
        series: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
    }),
    textField: PropTypes.string,
    small: PropTypes.bool,
};

export default GreenCardInfoButton;
