import React, {useState} from 'react';
import {Alert, Button, Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import FillClientAddressWidget from "./FillClientAddressWigget.jsx";
import {useFillClientAddressMutation} from "../../../features/apiSlice.js";
import SlowSpinner from "../../elements/Spinner/SlowSpinner.jsx";
import ErrorWidget from "../ErrorWidget.jsx";
import InfoIcon from "../../elements/Icons/InfoIcon.jsx";

const ClientAddressesWidget = ({
    client,
    addresses
}) => {
    const [fillAddress, setFillAddress] = useState(false);
    const [editAddress, setEditAddress] = useState(null);

    const [fillClientAddress, {
        error: fillClientAddressError,
        isLoading: fillClientAddressLoading,
        isError: fillClientAddressIsError,
    }] = useFillClientAddressMutation();

    return (<><div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
            <tr>
                <th>I</th>
                <th>Държава</th>
                <th>Област</th>
                <th>Община</th>
                <th>Град</th>
                <th>Пощенски код</th>
                <th>Адрес</th>
                <th className="text-end"><Button variant="success" size="sm" onClick={() =>
                    setFillAddress(oldState => !oldState)}>
                    Добави адрес
                </Button></th>
            </tr>
            </thead>
            <tbody>
            {addresses.map(address => <tr key={address.id}
                     className={editAddress?.id === address.id ? 'progress-bar-striped grid-row-danger' : null}>
                <td>{(address.updatedAt || address.updatedBy) &&
                    <InfoIcon onClick={() =>
                        alert('Последна редакция:' + [address.updatedAt, address.updatedBy?.fullName].join(' - '))
                    } />}</td>
                    <td>{address.country}</td>
                    <td>{address.municipality}</td>
                    <td>{address.region}</td>
                    <td>{address.city}</td>
                    <td>{address.postCode}</td>
                    <td>{address.address}</td>
                    <td className="text-end"><Button variant="success" size="sm" onClick={() => {
                        setFillAddress(oldState => !oldState);
                        setEditAddress(editAddress === null ? address : null);
                    }}
                        >
                        <i className="fa-solid fa-pen"/>
                    </Button></td>
                </tr>
            )}
            </tbody>
        </Table>
        </div>
            {fillAddress && <FillClientAddressWidget onSubmit={
                async (values) => {
                await fillClientAddress({
                    client: client,
                    clientAddress: editAddress?.id,
                    ...values,
                }).unwrap();
                    setFillAddress(false);
                    setEditAddress(null);
            }} clientAddress={editAddress}/>}

            {fillClientAddressLoading && <Alert variant="secondary" icon={null} heading={<>
                    <span className="me-2">
                        <SlowSpinner />
                    </span>
                Моля, изчакайте...
            </>}>
            </Alert>}
            {fillClientAddressIsError && <ErrorWidget error={fillClientAddressError} />}
        </>
    );
};
ClientAddressesWidget.propTypes = {
    addresses: PropTypes.arrayOf(PropTypes.shape({
        country: PropTypes.string,
        municipality: PropTypes.string,
        region: PropTypes.string,
        city: PropTypes.string,
        postCode: PropTypes.string,
        address: PropTypes.string,
        updatedAt: PropTypes.string,
        updatedBy: PropTypes.shape({
            fullName: PropTypes.string,
        }),
    })),
};
export default ClientAddressesWidget;
