import PropTypes from 'prop-types';

const BasicPremiumAmount = ({
                           amount,
                           withCurrency,
                       }) => (new Intl.NumberFormat('bg-BG', withCurrency ? {
    currency: 'BGN',
    style: 'currency',
} : {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})).format(amount);

BasicPremiumAmount.defaultProps = {
    withCurrency: true,
};

BasicPremiumAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    withCurrency: PropTypes.bool,
};

export default BasicPremiumAmount;
