import React from 'react';
import {Button, Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DateTimeLong from '../../elements/DateTime/DateTimeLong.jsx';
import CopyButton from '../SimpleButtons/CopyButton.jsx';
import Card from '../../elements/ReactBootstrap/Card.jsx';

const UserSessionsInfoWidget = ({
    sessions,
}) => {
    return (
        <Card header={'Вписвания (' + sessions.length + ')'} collapse headerClassName='bg-success' initialClosed>
            <div className="table-responsive">
            <Table striped className="mb-0">
                <thead>
                    <tr>
                        <th>Дата/час</th>
                        <th>IP адрес</th>
                        <th>Браузър</th>
                    </tr>
                </thead>
                <tbody>
                {sessions.map(session => <tr key={session.id}>
                    <td>
                        <DateTimeLong date={session.createdAt} />
                    </td>
                    <td>
                        <Button variant="outline-secondary" target="_blank"
                            href={'https://iplocation.io/ip-whois-lookup/' + session.ipAddress}>
                            {session.ipAddress}
                        </Button>
                        <CopyButton text={session.ipAddress} />
                    </td>
                    <td>
                        <form method="post" target="_blank" action="https://www.whatsmyua.info/">
                            <input type="hidden" name="custom-ua-string" value={session.userAgent} />
                            <Button variant="outline-secondary" type="submit">
                                {session.userAgent}
                            </Button>
                            <CopyButton text={session.userAgent} />
                        </form>
                    </td>
                </tr>)}
                </tbody>
            </Table>
        </div>
    </Card>);
};

UserSessionsInfoWidget.propTypes = {
    sessions: PropTypes.arrayOf(PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        ipAddress: PropTypes.string.isRequired,
        userAgent: PropTypes.string.isRequired,
    }).isRequired)
};

export default UserSessionsInfoWidget;
