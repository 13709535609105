import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetAssistanceCardQuery} from '../../features/apiSlice';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import AssistanceCardInfoWidget from "../widgets/AssistanceCardInfoWidget.jsx";

const AssistanceCard = () => {
    const {assistanceCardId} = useParams();

    const {
        data: assistanceCard,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetAssistanceCardQuery(assistanceCardId);

    return (<Content title="Информация за асистънс карта">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за асистънс карта... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <AssistanceCardInfoWidget assistanceCard={assistanceCard} />
                </Card>}
            </Col>
        </Row>
    </Content>);
};

export default AssistanceCard;
