import React from 'react';
import {Button, Col, Collapse, Container, FormControl, FormLabel, InputGroup, ProgressBar, Row} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import {offersProgressSelector, useGetApplicationQuery, useGetCalculatorConfigQuery} from '../../../features/apiSlice';
import ErrorWidget from '../../widgets/ErrorWidget';
import {installmentCounts} from '../../../config';
import MtplBestOfferWidget from '../../widgets/Mtpl/MtplBestOfferWidget';
import Card from '../../elements/ReactBootstrap/Card';
import MtplOffersTableWidget from '../../widgets/Mtpl/MtplOffersTableWidget';
import {useSelector} from 'react-redux';
import {copyText} from '../../../utils';
import Content from '../../partials/Content.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const MtplOffer = () => {
    const {
        data: calculatorConfig,
        isSuccess: calculatorConfigIsSuccess,
    } = useGetCalculatorConfigQuery();

    const {applicationId} = useParams();

    const {
        data,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetApplicationQuery(applicationId, {
        skip: applicationId === undefined,
    });

    const progress = useSelector(offersProgressSelector(applicationId));

    return (<Content title="Оферта за нова полица">
        <Row>
            <Col>
                {(isLoading || isSuccess) && <Card outline>
                    {isLoading && <>Търсене на предложение... <SmallSpinner /></>}
                    {isSuccess && <Container className="gx-0">
                        <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4 g-3">
                            <Col>
                                <FormLabel htmlFor="plateNumber">Номер на автомобил (ДКН)</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <i className="fas fa-car-alt"/>
                                    </InputGroup.Text>
                                    <FormControl type="text" id="plateNumber" disabled={true}
                                        value={data.plateNumber} />
                                    <div className="input-group-append">
                                        <Button variant="outline-success" className="rounded-start-0"
                                                onClick={() => copyText(data.plateNumber)}>
                                            <i className="far fa-copy" />
                                        </Button>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col>
                                <FormLabel htmlFor="registrationCertificateNumber">Номер на талон (СРМПС)</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <i className="fas fa-file"/>
                                    </InputGroup.Text>
                                    <FormControl type="text" id="registrationCertificateNumber" disabled={true}
                                                 value={data.registrationCertificateNumber} />
                                    <div className="input-group-append">
                                        <Button variant="outline-success" className="rounded-start-0"
                                                onClick={() => copyText(data.registrationCertificateNumber)}>
                                            <i className="far fa-copy" />
                                        </Button>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col>
                                <FormLabel htmlFor="personalNumber">ЕГН/ЕИК на собственик</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <i className="fas fa-user"/>
                                    </InputGroup.Text>
                                    <FormControl type="text" id="personalNumber" disabled={true}
                                                 value={data.personalNumber} />
                                    <div className="input-group-append">
                                        <Button variant="outline-success" className="rounded-start-0"
                                                onClick={() => copyText(data.personalNumber)}>
                                            <i className="far fa-copy" />
                                        </Button>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col className="align-self-end">
                                <Button as={Link} variant="outline-success" to="/mtpl/new" className="w-100">
                                    <i className="fas fa-search" /> Нова оферта
                                </Button>
                            </Col>
                            <Col>
                                <FormLabel htmlFor="policyHolderPersonalNumber">ЕГН/ЕИК на застраховащ</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <i className="fas fa-user"/>
                                    </InputGroup.Text>
                                    <FormControl type="text" id="policyHolderPersonalNumber" disabled={true}
                                                 value={data.policyHolderPersonalNumber} />
                                    <div className="input-group-append">
                                        <Button variant="outline-success" className="rounded-start-0"
                                                onClick={() => copyText(data.policyHolderPersonalNumber)}>
                                            <i className="far fa-copy" />
                                        </Button>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col>

                                <FormLabel className="text-secondary">
                                    МПС е с десен волан: <strong>{data.rightSteering ? 'Да' : 'Не'}</strong>
                                </FormLabel>
                                <FormLabel className="text-secondary">
                                    Вина при ПТП в последните 3 години: <strong>{data.faultForAccident ?
                                    'Да' : 'Не'
                                }</strong>
                                </FormLabel>
                            </Col>
                            <Col>
                                <FormLabel className="text-secondary">Предназначение на МПС:{' '}
                                    <strong>{calculatorConfigIsSuccess && (
                                        calculatorConfig.vehiclePurposes[data.vehiclePurpose] ||
                                        'Автоматично (според собственика)'
                                    )}</strong>
                                </FormLabel>
                                <FormLabel className="text-secondary">
                                    Покритие: <strong>{data.product.name}</strong>
                                </FormLabel>
                            </Col>
                        </Row>
                    </Container>}
                </Card>}
                {isError && <ErrorWidget error={error} />}
            </Col>
        </Row>
        {isSuccess && <>
            <Collapse in={progress < 1}>
                <Row>
                    <Col>
                        <Card>
                            <ProgressBar variant="success" striped animated now={progress} max={1}
                                         label="Търсене на най-добрите оферти..." />
                        </Card>
                    </Col>
                </Row>
            </Collapse>
            <Row className="row-cols-1 row-cols-md-2 row-cols-xl-3">
                {installmentCounts.map(installmentsCount => <Col key={installmentsCount}
                        md={installmentsCount === 1 ? 12 : null} className="d-flex">
                    <MtplBestOfferWidget installmentsCount={installmentsCount} />
                </Col>)}
            </Row>
            <Row>
                <Col>
                    <Card outline collapse header="Всички оферти" className="p-0">
                        <MtplOffersTableWidget product={data.product}/>
                    </Card>
                </Col>
            </Row>
        </>}
    </Content>);
};

export default MtplOffer;
