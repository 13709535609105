import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import SelectField from "../elements/ReactSelect/SelectField.jsx";
import usePaymentMethods from "../../hooks/usePaymentMethods.js";

const VoucherPaymentMethod = ({
    onSubmit,
}) => {
    const {paymentMethodOptions} = usePaymentMethods();

    return (
        <Formik
            initialValues={{
                paymentMethod: 'CASH',
            }}
            validate={values => {
                const errors = {};
                if (!values.paymentMethod) {
                    errors.paymentMethod = 'Моля изберете начин на плащане!';
                }
                return errors;
            }}
            onSubmit={values => onSubmit({...values})}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <Col>
                            <Field name="paymentMethod" component={SelectField} options={paymentMethodOptions}
                                   label="Начин на плащане" />
                        </Col>
                        <Col>
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

VoucherPaymentMethod.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
export default VoucherPaymentMethod;
