import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import PolicySearchWidget from '../widgets/Policy/PolicySearchWidget.jsx';
import {Button, Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';

const PolicyCancellation = () => {
    const [policyNumber, setPolicyNumber] = useState('');

    return (<Content title="Анулиране на полица">
        <Row>
            <Col lg={6}>
                <Card outline>
                    <PolicySearchWidget formName="policySearchVoid" findButtonLabel="Провери възможност за анулиране"
                        onSubmit={({policyNumber}, {setSubmitting}) => {
                            setPolicyNumber(policyNumber);
                            setSubmitting(false);
                        }} />
                </Card>
                {policyNumber.length > 0 &&
                <Card>
                    <Button variant="danger">Анулиране на полицата</Button>
                </Card>
                }
            </Col>
            {/*policyNumber.length > 0 &&
            <div className="col-lg-6">
                <PolicyInfoWidget/>
            </div>*/}
        </Row>
    </Content>);
};

export default PolicyCancellation;
