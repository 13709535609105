import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {useListInsuranceCompaniesQuery} from '../../../features/apiSlice';
import {formatInsuranceCompanyNumber} from '../../../utils';
import PropTypes from 'prop-types';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const InsuranceCompanySelector = ({
    required,
    mtplOnly,
    bulstradOnly,

    ...props
}) => {
    const {
        data: insuranceCompanies,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useListInsuranceCompaniesQuery();

    const insuranceCompaniesOptions = useMemo(
        () => isSuccess ? insuranceCompanies.filter(ic => mtplOnly ? ic.number : bulstradOnly ? ic.number === 3 : true).map(insuranceCompany => ({
            value: insuranceCompany.id,
            label: (insuranceCompany.number ? ('[' + formatInsuranceCompanyNumber(insuranceCompany.number) + '] ') : '')
                + insuranceCompany.shortName,
        })) : [],
        [isSuccess, insuranceCompanies, mtplOnly, bulstradOnly]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {isLoading && <SmallSpinner />}
        {isSuccess && <Field component={SelectField} name="insuranceCompany" options={insuranceCompaniesOptions}
            disabled={isSubmitting} isClearable={true} required={required}
            placeholder={required ? 'Изберете ЗК' : 'Всички ЗК'}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {isError && <ErrorIcon error={error} />}
    </>);
};

InsuranceCompanySelector.defaultProps = {
    required: false,
    mtplOnly: false,
    bulstradOnly: false,
};

InsuranceCompanySelector.propTypes = {
    required: PropTypes.bool,
    mtplOnly: PropTypes.bool,
    bulstradOnly: PropTypes.bool,
};

export default InsuranceCompanySelector;
