import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import PolicyInfoWidget from '../widgets/Policy/PolicyInfoWidget.jsx';
import {useGetPolicyQuery} from '../../features/apiSlice';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import InstallmentsListWidget from '../widgets/InstallmentsListWidget';
import {clone} from '../../utils';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import VouchersCardWidget from "../widgets/VouchersCardWidget.jsx";

const Policy = () => {
    const {policyId} = useParams();
    const [printing, setPrinting] = useState(false);

    const {
        data: policy,
        error,
        isLoading,
        isFetching,
        isSuccess,
        isError,
    } = useGetPolicyQuery(policyId);

    let vouchers = [];

    if (isSuccess) {
        for (const originalInstallment of policy.installments) {
            let installment = clone(originalInstallment);

            for (const originalVoucher of (installment.vouchers || [])) {
                let voucher = clone(originalVoucher);
                let existingVoucher = vouchers.filter(existingVoucher => voucher.id === existingVoucher.id).shift();
                let currentVoucher;

                if (existingVoucher) {
                    currentVoucher = existingVoucher;
                }
                else {
                    vouchers.push(voucher);
                    currentVoucher = voucher;
                }

                if (!currentVoucher.hasOwnProperty('installments')) {
                    currentVoucher.installments = [];
                }

                const existingInstallment = currentVoucher.installments.filter(existingInstallment =>
                    installment.id === existingInstallment.id).shift();

                let currentInstallment;

                if (existingInstallment) {
                    currentInstallment = existingInstallment;
                }
                else {
                    currentVoucher.installments.push(installment);
                    currentInstallment = installment;
                }

                currentInstallment.policy = policy;
            }
        }
    }

    return (<Content title="Информация за полица">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на полицата... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <PolicyInfoWidget policy={policy} noInfoButton detailed printing={printing}
                    setPrinting={setPrinting} />}
            </Col>
            <Col>
                {isSuccess && <>
                    <Card noPadding header={<>Вноски по полицата {isFetching && <SmallSpinner />}</>}>
                        <InstallmentsListWidget product={policy.product} installments={policy.installments} readOnly
                            detailed />
                    </Card>
                    <Card header={<>Издадени документи {isFetching && <SmallSpinner />}</>}>
                        <VouchersCardWidget policy={policy} vouchers={vouchers} printing={printing}
                            setPrinting={setPrinting} />
                    </Card>
                </>}
            </Col>
        </Row>
    </Content>);
};

export default Policy;
