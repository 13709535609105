import React from 'react';
import {Form, Formik} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import SubmitButton from '../../elements/Form/SubmitButton';
import UserLevelSelector from "../../elements/Form/UserLevelSelector.jsx";

const UserLevelSelectionWidget = ({
    userLevelId,
    onSubmit,
}) => {
    const formName = 'UserLevelSelector';

    return (
        <Formik initialValues={{
            userLevel: userLevelId,
        }} onSubmit={onSubmit}>
            <Container as={Form} className="gx-0">
                <Row>
                    <Col>
                        <UserLevelSelector required defaultValueFilter={values => values.filter(userLevel =>
                            userLevel.value === userLevelId
                        )} />
                    </Col>
                    <Col>
                        <SubmitButton>Запиши</SubmitButton>
                    </Col>
                </Row>
                <AutoPersistToken formName={formName}/>
            </Container>
        </Formik>
    );
};

UserLevelSelectionWidget.propTypes = {
    userLevelId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default UserLevelSelectionWidget;
