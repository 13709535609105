import {Col, Row} from "react-bootstrap";
import React from "react";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import PoliciesListWidget from "../Policy/PoliciesListWidget.jsx";
import PropTypes from "prop-types";

const PolicySearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'Полици (' + length + ')'}>
                <PoliciesListWidget policies={results} />
            </Card>
        </Col>
    </Row>);
};

PolicySearchResult.propTypes = {
    results: PropTypes.array,
};

export default PolicySearchResult;
