import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import {Col, Row} from 'react-bootstrap';
import TextWithCopyButton from '../TextWithCopyButton.jsx';
import {useUpdateUserMutation, useUpdateUserRelationsMutation} from "../../../features/apiSlice.js";
import SwitchButton from "../../elements/Form/SwitchButton.jsx";
import ErrorWidget from "../ErrorWidget.jsx";
import UserLevelSelectionWidget from "./UserLevelSelectionWidget.jsx";
import useCurrentUserHasRole from "../../../hooks/useCurrentUserHasRole.js";
import ReferrerSelectionWidget from "./ReferrerSelectionWidget.jsx";

const UserInfoWidget = ({
    user,
}) => {

    const hasUpdateUser = useCurrentUserHasRole('ROLE_UPDATE_USER');

    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useUpdateUserMutation();

    const [updateRelations, {
        isError: updateRelationIsError,
        error: updateRelationError,
    }] = useUpdateUserRelationsMutation();

    return (<Card header="Лични данни" collapse headerClassName='bg-success'>
            <Row>
                <Col sm={2} as="dt">Име:</Col>
                {user.fullName &&  <Col sm={3} as="dd"><TextWithCopyButton text={user.fullName} /></Col>}
                    <Col sm={1} as="dt">Активен:</Col>
                    <Col sm={6} as="dd">{hasUpdateUser ? <SwitchButton
                        checked={user.active}
                        onChange={checked => update([user.id, {
                            active: checked,
                        }])}/> : user.active ? 'да' : 'не'}
                    </Col>
            </Row>
            <Row>
                <Col sm={2} as="dt">ЕГН/ЕИК:</Col>
                {user.pin && <Col sm={3} as="dd"><TextWithCopyButton text={user.pin} /></Col>}
                    <Col sm={1} as="dt">Лимит:</Col>
                    <Col sm={6} as="dd">{hasUpdateUser ?
                        <SwitchButton
                            checked={user.hasLimit}
                            onChange={checked => update([user.id, {
                                hasLimit: checked,
                            }])}
                        />: user.hasLimit ? 'да' : 'не'}</Col>
            </Row>
            <Row>
                <Col sm={2} as="dt">Собствено име:</Col>
                {user.firstName && <Col sm={3} as="dd"><TextWithCopyButton text={user.firstName} /></Col>}
                    <Col sm={1} as="dt">Ниво:</Col>
                    <Col sm={6} as="dd">{hasUpdateUser ? <UserLevelSelectionWidget onSubmit={values =>
                        updateRelations([user.id, {
                            level: values.userLevel,
                        }])
                        } userLevelId={user.level.id}/> : user.level.name}
                    </Col>
            </Row>
            <Row>
                <Col sm={2} as="dt">Презиме:</Col>
                {user.middleName && <Col sm={3} as="dd"><TextWithCopyButton text={user.middleName} /></Col>}
                <Col sm={1} as="dt">Референт:</Col>
                <Col sm={6} as="dd">{hasUpdateUser ? <ReferrerSelectionWidget onSubmit={values =>
                    updateRelations([user.id, {
                        referrer: values.referrer,
                    }])
                } referrerId={user.referrer?.id}/> :
                    (user.referrer === undefined || user.referrer === null) ? 'не' : 'да'}
                </Col>
            </Row>
            <Row>
                <Col sm={2} as="dt">Фамилно име:</Col>
                {user.lastName && <Col sm={10} as="dd"><TextWithCopyButton text={user.lastName} /></Col>}
            </Row>
            <Row>
                <Col sm={2} as="dt">Офис/Брокер:</Col>
                {user.office && <Col sm={10} as="dd">{user.office.name + '/' + user.insuranceBroker.shortName}</Col>}
            </Row>
            {updateIsError && <div className="mt-3">
                <ErrorWidget error={updateError} />
            </div>}
            {updateRelationIsError && <div className="mt-3">
                <ErrorWidget error={updateRelationError} />
        </div>}
    </Card>);
};

UserInfoWidget.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        pin: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
    }).isRequired,
};

export default UserInfoWidget;
