import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import WeekAndInsuranceCompanySelectionWidget
    from '../../widgets/Report/Selection/WeekAndInsuranceCompanySelectionWidget';
import {getInsuranceCompanyReportByPaymentDateToBrokerUrl, getInsuranceCompanyReportUrl} from '../../../url';
import Content from '../../partials/Content.jsx';
import DateRangeAndInsuranceCompanySelectionWidget
    from "../../widgets/Report/Selection/DateRangeAndInsuranceCompanySelectionWidget.jsx";

const InsuranceCompany = () => {
    return (<Content title="Отчет застрахователни компании">
        <Row>
            <Col>
                <Card outline header="Отчет по дата на издаване на вноска">
                    <WeekAndInsuranceCompanySelectionWidget addFilterPaidPremiumFromClient={true}
                        addFilterUnpaidPremiumToInsuranceCompany={true}
                        onSubmit={values => window.open(getInsuranceCompanyReportUrl(
                            values.insuranceCompany,
                            values.weekCode,
                            null,
                            null,
                            values.filterPaidPremiumFromClient,
                            values.filterUnpaidPremiumToIC,
                            true,
                        ))} />
                    <DateRangeAndInsuranceCompanySelectionWidget addFilterPaidPremiumFromClient={true}
                        addFilterUnpaidPremiumToInsuranceCompany={true}
                        onSubmit={values => window.open(getInsuranceCompanyReportUrl(
                        values.insuranceCompany,
                        null,
                        values.startDateTime + ' 00:00:00',
                        values.endDateTime+ ' 23:59:59',
                        values.filterPaidPremiumFromClient,
                        values.filterUnpaidPremiumToIC,
                        true,
                    ))} />
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card outline header="Отчет по дата на приемане на отчет към Брокер">
                    <WeekAndInsuranceCompanySelectionWidget addFilterUnpaidPremiumToInsuranceCompany={true}
                        onSubmit={values => window.open(getInsuranceCompanyReportByPaymentDateToBrokerUrl(
                            values.insuranceCompany,
                            values.weekCode,
                            null,
                            null,
                            values.filterUnpaidPremiumToIC,
                            true,
                        ))} />
                    <DateRangeAndInsuranceCompanySelectionWidget addFilterUnpaidPremiumToInsuranceCompany={true}
                         onSubmit={values => window.open(getInsuranceCompanyReportByPaymentDateToBrokerUrl(
                             values.insuranceCompany,
                             null,
                             values.startDateTime + ' 00:00:00',
                             values.endDateTime+ ' 23:59:59',
                             values.filterUnpaidPremiumToIC,
                             true,
                         ))} />
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default InsuranceCompany;
