import PropTypes from "prop-types";
import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import {Form, Formik} from "formik";
import AutoPersistToken from "../../elements/Formik/AutoPersistToken.jsx";
import CountrySelector from "../../elements/Form/CountrySelector.jsx";
import MunicipalitySelector from "../../elements/Form/MunicipalitySelector.jsx";
import RegionSelector from "../../elements/Form/RegionSelector.jsx";
import CitySelector from "../../elements/Form/CitySelector.jsx";
import IconField from "../../elements/Formik/IconField.jsx";

const FillClientAddressWidget = ({
      onSubmit,
      clientAddress,
  }) => {
    const formName = 'fillClientAddress';

    return (<Formik
                initialValues={{
                    country: 'BG',
                    municipality: '',
                    region: '',
                    city: '',
                    address: clientAddress?.address,
                }}
                validate={values => {
                    const errors = {};
                    if (!values.country) {
                        errors.country = 'Моля, изберете държава!';
                    }
                    if (!values.municipality) {
                        errors.municipality = 'Моля, изберете област!';
                    }
                    if (!values.region) {
                        errors.region = 'Моля, изберете община!';
                    }
                    if (!values.city) {
                        errors.city = 'Моля, изберете град!';
                    }
                    if (!values.address) {
                        errors.city = 'Моля, въведете адрес!';
                    }
                    else if (!/^[А-я\d\s.,]+$/i.test(values.address)) {
                        errors.city = 'Моля, въведете коректен адрес!';
                    }

                    return errors;
                }}
                onSubmit={values => onSubmit({
                    ...values})}>
                {({isSubmitting, values}) => (
                    <Container as={Form} fluid className="gx-0">
                        <Row className="m-2">
                            <Col sm={2}><CountrySelector required isClearable
                                     defaultValueFilter={values => values.filter(country =>country.value === 'BG')}/>
                            </Col>
                            <Col sm={2}>
                                <MunicipalitySelector required isClearable country={values.country} defaultValueFilter={
                                    values => values.filter(
                                        municipality => municipality.label === clientAddress?.municipality
                                    )}/>
                            </Col>
                            <Col sm={2}>{values.municipality &&<RegionSelector required isClearable municipality={values.municipality}/>}</Col>
                            <Col sm={3}>{values.region &&<CitySelector required isClearable region={values.region}/>}</Col>
                            <Col sm={2}><IconField type="text" name="address" inputMode="text" disabled={isSubmitting}
                                                   placeholder={'Въведете адрес'}
                                                   pattern="[А-я\d\s\.,]+"
                                                   maxLength={255} autoComplete="off" />
                            </Col>
                            <Col sm={1} className="text-end"><Button variant="success" type="submit"
                                         className="fa-solid fa-floppy-disk" disabled={isSubmitting}/></Col>
                        </Row>
                        <AutoPersistToken formName={formName}/>
                    </Container>
                )}
            </Formik>
    );
}
FillClientAddressWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    clientAddress: PropTypes.shape({
        id: PropTypes.string,
        country: PropTypes.string,
        municipality: PropTypes.string,
        region: PropTypes.string,
        postCode: PropTypes.string,
        address: PropTypes.string,
    })
};

export default FillClientAddressWidget;