import React from 'react';
import {Button, Col, Container, FormCheck, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken.jsx';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues.js';
import InsuranceCompanySelector from '../../../elements/Form/InsuranceCompanySelector.jsx';
import ToggleButtonGroupField from '../../../elements/ReactBootstrap/ToggleButtonGroupField.jsx';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {bsoTypes, toTitleCase} from '../../../../utils.js';
import {
    useCreateBsoProtocolAssistanceCardsMutation,
    useCreateBsoProtocolGreenCardsMutation,
    useCreateBsoProtocolStickersMutation,
    useCreateBsoProtocolUabCardsMutation
} from '../../../../features/apiSlice.js';
import PropTypes from 'prop-types';
import UabCardTypeSelector from '../../../elements/Form/UabCardTypeSelector.jsx';
import Card from '../../../elements/ReactBootstrap/Card.jsx';
import ErrorWidget from '../../ErrorWidget.jsx';
import BsoCount from './BsoCount.jsx';
import BsoSelect from "./BsoSelect.jsx";
import AssistanceCardSeriesSelector from "../../../elements/Form/AssistanceCardSeriesSelector.jsx";

const BsoAddWidget = ({
    bsoProtocol,
    disabled,
    setDisabled,
}) => {
    const formName = 'bsoProtocolAddForm';
    const initialFormValues = useFormPersistedValues(formName);

    const [addStickers, {
        isError: addStickersIsError,
        error: addStickersError,
        reset: addStickersReset,
    }] = useCreateBsoProtocolStickersMutation();

    const [addGreenCards, {
        isError: addGreenCardsIsError,
        error: addGreenCardsError,
        reset: addGreenCardsReset,
    }] = useCreateBsoProtocolGreenCardsMutation();

    const [addUabCards, {
        isError: addUabCardsIsError,
        error: addUabCardsError,
        reset: addUabCardsReset,
    }] = useCreateBsoProtocolUabCardsMutation();

    const [addAssistanceCards, {
        isError: addAssistanceCardIsError,
        error: addAssistanceCardsError,
        reset: addAssistanceCardsReset,
    }] = useCreateBsoProtocolAssistanceCardsMutation();

    const operations = {addStickers, addGreenCards, addUabCards, addAssistanceCards};
    const isError = addStickersIsError || addGreenCardsIsError || addUabCardsIsError || addAssistanceCardIsError;
    const error = addStickersError || addGreenCardsError || addUabCardsError || addAssistanceCardsError;

    return (<>
        <Card header="Добавяне на БСО">
            <Formik initialValues={initialFormValues || {
                bsoType: '',
                insuranceCompany: null,
                type: null,
                series: null,
                count: 0,
                bso: null,
                newestFirst: false,
            }} validate={values => {
                const errors = {};

                if (!values.bsoType) {
                    errors.bsoType = 'Моля, изберете тип на бланката';
                }

                if (['stickers', 'greenCards', 'assistanceCard'].indexOf(values.bsoType) > -1 && !values.insuranceCompany) {
                    errors.insuranceCompany = 'Моля, изберете застрахователна компания';
                }

                if (values.bsoType === 'uabCards' && !values.type) {
                    errors.type = 'Моля, изберете тип на картата';
                }

                if (values.bsoType === 'assistanceCard' && !values.series) {
                    errors.type = 'Моля, изберете серия на картата';
                }

                if(values.count > 0 && values.bso !== null) {
                    errors.bsoType = 'Моля, изберете само брой или само конкретна бланка'
                }

                return errors;
            }} onSubmit={async ({
                bsoType,
                insuranceCompany,
                type,
                series,
                count,
                bso,
                newestFirst,
            }) => {
                setDisabled && setDisabled(true);
                addStickersReset();
                addGreenCardsReset();
                addUabCardsReset();
                addAssistanceCardsReset();

                const values = {};

                switch (bsoType) {
                    case 'stickers':
                    case 'greenCards':
                    case 'assistanceCards':
                        values.insuranceCompany = insuranceCompany;
                        values.newestFirst = newestFirst;
                    // noinspection FallThroughInSwitchStatementJS
                    case 'uabCards':
                        values.count = count;
                        values.bso = bso;
                }

                if (bsoType === 'assistanceCards') {
                    values.series = series;
                }

                if (bsoType === 'uabCards') {
                    values.type = type;
                }

                await operations['add' + toTitleCase(bsoType)]([bsoProtocol.id, values]);
                setDisabled && setDisabled(false);
            }}>
                {({isSubmitting, values}) => {
                    isSubmitting = isSubmitting || disabled;
                    const isMtplBso = ['stickers', 'greenCards'].indexOf(values.bsoType) > -1;
                    const isUabCard = values.bsoType === 'uabCards';
                    const isAssistanceCard = values.bsoType === 'assistanceCards';

                    return (<Container as={Form} className="px-0">
                        <Row className="row-cols-1 row-cols-xl-4 g-3">
                            <Col>
                                Тип: <Field component={ToggleButtonGroupField} type="radio" name="bsoType"
                                    className="ms-2" options={bsoTypes} disabled={isSubmitting} buttonProps={{
                                        variant: 'outline-secondary',
                                    }} />
                                {(isMtplBso || isAssistanceCard) && <FormCheck className="mt-3">
                                    <Field as={FormCheckInput} type="checkbox" name="newestFirst" id="newestFirst"
                                        disabled={isSubmitting} />
                                    <FormCheckLabel htmlFor="newestFirst">Първо най-новите</FormCheckLabel>
                                </FormCheck>}
                            </Col>
                            {values.bsoType && <>
                                {isMtplBso && <Col>
                                    <InsuranceCompanySelector required={true} mtplOnly={true} disabled={isSubmitting}/>
                                </Col>}
                                {isAssistanceCard && <Col>
                                    <InsuranceCompanySelector required={true} mtplOnly={false} disabled={isSubmitting}
                                                              bulstradOnly={true}/>
                                </Col>}
                                {isUabCard && <Col>
                                    <UabCardTypeSelector required={true} disabled={isSubmitting} name="type" />
                                </Col>}
                                {isAssistanceCard && <Col>
                                    <AssistanceCardSeriesSelector required={true} disabled={isSubmitting} name="series" />
                                </Col>}
                                <Col>
                                    <BsoCount sendOfficeId={bsoProtocol.sendOffice.id} />
                                </Col>
                                <Col>
                                    <BsoSelect sendOfficeId={bsoProtocol.sendOffice.id} />
                                </Col>
                                <Col>
                                    <Button type="submit" variant="success" className="w-100" disabled={isSubmitting}>
                                        <i className="fa-solid fa-square-plus me-2" />
                                        Добави в протокола
                                    </Button>
                                </Col>
                            </>}
                        </Row>
                        <AutoPersistToken formName={formName}/>
                    </Container>);
                }}
            </Formik>
        </Card>
        {isError && <ErrorWidget error={error} />}
    </>);
};

BsoAddWidget.defaultProps = {
    disabled: false,
};

BsoAddWidget.propTypes = {
    bsoProtocol: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    setDisabled: PropTypes.func,
};

export default BsoAddWidget;
