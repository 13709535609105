import PropTypes from "prop-types";
import {Col, Container, FormCheck, FormLabel, Row, Table} from "react-bootstrap";
import Card from "../elements/ReactBootstrap/Card.jsx";
import React from "react";
import IconField from "../elements/Formik/IconField.jsx";
import {Field, Form, Formik} from "formik";
import IssueUserSelectionWidget from "./IssueUserSelectionWidget.jsx";
import SubmitButton from "../elements/Form/SubmitButton.jsx";
import AutoPersistToken from "../elements/Formik/AutoPersistToken.jsx";
import {sum, voucherTypes} from "../../utils.js";
import usePaymentMethods from "../../hooks/usePaymentMethods.js";
import IconSelectField from "../elements/ReactSelect/IconSelectField.jsx";
import Alert from "../elements/ReactBootstrap/Alert.jsx";
import {formatInTimeZone} from "date-fns-tz";

const IssueAnnexWidget = ({
      annexType,
      selectedInstallments,
      onSubmit,
  }) => {
    const formName = 'issueAnnexForm';

    const todayFormatted= formatInTimeZone(new Date(), 'Europe/Sofia', 'yyyy-MM-dd HH:mm')

    const {
        paymentMethodOptions,
        paymentMethodIcons,
        hasNonCashPaymentMethods,
    } = usePaymentMethods();

    const issueHeader = 'Въвеждане на ' + voucherTypes[annexType];

    // const premiumAmount = annexType === 'STANDARD' ?
    //     Math.round(sum(selectedInstallments.map(installment => installment.premiumAmount)) * 100)/100 : 0;
    // const taxAmount = annexType === 'STANDARD' ?
    //     Math.round(sum(selectedInstallments.map(installment => installment.taxAmount)) * 100)/100 : 0;
    // const stickerAmount = annexType === 'STANDARD' ?
    //     Math.round(sum(selectedInstallments.map(installment => installment.stickerAmount)) * 100)/100 : 0;
    // const guaranteeFundAmount = annexType === 'STANDARD' ?
    //     Math.round(sum(selectedInstallments.map(installment => installment.guaranteeFundAmount)) * 100)/100 : 0;

    return (
        <Card noPadding header={issueHeader}>
            <Formik
                initialValues={{
                    number: '',
                    issueDate: todayFormatted,
                    premiumAmount: 0,
                    taxAmount: 0,
                    stickerAmount: 0,
                    guaranteeFundAmount: 0,
                    paymentMethod: 'CASH',
                    issueUser: '',
                }}
                validate={values => {
                    const errors = {};
                    if (!values.issueUser) {
                        errors.issueUser = 'Моля, изберете консултант издал анеска!';
                    }
                    if (annexType === 'STANDARD' && values.premiumAmount === 0) {
                        errors.issueUser = 'Моля, въведете сумите по квитанция!';
                    }

                    return errors;
                }}
                onSubmit={values => onSubmit({
                    ...values})}>
                {({isSubmitting, values}) => (
                    <Container as={Form} fluid className="gx-0">
                        <div className="table-responsive">
                            <Table striped className="mb-0">
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Дата на издаване</th>
                                    <th>Премия</th>
                                    <th>Данък</th>
                                    <th>Стикер</th>
                                    <th>ГФ/ОФ</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <IconField type="text" name="number" inputMode="text" disabled={isSubmitting}
                                                   autoComplete="off" />
                                    </td>
                                    <td><IconField type="datetime-local" name="issueDate" max={todayFormatted}
                                                   disabled={isSubmitting} />
                                    </td>
                                    <td>
                                        <IconField type="number" name="premiumAmount" inputMode="decimal"
                                                   disabled={isSubmitting} maxLength={10} autoComplete="off"/>
                                    </td>
                                    <td>
                                        <IconField type="number" name="taxAmount" inputMode="decimal"
                                                   disabled={isSubmitting} maxLength={10} autoComplete="off"/>
                                    </td>
                                    <td>
                                        <IconField type="number" name="stickerAmount" inputMode="decimal"
                                                   disabled={isSubmitting} maxLength={10} autoComplete="off"/>
                                    </td>
                                    <td>
                                        <IconField type="number" name="guaranteeFundAmount" inputMode="decimal"
                                                   disabled={isSubmitting} maxLength={10} autoComplete="off"/>
                                    </td>
                                    {annexType === 'STANDARD' && <td>
                                        <FormCheck as={Field} name="setOffForDamage" id="setOffForDamage"
                                                   label="Прихв. по щета" disabled={isSubmitting}>
                                        </FormCheck>
                                    </td>}
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="m-3">
                            <Row>
                                <Col><FormLabel>Консултант</FormLabel><IssueUserSelectionWidget/></Col>
                                {hasNonCashPaymentMethods && <Col>
                                    <Field name="paymentMethod" component={IconSelectField} options={paymentMethodOptions}
                                       label="Начин на плащане" disabled={isSubmitting}
                                       {...paymentMethodIcons[values.paymentMethod]} />
                                </Col>}
                            </Row>
                                {values.paymentMethod === 'POS_TERMINAL' &&
                                <Row><Col className="mt-2 mb-0">
                                        <IconField type="text" name="posTerminalReference" label="Номер на разписката"
                                                   prependIcon="receipt" disabled={isSubmitting} maxLength="6" pattern="[a-zA-Z\d]{6}"
                                                   autoComplete="off" /></Col><Col>
                                        <Alert variant="secondary" icon={null} className="mt-2 mb-0">
                                            <i className='fas fa-fas fa-circle-left flex-shrink-0 me-2' />
                                            Код от 6 букви или цифри, намиращ се под текста „не се
                                                изисква подпис“, между буквите AC и знака наклонена черта (/).
                                                Пример: AC <strong><u>2RG5F3</u></strong> / NO 341825 ...
                                        </Alert>
                                    </Col></Row>
                                }

                        </div>
                        <div className="m-3">
                            <SubmitButton>Запиши</SubmitButton>
                        </div>
                        <AutoPersistToken formName={formName}/>
                    </Container>
                )}
            </Formik>
        </Card>
    );
}
IssueAnnexWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default IssueAnnexWidget;