import React, {useState} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import AddUserLimitWidget from "./AddUserLimitWidget.jsx";
import {useAddUserLimitMutation} from "../../../features/apiSlice.js";
import ErrorWidget from "../ErrorWidget.jsx";
import {useParams} from "react-router-dom";
import RequireRole from "../../elements/AccessControl/RequireRole.jsx";
import useCurrentUserHasRole from "../../../hooks/useCurrentUserHasRole.js";
import UserLimitInfoRowWidget from "./UserLimitInfoRowWidget.jsx";

const UserLimitsInfoWidget = ({
      initialLimits,
}) => {
    const {userId} = useParams();
    const [limits, setLimits] = useState(initialLimits);
    const hasAddLimitRole = useCurrentUserHasRole('ROLE_ADD_LIMIT');

    const [addLimit, {
        isError,
        error,
    }] = useAddUserLimitMutation();

    return (
        <Card header={'Лимити (' + limits.length + ')'} collapse headerClassName='bg-success'>
            <Row>
            <Col sm={4}>
                <Row>
                    <Col as="dt">Общо кредит:</Col>
                    <Col as="dd">{limits.reduce(function(prev, current) {
                        return prev + +current.loadFromBroker
                    }, 0)}</Col>
                </Row>
                <Row>
                    <Col as="dt">Общо от комисион:</Col>
                    <Col as="dd">{limits.reduce(function(prev, current) {
                        return prev + +current.loadFromCommission
                    }, 0)}</Col>
                </Row>
                <Row>
                    <Col as="dt">Общо захранване:</Col>
                    <Col as="dd">{limits.reduce(function(prev, current) {
                        return prev + +current.loadFromUser
                    }, 0)}</Col>
                </Row>
                <Row>
                    <Col as="dt">Обща наличност:</Col>
                    <Col as="dd">{limits.reduce(function(prev, current) {
                        return prev + +current.remain
                    }, 0)}</Col>
                </Row>
            </Col>
            <Col sm={8}>
                <RequireRole name="ROLE_ADD_LIMIT">
                {isError && hasAddLimitRole && <ErrorWidget error={error} />}
                <AddUserLimitWidget onSubmit={async values => {
                    // eslint-disable-next-line no-restricted-globals
                    if (!confirm('Потвърдете добавянето на лимит')) {
                        return Promise.reject(new Error('Добавянето беше отказано'));
                    }
                    const newLimit = await addLimit(([userId, values])).unwrap();

                    if (newLimit) {
                        setLimits([...limits, newLimit]);
                    }
                }}/>
                </RequireRole>
                </Col>
            </Row>
            <Row>
                <div className="table-responsive">
                    <Table hover className="mb-0">
                        <thead>
                        <tr>
                            <th>Дата/час</th>
                            <th>Кредит</th>
                            <th>Комисион</th>
                            <th>Захранване</th>
                            <th>Наличност</th>
                            <th colSpan={4}>Заем</th>
                        </tr>
                        </thead>
                        <tbody>
                            {limits.map(limit => (
                                <UserLimitInfoRowWidget limit={limit} key={limit.id}/>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Row>
    </Card>);
};

UserLimitsInfoWidget.propTypes = {
    limits: PropTypes.arrayOf(PropTypes.shape({
        loadDate: PropTypes.string.isRequired,
        loadFromBroker: PropTypes.number.isRequired,
        loadFromCommission: PropTypes.number.isRequired,
        loadFromUser: PropTypes.number.isRequired,
        remain: PropTypes.number.isRequired,
        loan: PropTypes.bool.isRequired,
    }).isRequired)
};

export default UserLimitsInfoWidget;
