import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import StickerInfoButton from './NavigationButtons/StickerInfoButton.jsx';
import BsoStatusWidget from "./BsoStatusWidget.jsx";
import {useListInsuranceCompaniesQuery} from "../../features/apiSlice.js";
import Card from '../elements/ReactBootstrap/Card.jsx';
import SmallSpinner from "../elements/Spinner/SmallSpinner.jsx";
import React from "react";

const StickersListWidget = ({
    stickers,
    actionButton,
}) => {
    const {
        data: insuranceCompanies,
        isSuccess,
        isLoading,
    } = useListInsuranceCompaniesQuery();

    return (<>
        {isLoading && <SmallSpinner />}
        {isSuccess && <>
            {/* eslint-disable-next-line array-callback-return */}
        {insuranceCompanies.map(insuranceCompany =>
            {const currentInsuranceCompanyStickers = stickers.filter(sticker =>
                sticker.insuranceCompany.id === insuranceCompany.id);
            if (currentInsuranceCompanyStickers.length > 0) {
            return(<Card header={insuranceCompany?.shortName + ' (' + currentInsuranceCompanyStickers.length + ')'}
                 initialClosed collapse noPadding key={insuranceCompany.id}
                 outline baseClassName='card-primary'>
                    <div className="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                            <tr>
                                {actionButton && <th width={72} />}
                                <th>Номер</th>
                                <th>Статус</th>
                                <th>Консултант</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentInsuranceCompanyStickers.map(sticker => <tr key={sticker.id}>
                                {actionButton && <td>{actionButton(sticker)}</td>}
                                <td><StickerInfoButton sticker={sticker} /></td>
                                <td><BsoStatusWidget {...sticker} /></td>
                                <td>{sticker.user?.shortName}</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </Card>
            )}})}
        </>}
    </>);
};

StickersListWidget.propTypes = {
    stickers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceBroker: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceCompany: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
    }).isRequired),
    actionButton: PropTypes.func,
};

export default StickersListWidget;
