import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import SelectField from "../../elements/ReactSelect/SelectField.jsx";
import {vehicleTypes} from "../../../utils.js";

const VehicleTypeWidget = ({
    onSubmit,
}) => {

    const vehicleTypeOptions = useMemo(
        () => Object.entries(vehicleTypes).map(([vehicleTypeId, vehicleType]) => ({
            value: vehicleTypeId,
            label: vehicleType,
        })), [vehicleTypes]);

    return (
        <Formik
            initialValues={{
                type: 'LIGHT_CAR',
            }}
            validate={values => {
                const errors = {};
                if (!values.type) {
                    errors.type = 'Моля въведете тип МПС!';
                }
                return errors;
            }}
            onSubmit={onSubmit}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <Col>
                            <Field name="type" component={SelectField} options={vehicleTypeOptions}
                                   label="Тип МПС (D)" />
                        </Col>
                        <Col>
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

VehicleTypeWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
export default VehicleTypeWidget;
