import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Container, Image, Row} from 'react-bootstrap';
import EmailButton from '../SimpleButtons/EmailButton.jsx';
import PhoneButton from '../SimpleButtons/PhoneButton.jsx';
import WeekCodeSelector from '../../elements/Form/WeekCodeSelector.jsx';
import {Form, Formik} from 'formik';
import ErrorWidget from '../ErrorWidget.jsx';
import {useUpdateInsuranceBrokerMutation} from '../../../features/apiSlice.js';
import Alert from '../../elements/ReactBootstrap/Alert.jsx';
import TextWithCopyButton from '../TextWithCopyButton.jsx';
import DateLong from "../../elements/DateTime/DateLong.jsx";

const InsuranceBrokerInfoWidget = ({
    broker,
}) => {
    const [update, {
        isSuccess: updateSuccess,
        isError: updateIsError,
        error: updateError,
    }] = useUpdateInsuranceBrokerMutation();

    return (<>
        <Formik initialValues={{
            confirmedReportWeek: broker.confirmedReportWeek || '',
        }} onSubmit={values => update([broker.id, values])}>
            {({isSubmitting, values, setFieldValue}) => (<Container as={Form} fluid className="g-0">
                <Row as="dl" className="mb-0">
                    {broker.pin && <>
                        <Col sm={4} as="dt">ЕИК</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.pin} />
                        </Col>
                    </>}
                    {broker.name && <>
                        <Col sm={4} as="dt">Име</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.name} />
                        </Col>
                    </>}
                    {broker.shortName && <>
                        <Col sm={4} as="dt">Кратко име</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.shortName} />
                        </Col>
                    </>}
                    {broker.region && <>
                        <Col sm={4} as="dt">Област</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.region} />
                        </Col>
                    </>}
                    {broker.municipality && <>
                        <Col sm={4} as="dt">Община</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.municipality} />
                        </Col>
                    </>}
                    {broker.city && <>
                        <Col sm={4} as="dt">Град</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.city} />
                        </Col>
                    </>}
                    {broker.address && <>
                        <Col sm={4} as="dt">Адрес</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.address} />
                        </Col>
                    </>}
                    {broker.zipCode && <>
                        <Col sm={4} as="dt">Пощенски код</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.zipCode} />
                        </Col>
                    </>}
                    {broker.phone && <>
                        <Col sm={4} as="dt">Телефонен номер</Col>
                        <Col sm={8} as="dd">
                            <PhoneButton phone={broker.phone} />
                        </Col>
                    </>}
                    {broker.email && <>
                        <Col sm={4} as="dt">E-mail адрес</Col>
                        <Col sm={8} as="dd">
                            <EmailButton email={broker.email} />
                        </Col>
                    </>}
                    {broker.logoUrl && <>
                        <Col sm={4} as="dt">Лого</Col>
                        <Col sm={8} as="dd">
                            <Image src={broker.logoUrl} />
                        </Col>
                    </>}
                    {broker.iban && <>
                        <Col sm={4} as="dt">IBAN на СКС по чл. 306</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.iban} />
                        </Col>
                    </>}
                    {broker.bic && <>
                        <Col sm={4} as="dt">BIC/SWIFT на СКС по чл. 306</Col>
                        <Col sm={8} as="dd">
                            <TextWithCopyButton text={broker.bic} />
                        </Col>
                    </>}
                    {broker.supportUrl && <>
                        <Col sm={4} as="dt">URL на секция "Помощ и поддръжка"</Col>
                        <Col sm={8} as="dd">
                            <a href={broker.supportUrl} target="_blank" rel="noreferrer">Отвори в нов таб</a>
                        </Col>
                    </>}
                    <Col sm={4} as="dt">Потвърден отчет продажби за седмица</Col>
                    <Col sm={8} lg={4} as="dd">
                        <WeekCodeSelector name="confirmedReportWeek" />
                    </Col>
                    <Col lg={4} />
                    {values.confirmedReportWeek && <Col sm={{ span: 8, offset: 4 }} as="dd">
                        <Button variant="danger" onClick={() => {
                            setFieldValue('confirmedReportWeek', '');
                        }}>X</Button>
                    </Col>}
                    {broker.serviceFeeBeginDate && <>
                        <Col sm={4} as="dt">Начало на тарифирането</Col>
                        <Col sm={8} as="dd">
                            <DateLong date={broker.serviceFeeBeginDate} />
                        </Col>
                    </>}
                    <Col sm={{ span: 8, offset: 4 }} lg={{ span: 4, offset: 4 }} as="dd">
                        <Button variant="success" type="submit" className="w-100" disabled={isSubmitting}>
                            <i className="fas fa-upload" /> Запиши настройките
                        </Button>
                    </Col>
                </Row>
            </Container>)}
        </Formik>
        {updateSuccess && <div className="mt-3">
            <Alert variant="success" heading={<>Успешно записахте настройките!</>} />
        </div>}
        {updateIsError && <div className="mt-3">
            <ErrorWidget error={updateError} />
        </div>}
    </>);
};

InsuranceBrokerInfoWidget.propTypes = {
    broker: PropTypes.shape({
        pin: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        shortName: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        municipality: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        zipCode: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        logoUrl: PropTypes.string.isRequired,
        supportUrl: PropTypes.string,
        confirmedReportWeek: PropTypes.string,
    }).isRequired,
};

export default InsuranceBrokerInfoWidget;
