import React from 'react';
import {Card, Col, Nav, Row, Tab} from 'react-bootstrap';
import NavItem from '../elements/NavItem';
import {Outlet, useMatch} from 'react-router-dom';
import Content from '../partials/Content.jsx';

const Nomenclatures = () => {

    const isFluid = useMatch('/nomenclatures');

    return (<Content title="Номенклатури">
        <Row className="row-cols-1 flex-column flex-grow-1">
            <Col className="d-flex flex-grow-1 flex-column">
                <Card className={"card-info card-tabs" + (isFluid ? ' flex-grow-1' : '')}>
                    <Card.Header className="p-0 pt-1">
                        <Nav as="ul" className="nav-tabs" style={isFluid ? {
                            border: 0,
                        } : null}>
                            <NavItem to="products">Продукти</NavItem>
                            <NavItem to="fsc-categories">КФН Категории</NavItem>
                        </Nav>
                    </Card.Header>
                    <Card.Body className={isFluid ? 'p-0 d-flex' : null}>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Tab.Content className={isFluid ? 'd-flex flex-column flex-grow-1' : null}>
                            <Tab.Pane active className={isFluid ? 'd-flex flex-column flex-grow-1' : null}>
                                <Outlet />
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default Nomenclatures;
