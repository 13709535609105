import {getVoucherUrl} from '../../../url';
import PrintButton from '../ReactBootstrap/PrintButton';
import React from 'react';
import {printVoucher} from '../../../print';
import PropTypes from 'prop-types';

const VoucherPrintButton = ({
    voucher,

    ...props
}) => {
    return (
        <PrintButton icon="file-invoice-dollar" printAction={() => printVoucher(voucher.id)}
            viewUrl={getVoucherUrl(voucher.id)}
            downloadUrl={getVoucherUrl(voucher.id, true)}
            objId={voucher.id} {...props} />
    );
};

VoucherPrintButton.defaultProps = {
    label: 'Печат на сметка',
    objType: 'voucher',
};

VoucherPrintButton.propTypes = {
    label: PropTypes.string,
    objType: PropTypes.string,
    voucher: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default VoucherPrintButton;
