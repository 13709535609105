import React, {useCallback} from 'react';
import {Button, Col, Container, FormLabel, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {
    useGetUserLevelCommissionSettingQuery,
    useUpdateUserLevelCommissionSettingMutation
} from '../../../features/apiSlice.js';
import ErrorWidget from '../ErrorWidget.jsx';
import {Field, Form, Formik} from 'formik';
import Percent from '../../elements/Values/Percent.jsx';
import Alert from '../../elements/ReactBootstrap/Alert.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import BackButton from '../../elements/BackButton.jsx';
import {formatISO, parseISO} from 'date-fns';

const UserLevelCommissionSettingWidget = () => {
    const { id } = useParams();

    const {
        data: userLevelCommissionSetting,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetUserLevelCommissionSettingQuery(id, {
        skip: id === undefined,
    });

    const [update, {
        isSuccess: updateSuccess,
        isError: updateIsError,
        error: updateError,
    }] = useUpdateUserLevelCommissionSettingMutation();

    const formatDate = useCallback(dateTimeString => dateTimeString ?
        formatISO(parseISO(dateTimeString), {
            representation: 'date',
        }) : '', []);

    return (<>
        {isLoading && <div>Зареждане на информация за комисион на ниво... <SmallSpinner /></div>}
        {isError && <ErrorWidget error={error} />}
        {isSuccess && <>
            <Formik initialValues={{
                beginDate: formatDate(userLevelCommissionSetting.beginDate),
                endDate: formatDate(userLevelCommissionSetting.endDate),
                policyCoefficient: userLevelCommissionSetting.policyCoefficient,
                voucherCoefficient: userLevelCommissionSetting.voucherCoefficient,
                independentPolicyCoefficient: userLevelCommissionSetting.independentPolicyCoefficient,
                stickerCommissionOnForeignPolicy: userLevelCommissionSetting.stickerCommissionOnForeignPolicy,
            }} onSubmit={values => update([id, {
                ...values,
                beginDate: values.beginDate === '' ? null : values.beginDate,
                endDate: values.endDate === '' ? null : values.endDate,
            }])}>
                {({isSubmitting, values}) => (<Container as={Form} fluid className="g-0">
                    <Row as="dl" className="mb-0">
                        <Col sm={4} as="dt">Застрахователна компания</Col>
                        <Col sm={8} as="dd">{userLevelCommissionSetting.insuranceCompany?.shortName}</Col>
                        <Col sm={4} as="dt">Вид застраховане</Col>
                        <Col sm={8} as="dd">{userLevelCommissionSetting.product?.name}</Col>
                        <Col sm={4} as="dt">Начална дата</Col>
                        <Col sm={8} as="dd">
                            <Field type="date" name="beginDate" disabled={isSubmitting} />
                        </Col>
                        <Col sm={4} as="dt">Крайна дата</Col>
                        <Col sm={8} as="dd">
                            <Field type="date" name="endDate" disabled={isSubmitting} />
                        </Col>
                        <Col sm={4} as="dt">Комисион полица</Col>
                        <Col sm={8} as="dd">
                            <Field type="number" name="policyCoefficient" min={0} max={1} step={0.0001}
                                disabled={isSubmitting} />
                        </Col>
                        <Col sm={4} as="dt">Комисион квитанция</Col>
                        <Col sm={8} as="dd">
                            <Field type="number" name="voucherCoefficient" min={0} max={1} step={0.0001}
                                disabled={isSubmitting} />
                        </Col>
                        <Col sm={4} as="dt">Наша полица, платена другаде</Col>
                        <Col sm={8} as="dd">
                            <Field type="checkbox" name="independentPolicyCoefficient" id="independentPolicyCoefficient"
                                disabled={isSubmitting} />{' '}
                            <FormLabel htmlFor="independentPolicyCoefficient">
                                Начисляване на комисион
                            </FormLabel>
                        </Col>
                        <Col sm={4} as="dt">Комисион чужда квитанция</Col>
                        <Col sm={8} as="dd">
                            <Field type="checkbox" name="stickerCommissionOnForeignPolicy" id="stickerCommissionOnForeignPolicy"
                                   disabled={isSubmitting} />{' '}
                            <FormLabel htmlFor="stickerCommissionOnForeignPolicy">
                                Включване на такса стикер
                            </FormLabel>
                        </Col>
                        <Col sm={4} as="dt">Краен резултат</Col>
                        <Col sm={8} as="dd">Наша: <Percent coefficient={
                            values.policyCoefficient + values.voucherCoefficient
                        } toFixed={2}/></Col>
                        <Col sm={{ offset: 4, span: 8}} as="dd">Чужда: <Percent coefficient={
                            values.voucherCoefficient
                        } toFixed={2}/></Col>
                        <Col sm={{ offset: 4, span: 8}} as="dd">Наша, платена другаде: <Percent coefficient={
                            values.independentPolicyCoefficient ? values.policyCoefficient : 0.0
                        } toFixed={2}/></Col>
                        <Col sm={4} as="dt" className="mt-3">
                            <BackButton to="/settings/user-level" disabled={isSubmitting} />
                        </Col>
                        <Col sm={8} lg={4} as="dd" className="mt-3">
                            <Button variant="success" type="submit" className="w-100" disabled={isSubmitting}>
                                <i className="fas fa-upload" /> Запиши настройките
                            </Button>
                        </Col>
                    </Row>
                </Container>)}
            </Formik>
            {updateSuccess && <div className="mt-3">
                <Alert variant="success" heading={<>Успешно записахте настройките!</>} />
            </div>}
            {updateIsError && <div className="mt-3">
                <ErrorWidget error={updateError} />
            </div>}
        </>}
    </>);
};

export default UserLevelCommissionSettingWidget;
