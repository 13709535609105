import React, {lazy, Suspense, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import Card from '../elements/ReactBootstrap/Card.jsx';
import localeText from '../elements/AgGrid/locale.bg';
import {defaultComparator} from '@ag-grid-community/core/dist/esm/es6/utils/generic';
import EmailButton from './SimpleButtons/EmailButton.jsx';
import PhoneButton from './SimpleButtons/PhoneButton.jsx';
import {Link} from 'react-router-dom';
import UserAvatar from '../elements/UserAvatar.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const AgGridEnterprise = lazy(() => import('../elements/AgGrid/AgGridEnterprise.js'));
const AgGridReact = lazy(() => import('../elements/AgGrid/AgGridReactWrapper'));

const UsersGridWidget = ({
    rowData,
    showExtraControls,
    treeData,
}) => {
    const animateRows = true;

    const getDataPath = data => [
        ...(data.referrer ? getDataPath(data.referrer) : []),
        data.firstName + ' ' + data.lastName,
    ];

    const onGridReady = useCallback(({ api, columnApi }) => {
        if (!treeData) {
            columnApi.applyColumnState({
                state: [{
                    colId: 'ag-Grid-AutoColumn',
                    sort: 'asc',
                    sortIndex: 1,
                }, {
                    colId: 'office.name',
                    hide: true,
                    sort: 'asc',
                    sortIndex: 0,
                    rowGroupIndex: 0,
                }, {
                    colId: 'firstName',
                    sort: 'asc',
                    sortIndex: 2,
                }, {
                    colId: 'lastName',
                    sort: 'asc',
                    sortIndex: 3,
                }],
                defaultState: {
                    // important to say 'null' as undefined means 'do nothing'
                    sort: null,
                },
            });

            api.forEachNode(node => {
                node.setExpanded(node.rowGroupIndex === 0);
            });
        }

        api.getFilterInstance('active').setModel({
            values: ['Активен'],
        });
        api.onFilterChanged();
    }, [treeData]);

    const columnDefs = useMemo(() => [{
        colId: 'avatar',
        headerName: 'Аватар',
        field: 'avatar',
        filter: false,
        cellRenderer: params => params.data ? <UserAvatar user={params.data} size={34} className="user-image shadow" />
            : null,
        width: 70,
    }, {
        headerName: 'Офис',
        field: 'office.name',
        width: 145,
    }, {
        headerName: 'Име',
        field: 'firstName',
        width: 137,
    }, {
        headerName: 'Презиме',
        field: 'middleName',
        width: 167,
    }, {
        headerName: 'Фамилия',
        field: 'lastName',
        width: 171,
    }, {
        headerName: 'ЕГН',
        field: 'pin',
        cellRenderer: params => <TextWithCopyButton text={params?.value} small />,
        width: 145,
    }, {
        headerName: 'E-mail',
        field: 'email',
        cellRenderer: params => params.value ? <EmailButton email={params.value} small /> : null,
        width: 313,
    }, {
        headerName: 'Телефон',
        field: 'phone',
        cellRenderer: params => params.value ? <PhoneButton phone={params.value} small /> : null,
        width: 213,
    }, {
        colId: 'active',
        headerName: 'Статус',
        valueGetter: params => params.data ? (params.data.active ? 'Активен' : 'Неактивен') : null,
        width: 153,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset'],
        },
    }, {
        colId: 'actions',
        width: 66,
        filter: false,
        cellRenderer: params => params.data ? <Link to={'/user/' + params.data.id}
            className={'btn btn-outline-secondary p-1'}>
                <i className="fa-solid fa-user-pen" />
        </Link> : null,
    }], []);

    const defaultColDef = useMemo(() => ({
        comparator: (valueA, valueB) => defaultComparator(
            valueA?.toLowerCase ? valueA.toLowerCase() : valueA,
            valueB?.toLowerCase ? valueB.toLowerCase() : valueB,
        ),
        enableRowGroup: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filter: 'agTextColumnFilter',
                },
                {
                    filter: 'agSetColumnFilter',
                    filterParams: {
                        buttons: ['reset'],
                    },
                },
            ],
        },
        floatingFilter: showExtraControls,
        resizable: true,
        sortable: true,
    }), [showExtraControls]);

    const rowGroupPanelShow = showExtraControls ? 'always' : 'never';

    const rowClassRules = useMemo(() => ({
        'grid-row-secondary': params => params.data && !params.data.active,
        'progress-bar-striped': params => params.data && !params.data.active,
    }), []);

    return (<Suspense fallback={<Card><SmallSpinner /></Card>}>
        <AgGridEnterprise>
            <AgGridReact className="ag-theme-alpine" {...{
                animateRows, treeData, getDataPath, columnDefs, defaultColDef, localeText, onGridReady, rowData,
                rowGroupPanelShow, rowClassRules
            }} />
        </AgGridEnterprise>
    </Suspense>);
};

UsersGridWidget.defaultProps = {
    showExtraControls: false,
    treeData: false,
};

UsersGridWidget.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
    showExtraControls: PropTypes.bool,
    treeData: PropTypes.bool,
};

export default UsersGridWidget;
