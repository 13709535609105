import React, {useMemo} from 'react';
import {Field, useFormikContext} from 'formik';
import {
    useListAssistanceCardsQuery,
    useListGreenCardsQuery,
    useListStickersQuery,
    useListUabCardsQuery
} from '../../../../features/apiSlice.js';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';
import ErrorIcon from '../../../elements/Icons/ErrorIcon.jsx';
import PropTypes from 'prop-types';
import GreenCardNumber from "../../../elements/Values/GreenCardNumber.jsx";
import UabCardNumber from "../../../elements/Values/UabCardNumber.jsx";
import StickerNumber from '../../../elements/Values/StickerNumber';
import SelectField from "../../../elements/ReactSelect/SelectField.jsx";
import {createFilter} from "react-select";
import {formatGreenCardNumber, formatStickerNumber, formatUabCardNumber} from "../../../../utils.js";
import AssistanceCardNumber from "../../../elements/Values/AssistanceCardNumber.jsx";

const BsoSelect = ({
    sendOfficeId,
}) => {
    const { isSubmitting, values } = useFormikContext();

    const isStickers = values.bsoType === 'stickers';
    const isGreenCards = values.bsoType === 'greenCards';
    const isUabCards = values.bsoType === 'uabCards';
    const isAssistanceCards = values.bsoType === 'assistanceCards'

    const {
        data: stickersList,
        error: stickersListError,
        isLoading: stickersListLoading,
        isFetching: stickersListFetching,
        isSuccess: stickersListSuccess,
    } = useListStickersQuery({
        insuranceCompany: values.insuranceCompany,
        office: sendOfficeId,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !isStickers,
    });

    const {
        data: greenCardsList,
        error: greenCardsListError,
        isLoading: greenCardsListLoading,
        isFetching: greenCardsListFetching,
        isSuccess: greenCardsListSuccess,
    } = useListGreenCardsQuery({
        insuranceCompany: values.insuranceCompany,
        office: sendOfficeId,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !isGreenCards,
    });

    const {
        data: uabCardsList,
        error: uabCardsListError,
        isLoading: uabCardsListLoading,
        isFetching: uabCardsListFetching,
        isSuccess: uabCardsListSuccess,
    } = useListUabCardsQuery({
        office: sendOfficeId,
        type: values.type,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !isUabCards,
    });

    const {
        data: assistanceCardsList,
        error: assistanceCardsListError,
        isLoading: assistanceCardsListLoading,
        isFetching: assistanceCardsListFetching,
        isSuccess: assistanceCardsListSuccess,
    } = useListAssistanceCardsQuery({
        insuranceCompany: values.insuranceCompany,
        office: sendOfficeId,
        series: values.series,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !isAssistanceCards,
    });

    const isLoading = stickersListLoading || stickersListFetching || greenCardsListLoading || greenCardsListFetching ||
        uabCardsListLoading || uabCardsListFetching || assistanceCardsListLoading || assistanceCardsListFetching;
    const error = stickersListError || greenCardsListError || uabCardsListError || assistanceCardsListError;

    const stickers = stickersListSuccess ? stickersList : [];
    const greenCards = greenCardsListSuccess ? greenCardsList : [];
    const uabCards = uabCardsListSuccess ? uabCardsList : [];
    const assistanceCards = assistanceCardsListSuccess ? assistanceCardsList : [];

    const bso = isStickers ? stickers : (
        isGreenCards ? greenCards : (
            isUabCards ? uabCards : (
                isAssistanceCards ? assistanceCards :
                    null
            )
        )
    );
    const bsoList = useMemo(() => bso.map(b => ({
        value: b.id,
        label: isStickers ? <StickerNumber {...b}/> :
            isGreenCards ? <GreenCardNumber {...b}/> :
                isAssistanceCards ? <AssistanceCardNumber {...b} /> :
                <UabCardNumber {...b}/>,
    })), [bso, isStickers, isGreenCards, isAssistanceCards]);


    if (isLoading) {
        return (<SmallSpinner />);
    }

    if (error) {
        return (<ErrorIcon error={error} />);
    }

    return (<Field name="bso" component={SelectField} options={bsoList}
                   disabled={isSubmitting} isClearable={true}
                   noOptionsMessage={() => 'Нямате налични!'}
                   filterOption={
                   isStickers ? createFilter({ stringify: option => {
                       const number = option.label.props.number;
                       return number + '\0' + formatStickerNumber(number);
                    },
                   }) :
                   isGreenCards ? createFilter({ stringify: option => {
                           const props = option.label.props;
                           const series = props.series;
                           const number = props.number;
                           const numberFormatted = formatGreenCardNumber(number);
                           return series + ' ' + numberFormatted + '\0' + series + numberFormatted;
                       },
                   }) :
                   isAssistanceCards ? createFilter({ stringify: option => {
                               const props = option.label.props;
                               const series = props.series;
                               return series + props.number;
                           },
                       }) :
                       createFilter({
                           stringify: option => {
                               const number = option.label.props.number;
                               return number + '\0' + formatUabCardNumber(number);
                           },
                       })
    }
    />);
};

BsoSelect.propTypes = {
    sendOfficeId: PropTypes.string.isRequired,
};

export default BsoSelect;
