import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import TextWithCopyButton from '../TextWithCopyButton.jsx';
import {useUpdateClientMutation} from "../../../features/apiSlice.js";
import EditButton from "../SimpleButtons/EditButton.jsx";
import ErrorWidget from "../ErrorWidget.jsx";
import PhoneButton from "../SimpleButtons/PhoneButton.jsx";
import EditInputGroup from "../../elements/ReactBootstrap/EditInputGroup.jsx";
import Alert from "../../elements/ReactBootstrap/Alert.jsx";
import DateTimeLong from "../../elements/DateTime/DateTimeLong.jsx";
import DateShort from "../../elements/DateTime/DateShort.jsx";
import useCurrentBrokerIsDemo from "../../../hooks/useCurrentBrokerIsDemo.js";

const ClientInfoWidget = ({
    client,
}) => {
    const [editName, setEditName] = useState(false);
    const [editFirstName, setEditFirstName] = useState(false);
    const [editMiddleName, setEditMiddleName] = useState(false);
    const [editLastName, setEditLastName] = useState(false);
    const [editDriversLicenseNumber, setEditDriversLicenseNumber] = useState(false);
    const [editIdCardNumber, setEditIdCardNumber] = useState(false);
    const [editIdCardDate, setEditIdCardDate] = useState(false);
    const [editMobilePhoneNumber, setEditMobilePhoneNumber] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [newValue, setNewValue] = useState('');

    const isEditOn = editName || editFirstName || editMiddleName || editLastName || editDriversLicenseNumber ||
        editIdCardNumber || editIdCardDate || editMobilePhoneNumber || editEmail;

    const isPersonalEntity = client.pin.length === 10;

    const isDemo = useCurrentBrokerIsDemo();

    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useUpdateClientMutation(client);

    return (<Row><Col sm={8}>
        <Row as="dl" className="mb-0">
            <Col as="dt">{isPersonalEntity ? 'ЕГН' : 'ЕИК'}</Col>
            <Col as="dd" className={isDemo ? 'demo' : null}><TextWithCopyButton text={client.pin} /></Col>
        </Row>
        <Row>
            <Col as="dt">Име</Col>
            <Col as="dd" className={isDemo ? 'demo' : null}>{editName && !isPersonalEntity ?
                <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                    saveOnClick={() => {
                        if (!/^[А-я\s\d"-]+$/i.test(newValue)) {
                            alert('Моля, въведете коректно име!');
                        }
                        else {
                            update([client.id, {
                                name: newValue
                            }]);
                            setEditName(oldState => !oldState);
                            setNewValue('');
                        }}}
                    fieldValue={newValue}
                    closeOnClick={() => setEditName(oldState => !oldState)} />
                : <>{client.name && <TextWithCopyButton text={client.name} />}
                    {!isPersonalEntity && <EditButton onClick={() => {
                        setEditName(oldState => !oldState);
                        setNewValue(client.name);
                    }}/>}
                </>}
            </Col>
        </Row>
        {isPersonalEntity && <>
        <Row>
            <Col as="dt">Собствено име</Col>
            <Col as="dd" className={isDemo ? 'demo' : null}>{editFirstName ?
                <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                    saveOnClick={() => {
                        if (!/^[А-я]+$/i.test(newValue)) {
                            alert('Моля, въведете коректно Собствено име!');
                        }
                        else {
                            update([client.id, {
                                firstName: newValue,
                                name: newValue +
                                    (client.hasOwnProperty('middleName') ? ' ' +  client.middleName : '') +
                                    (client.hasOwnProperty('lastName') ? ' ' +  client.lastName : '')
                            }]);
                            setEditFirstName(oldState => !oldState);
                            setNewValue('');
                        }}}
                    fieldValue={newValue}
                    closeOnClick={() => setEditFirstName(oldState => !oldState)} />
             : <> {client.firstName && <TextWithCopyButton text={client.firstName} />}
                <EditButton onClick={() =>{
                    setEditFirstName(oldState => !oldState);
                    setNewValue(client.firstName);
                }}/>
            </>}
            </Col></Row>
        <Row>
            <Col as="dt">Презиме</Col>
            <Col as="dd" className={isDemo ? 'demo' : null}>{editMiddleName ?
                <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                    saveOnClick={() => {
                        if (!/^[А-я]+$/i.test(newValue)) {
                            alert('Моля, въведете коректно Презиме!');
                        }
                        else {
                            update([client.id, {
                                middleName: newValue,
                                name: (client.hasOwnProperty('firstName') ? client.firstName + ' ' : '') +
                                    newValue +
                                    (client.hasOwnProperty('lastName') ? ' ' + client.lastName : '')
                            }]);
                            setEditMiddleName(oldState => !oldState);
                            setNewValue('');
                        }}}
                    fieldValue={newValue}
                    closeOnClick={() => setEditMiddleName(oldState => !oldState)} />
                : <>  {client.middleName && <TextWithCopyButton text={client.middleName} />}
                <EditButton onClick={() =>{
                    setEditMiddleName(oldState => !oldState);
                    setNewValue(client.middleName);
                }}/>
                </>}
            </Col>
        </Row>
        <Row>
            <Col as="dt">Фамилно име</Col>
            <Col as="dd" className={isDemo ? 'demo' : null}>{editLastName ?
                <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                    saveOnClick={() => {
                        if (!/^[А-я\s-]+$/i.test(newValue)) {
                            alert('Моля, въведете коректно Фамилно име!');
                        }
                        else {
                            update([client.id, {
                                lastName: newValue,
                                name:
                                    (client.hasOwnProperty('firstName') ? ( client.firstName + ' ') : '') +
                                    (client.hasOwnProperty('middleName') ? client.middleName + ' ' : '') +
                                    newValue
                            }]);
                            setEditLastName(oldState => !oldState);
                            setNewValue('');
                        }}}
                    fieldValue={newValue}
                    closeOnClick={() => setEditLastName(oldState => !oldState)} />
                : <> {client.lastName &&<TextWithCopyButton text={client.lastName} />}
                    <EditButton onClick={() =>{
                        setEditLastName(oldState => !oldState);
                        setNewValue(client.lastName);
                    }}/>
                </>}
            </Col>
        </Row>
        <Row>
            <Col as="dt">Номер на СУМПС</Col>
            <Col as="dd">{editDriversLicenseNumber ?
                <EditInputGroup formControlOnChange={e=>setNewValue(e.target.value)}
                    saveOnClick={() => {
                        if (!/^\d{9}$/i.test(newValue)) {
                            alert('Моля, въведете коректно Номер на СУМПС!');
                        }
                        else {
                            update([client.id, {
                                driversLicenseNumber: newValue
                            }]);
                            setEditDriversLicenseNumber(oldState => !oldState);
                            setNewValue('');
                        }}}
                    fieldValue={newValue}
                    closeOnClick={() => setEditDriversLicenseNumber(oldState => !oldState)} />
                : <> {client.driversLicenseNumber &&<TextWithCopyButton text={client.driversLicenseNumber} />}
                    <EditButton onClick={() =>{
                        setEditDriversLicenseNumber(oldState => !oldState);
                        setNewValue(client.driversLicenseNumber);
                    }}/>
                </>}
            </Col>
        </Row>
        <Row>
            <Col as="dt">Номер на ЛК</Col>
            <Col as="dd">{editIdCardNumber ?
                <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                                saveOnClick={() => {
                                    if (!/^\d{9}$/i.test(newValue)) {
                                        alert('Моля, въведете коректен номер на лична карта!');
                                    }
                                    else {
                                        update([client.id, {
                                            idCardNumber: newValue
                                        }]);
                                        setEditIdCardNumber(oldState => !oldState);
                                        setNewValue('');
                                    }}}
                                fieldValue={newValue}
                                closeOnClick={() => setEditIdCardNumber(oldState => !oldState)} />
                : <>{client.idCardNumber &&<TextWithCopyButton text={client.idCardNumber} />}
                    <EditButton onClick={() =>{
                        setEditIdCardNumber(oldState => !oldState);
                        setNewValue(client.idCardNumber);
                    }}/>
                </>}
            </Col>
        </Row>
        <Row>
            <Col as="dt">Дата на издаване на ЛК</Col>
            <Col as="dd">{editIdCardDate ?
                <EditInputGroup type="date" formControlOnChange={e=> setNewValue(e.target.value)}
                                saveOnClick={() => {
                                        update([client.id, {
                                            idCardDate: newValue
                                        }]);
                                        setEditIdCardDate(oldState => !oldState);
                                        setNewValue('');
                                    }}
                                fieldValue={newValue}
                                closeOnClick={() => setEditIdCardDate(oldState => !oldState)} />
                : <>{client.idCardDate &&<DateShort date={client.idCardDate} />}
                    <EditButton onClick={() =>{
                        setEditIdCardDate(oldState => !oldState);
                        setNewValue(client.idCardDate);
                    }}/>
                </>}
            </Col>
        </Row>
        </>}
        <Row>
            <Col as="dt">Мобилен телефон</Col>
            <Col as="dd">{editMobilePhoneNumber ?
                <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                    saveOnClick={() => {
                        if (!/^\d{10}$/i.test(newValue)) {
                            alert('Моля, въведете коректно Мобилен телефон!');
                        }
                        else {
                            update([client.id, {
                                mobilePhoneNumber: newValue
                            }]);
                            setEditMobilePhoneNumber(oldState => !oldState);
                            setNewValue('');
                        }}}
                    fieldValue={newValue}
                    closeOnClick={() => setEditMobilePhoneNumber(oldState => !oldState)} />
            : <>{client.mobilePhoneNumber &&<PhoneButton phone={client.mobilePhoneNumber} />}
                <EditButton onClick={() =>{
                    setEditMobilePhoneNumber(oldState => !oldState);
                    setNewValue(client.mobilePhoneNumber);
                }}/>
            </>}
            </Col>
        </Row>
        <Row>
            <Col as="dt">E-mail</Col>
            <Col as="dd">{editEmail ?
                <EditInputGroup formControlOnChange={e=> setNewValue(e.target.value)}
                                saveOnClick={() => {
                                    if (!/^\S+@\S+\.\S+$/i.test(newValue)) {
                                        alert('Моля, въведете коректен e-mail!');
                                    }
                                    else {
                                        update([client.id, {
                                            email: newValue
                                        }]);
                                        setEditEmail(oldState => !oldState);
                                        setNewValue('');
                                    }}}
                                fieldValue={newValue}
                                closeOnClick={() => setNewValue(oldState => !oldState)} />
                : <>{client.email &&<PhoneButton phone={client.email} />}
                    <EditButton onClick={() =>{
                        setEditEmail(oldState => !oldState);
                        setNewValue(client.email);
                    }}/>
                </>}
            </Col>
        </Row>
        <Row>
            <Col as="dt">Последна редакция от</Col>
            <Col as="dd">{client.updatedBy?.fullName && client.updatedBy.fullName}</Col>
        </Row>
        <Row>
            <Col as="dt">Последна редакция на</Col>
            <Col as="dd">{client.updatedAt && <DateTimeLong date={client.updatedAt} />}</Col>
        </Row>
        {updateIsError && <Row><Col><ErrorWidget error={updateError} /></Col></Row>}
    </Col>
    {isEditOn && <Col rowSpan={5} sm={4}>
        <Alert variant="warning" heading="Важно">
            Декларирам, че нося наказателна отговорност за подадени неверни данни!
        </Alert>
    </Col>}</Row>);
};

ClientInfoWidget.propTypes = {
    client: PropTypes.shape({
        pin: PropTypes.string.isRequired,
        name: PropTypes.string,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        driversLicenseNumber: PropTypes.string,
        mobilePhoneNumber: PropTypes.string,
        email: PropTypes.string,
        updatedAt: PropTypes.string,
        updatedBy: PropTypes.shape({
            fullName: PropTypes.string,
        }),
    }).isRequired,
};

export default ClientInfoWidget;
