import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {assistanceCardSeries} from '../../../utils.js';
import PropTypes from 'prop-types';

const AssistanceCardSeriesSelector = ({
    required,
    ...props
}) => {
    const assistanceCardSeriesOptions = useMemo(
        () => Object.entries(assistanceCardSeries).map(
            ([assistanceCardSeriesId, assistanceCardSeries]) => ({
                value: assistanceCardSeriesId,
                label: assistanceCardSeries.name,
            })), [assistanceCardSeries]);

    const { isSubmitting } = useFormikContext();

    return (<Field component={SelectField} name="assistanceCardSeries" options={assistanceCardSeriesOptions}
        disabled={isSubmitting} isClearable={true} required={required}
        placeholder={required ? 'Изберете тип' : 'Всички типове'}
        filterOption={createFilter({
            stringify: option => option.label,
        })} {...props} />);
};

AssistanceCardSeriesSelector.defaultProps = {
    required: false,
};

AssistanceCardSeriesSelector.propTypes = {
    required: PropTypes.bool,
};

export default AssistanceCardSeriesSelector;
