import React from 'react';
import {Row} from 'react-bootstrap';
import ReportBox from '../elements/ReportBox';
import RequireRole from '../elements/AccessControl/RequireRole';
import Content from '../partials/Content.jsx';

const Reports = () => {
    return (<Content title="Изготвяне на справки и отчети">
        <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4">
            <ReportBox variant="info" type="Справка" description="Издадени полици и сметки за период" icon="tasks"
                       url="/reports/sales" />
            <ReportBox variant="success" type="Справка СБА" description="Издадени СБА карти и сметки за период"
                       icon="life-ring" url="/reports/uab-sales" />
            <ReportBox variant="warning" type="Предстоящи" description=" Плащания или изтичащи полици за период"
                       icon="money-bill-1-wave" url="/reports/pending-payments" />
        </Row>
        <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4">
            <RequireRole name="ROLE_SHOW_COMMISSION">
                <ReportBox variant="success" type="Отчет" description="Застрахователни компании" icon="clipboard-list"
                           url="/reports/insurance-company" />
            </RequireRole>
            <RequireRole name="ROLE_SALES_REPORT">
                <ReportBox variant="warning" type="Отчет" description="Плащания" icon="rectangle-list"
                           url="/reports/payments" />
            </RequireRole>
        </Row>
        <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4">
            <RequireRole name="ROLE_BROKER_MANAGER">
                <ReportBox variant="info" type="Дневник" description="на Застрахователния Брокер" icon="fas fa-book"
                           url="/reports/journal" />
                <ReportBox variant="danger" type="КФН" description="Отчет КФН" icon="building-columns"
                           url="/reports/fsc-report" />
            </RequireRole>
        </Row>
    </Content>);
};

export default Reports;
