import React from 'react';
import {Button} from 'react-bootstrap';
import {copyText} from '../../../utils.js';
import PropTypes from 'prop-types';

const CopyButton = ({
    text,
    small,
}) => {
    if (!text || !text?.length) {
        return null;
    }

    return (<Button variant="outline-success" className={'text-success ms-1' + (small ? ' p-1' : '')}
        onClick={() => copyText(text)}>
        <i className="far fa-copy" />
    </Button>);
};

CopyButton.defaultProps = {
    small: false,
};

CopyButton.propTypes = {
    text: PropTypes.string,
    small: PropTypes.bool,
};

export default CopyButton;
