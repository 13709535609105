import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken.jsx';
import PropTypes from 'prop-types';
import {Button, Col, Container, FormControl, FormLabel, InputGroup, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues.js';
import AutoSubmitToken from '../../elements/Formik/AutoSubmitToken.jsx';
import {matchPolicyNumbers} from '../../../utils.js';
import FieldError from '../../elements/ReactSelect/FieldError.jsx';

const PoliciesSearchWidget = ({
    children,

    autoFocus,
    autoSubmit,
    findButtonLabel,
    findButtonIcon,
    formName,
    onSubmit,
    defaultFormInitialValues,
    validationFunction,
    searchWithClientPin,
}) => {
    const formInitialValues = useFormPersistedValues(formName);

    return (
        <Formik
            initialValues={formInitialValues || {
                ...({
                    policyNumbers: '',
                    clientPin: null,
                }),
                ...(defaultFormInitialValues ? defaultFormInitialValues : {})
            }}
            validate={values => {
                const errors = {};

                if (matchPolicyNumbers(values.policyNumbers).length === 0) {
                    errors.policyNumbers = 'Моля въведете поне един номер на полица';
                }

                return {
                    ...errors,
                    ...(validationFunction ? validationFunction(values) : {}),
                };
            }}
            onSubmit={onSubmit}>
            {({isSubmitting, values}) => {
                const policyNumbers = matchPolicyNumbers(values.policyNumbers);
                const policiesCount = policyNumbers.length;
                const findButtonInfo = policiesCount > 0 ?
                    ('(' + policiesCount + ' полиц' + (policiesCount === 1 ? 'а' : 'и') + ')') : null;

                return (<Container as={Form} className="gx-0">
                    <Row className="row-cols-1 g-3 align-items-end">
                        <Col>
                            <FormLabel htmlFor="policyNumbers">Номера на полици</FormLabel>
                            <InputGroup>
                                <FormControl as={Field} component="textarea" type="textarea" name="policyNumbers"
                                    id="policyNumbers" required disabled={isSubmitting} autoFocus={autoFocus}/>
                                <ErrorMessage name="policyNumbers" component={FieldError} />
                            </InputGroup>
                        </Col>
                        {searchWithClientPin && <Col>
                            <FormLabel htmlFor="clientPin">ЕГН, ЛНЧ или ЕИК на клиент по полиците</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="fas fa-person-circle-plus"/>
                                </InputGroup.Text>
                                <FormControl as={Field} name="clientPin" id="clientPin"
                                    disabled={isSubmitting} autoFocus={autoFocus}/>
                            </InputGroup>
                        </Col>}
                        <Col>
                            <Button variant="success" type="submit" disabled={isSubmitting} className="w-100">
                                <i className={findButtonIcon} /> {findButtonLabel} {findButtonInfo}
                            </Button>
                        </Col>
                        {children}
                    </Row>
                    {autoSubmit && <AutoSubmitToken />}
                    <AutoPersistToken formName={formName} />
                </Container>)
            }}
        </Formik>
    );
};

PoliciesSearchWidget.propTypes = {
    formName: PropTypes.string.isRequired,
    findButtonLabel: PropTypes.node,
    findButtonIcon: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    autoSubmit: PropTypes.bool,
    defaultFormInitialValues: PropTypes.object,
    validationFunction: PropTypes.func,
    searchWithClientPin: PropTypes.bool,
};

PoliciesSearchWidget.defaultProps = {
    autoFocus: false,
    autoSubmit: false,
    findButtonLabel: 'Търсене',
    findButtonIcon: 'fas fa-search',
    searchWithClientPin: false,
};

export default PoliciesSearchWidget;
