import React, {useState} from 'react';
import {Accordion, Button, Col, Container, FormLabel, InputGroup, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import WeekSelectionWidget from '../../widgets/Report/Selection/WeekSelectionWidget';
import DateRangeSelectionWidget from '../../widgets/Report/Selection/DateRangeSelectionWidget';
import {useLazyListVouchersQuery} from '../../../features/apiSlice';
import ErrorWidget from '../../widgets/ErrorWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import {useNavigate, useParams} from 'react-router-dom';
import Content from '../../partials/Content.jsx';
import ReportGridWidget from '../../widgets/Report/ReportGridWidget';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const Sales = () => {
    const {action} = useParams();
    const navigate = useNavigate();
    const gridRef = React.createRef();
    const [showExtraControls, setShowExtraControls] = useState(false);

    const [generate, {
        data: vouchers,
        error,
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
    }] = useLazyListVouchersQuery();

    return (<Content title="Издадени полици и сметки за период">
        <Row className="row-cols-1 gy-3 flex-grow-1 flex-column">
            <Col>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Критерии на справката</Accordion.Header>
                        <Accordion.Body>
                            <Container fluid className="px-0">
                                <Row className="row-cols-1 row-cols-sm-2 gy-3">
                                    <Col>
                                        <WeekSelectionWidget onSubmit={values => {
                                            navigate('/reports/sales', {
                                                replace: true,
                                            });

                                            return generate(values);
                                        }} autoSubmit={action === 'generate'} />
                                    </Col>
                                    <Col>
                                        <DateRangeSelectionWidget onSubmit={values => generate(values)} />
                                    </Col>
                                    <Col sm={12}>
                                        <span>Шаблони</span>
                                        <Button variant="outline-secondary" className="ms-2" onClick={() => {
                                            if (!gridRef.current) {
                                                return;
                                            }

                                            const {api, columnApi} = gridRef.current;
                                            columnApi.applyColumnState({
                                                state: [{
                                                    colId: 'ag-Grid-AutoColumn',
                                                    width: 250,
                                                }, {
                                                    colId: 'status',
                                                    rowGroupIndex: 0,
                                                    hide: true,
                                                }, {
                                                    colId: 'paymentDocumentType',
                                                    rowGroupIndex: 1,
                                                    hide: true,
                                                }, {
                                                    colId: 'policy.insuranceCompany.shortName',
                                                    rowGroupIndex: 2,
                                                    sort: 'asc',
                                                    hide: true,
                                                }, {
                                                    colId: 'totalAmount',
                                                }, {
                                                    colId: 'commissionAmount',
                                                }, {
                                                    colId: 'policy.number',
                                                }],
                                                applyOrder: true,
                                                defaultState: {
                                                    hide: false,
                                                    rowGroupIndex: null,
                                                    sort: null,
                                                },
                                            });

                                            api.forEachNode(node => {
                                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                                            });
                                        }}>Отчет ЗК</Button>
                                        <Button variant="outline-secondary" className="ms-2" onClick={() => {
                                            if (!gridRef.current) {
                                                return;
                                            }

                                            const {api, columnApi} = gridRef.current;
                                            columnApi.applyColumnState({
                                                state: [{
                                                    colId: 'ag-Grid-AutoColumn',
                                                    width: 250,
                                                }, {
                                                    colId: 'status',
                                                    rowGroupIndex: 0,
                                                    hide: true,
                                                }, {
                                                    colId: 'paymentMethod',
                                                    rowGroupIndex: 1,
                                                    hide: true,
                                                }, {
                                                    colId: 'issueOffice.name',
                                                    rowGroupIndex: 2,
                                                    sort: 'asc',
                                                    hide: true,
                                                }, {
                                                    colId: 'totalAmount',
                                                }, {
                                                    colId: 'userCommissionAmount',
                                                }, {
                                                    colId: 'correctionAmount',
                                                }, {
                                                    colId: 'policy.number',
                                                }],
                                                applyOrder: true,
                                                defaultState: {
                                                    hide: false,
                                                    rowGroupIndex: null,
                                                    sort: null,
                                                },
                                            });

                                            api.forEachNode(node => {
                                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                                            });
                                        }}>Отчет офиси</Button>
                                        <Button variant="outline-secondary" className="ms-2" onClick={() => {
                                            if (!gridRef.current) {
                                                return;
                                            }

                                            const {api, columnApi} = gridRef.current;
                                            columnApi.applyColumnState({
                                                state: [{
                                                    colId: 'issueOffice.name',
                                                    hide: false,
                                                }, {
                                                    colId: 'policy.productName',
                                                    hide: false,
                                                }, {
                                                    colId: 'policy.insuranceCompany.shortName',
                                                    hide: false,
                                                }, {
                                                    colId: 'policy.number',
                                                    hide: false,
                                                }, {
                                                    colId: 'insuredClientNames',
                                                    hide: false,
                                                }, {
                                                    colId: 'insuredClientPins',
                                                    hide: false,
                                                }, {
                                                    colId: 'voucherNumber',
                                                    hide: false,
                                                }, {
                                                    colId: 'issueDate',
                                                    hide: false,
                                                }, {
                                                    colId: 'installments',
                                                    hide: false,
                                                }, {
                                                    colId: 'sticker.number',
                                                    hide: false,
                                                }, {
                                                    colId: 'greenCard.number',
                                                    hide: false,
                                                }, {
                                                    colId: 'premiumAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'guaranteeFundAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'stickerAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'taxAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'userCommissionPercent',
                                                    hide: false,
                                                }, {
                                                    colId: 'userCommissionAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'totalAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'commissionPercent',
                                                    hide: false,
                                                }, {
                                                    colId: 'commissionAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'differenceBrokerAgentPercent',
                                                    hide: false,
                                                }, {
                                                    colId: 'differenceBrokerAgentAmount',
                                                    hide: false,
                                                }, {
                                                    colId: 'correctionPercent',
                                                    hide: false,
                                                }, {
                                                    colId: 'correctionAmount',
                                                    hide: false,
                                                }],
                                                applyOrder: true,
                                                defaultState: {
                                                    hide: true,
                                                    rowGroupIndex: null,
                                                    sort: null,
                                                },
                                            });

                                            api.forEachNode(node => {
                                                node.setExpanded(node.rowGroupIndex !== null && node.rowGroupIndex < 2);
                                            });
                                        }}>Отчет месечен</Button>

                                        <Button variant="outline-secondary" className="ms-2" onClick={() => {
                                            if (!gridRef.current) {
                                                return;
                                            }

                                            const {columnApi} = gridRef.current;
                                            columnApi.resetColumnState();
                                        }}>Нулиране</Button>
                                        <InputGroup className="d-inline ms-2">
                                            <input type="checkbox" checked={showExtraControls} id="showExtraControls"
                                                   onChange={e => setShowExtraControls(e.target.checked)} />
                                            <FormLabel htmlFor="showExtraControls"
                                                       className="ms-1">Допълнителни настройки</FormLabel>
                                        </InputGroup>
{/*
                                        <Button className="ms-2" onClick={() => {
                                            if (!gridRef.current) {
                                                return;
                                            }

                                            const {columnApi} = gridRef.current;

                                            console.log(columnApi.getColumnState());
                                        }}>get state</Button>
*/}
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
            <Col className="flex-grow-1">
                {isUninitialized && <Alert variant="secondary"
                                           heading="Моля, изберете критерии за изготвянето на отчета." />}
                {isError && <ErrorWidget error={error} />}
                {isLoading && <Card><SmallSpinner /></Card>}
                {isSuccess && <ReportGridWidget rowData={vouchers} ref={gridRef}
                                                showExtraControls={showExtraControls} />}
            </Col>
        </Row>
    </Content>);
};

export default Sales;
