import React, {useMemo} from 'react';
import {createFilter} from 'react-select';
import {useGetCurrentInsuranceBrokerQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import SelectField from '../ReactSelect/SelectField';
import {Field, useFormikContext} from 'formik';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const UserSelector = ({
    defaultValueFilter,

    ...props
}) => {
    const {
        data: insuranceBroker,
        error: insuranceBrokerError,
        isLoading: insuranceBrokerIsLoading,
        isSuccess: insuranceBrokerIsSuccess,
        isError: insuranceBrokerIsError,
    } = useGetCurrentInsuranceBrokerQuery();

    const users = useMemo(
        () => insuranceBrokerIsSuccess ? insuranceBroker.users.filter(
            user => user.active
        ).map(user => ({
            value: user.id,
            label: user.firstName + ' ' + user.lastName,
        })).sort((a, b) => a.label.localeCompare(b.label)) : [],
        [insuranceBrokerIsSuccess, insuranceBroker]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {insuranceBrokerIsLoading && <SmallSpinner />}
        {insuranceBrokerIsSuccess && <Field component={SelectField} options={users}
            disabled={isSubmitting}
            placeholder={props.referrer ? 'Няма' : props.required ? 'Изберете потребител' : 'Всички потребители'}
            defaultValue={defaultValueFilter ? defaultValueFilter(users) : []}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {insuranceBrokerIsError && <ErrorIcon error={insuranceBrokerError} />}
    </>);
};

UserSelector.defaultProps = {
    name: 'user',
    required: false,
    referrer: false,
    isClearable: false,
};

UserSelector.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    referrer: PropTypes.bool,
    isClearable: PropTypes.bool,
    defaultValueFilter: PropTypes.func,
};

export default UserSelector;
