import React from 'react';
import {useGetVersionQuery} from '../../features/apiSlice';

const MainFooter = () => {
    const {
        data: version,
        isSuccess,
    } = useGetVersionQuery();

    return (
        <footer className="main-footer">
            <div className="float-end d-none d-sm-inline">
                Версия: {isSuccess && version.buildDate}-{isSuccess && version.gitHash.substr(0, 8)}
                {isSuccess && import.meta.env.VITE_GIT_HASH && '-'}
                {(import.meta.env.VITE_GIT_HASH || '').substr(0, 8)}
            </div>
            <strong>
                &copy; {(new Date()).getFullYear()}
                {// eslint-disable-next-line react/jsx-no-target-blank
                } <a href="https://intelekt.bg/" target="_blank">БГ ИНТЕЛЕКТ ЕООД</a>.
            </strong>
            {' '}
            Всички права запазени.
        </footer>
    );
};

export default MainFooter;
