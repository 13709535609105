import React from 'react';
import {Button, FormControl, InputGroup} from 'react-bootstrap';
import {Field, useFormikContext} from 'formik';
import {
    useListAssistanceCardsQuery,
    useListGreenCardsQuery,
    useListStickersQuery,
    useListUabCardsQuery
} from '../../../../features/apiSlice.js';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';
import ErrorIcon from '../../../elements/Icons/ErrorIcon.jsx';
import PropTypes from 'prop-types';

const BsoCount = ({
    sendOfficeId,
}) => {
    const { isSubmitting, values, setFieldValue } = useFormikContext();

    const isStickers = values.bsoType === 'stickers';
    const isGreenCards = values.bsoType === 'greenCards';
    const isUabCards = values.bsoType === 'uabCards';
    const isAssistanceCards = values.bsoType === 'assistanceCards';

    const showCount = ((isStickers || isGreenCards || isAssistanceCards) && values.insuranceCompany) || (isUabCards && values.type);

    const {
        data: stickersList,
        error: stickersListError,
        isLoading: stickersListLoading,
        isFetching: stickersListFetching,
        isSuccess: stickersListSuccess,
    } = useListStickersQuery({
        insuranceCompany: values.insuranceCompany,
        office: sendOfficeId,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !showCount || !isStickers,
    });

    const {
        data: greenCardsList,
        error: greenCardsListError,
        isLoading: greenCardsListLoading,
        isFetching: greenCardsListFetching,
        isSuccess: greenCardsListSuccess,
    } = useListGreenCardsQuery({
        insuranceCompany: values.insuranceCompany,
        office: sendOfficeId,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !showCount || !isGreenCards,
    });

    const {
        data: uabCardsList,
        error: uabCardsListError,
        isLoading: uabCardsListLoading,
        isFetching: uabCardsListFetching,
        isSuccess: uabCardsListSuccess,
    } = useListUabCardsQuery({
        office: sendOfficeId,
        type: values.type,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !showCount || !isUabCards,
    });

    const {
        data: assistanceCardsList,
        error: assistanceCardsListError,
        isLoading: assistanceCardsListLoading,
        isFetching: assistanceCardsListFetching,
        isSuccess: assistanceCardsListSuccess,
    } = useListAssistanceCardsQuery({
        insuranceCompany: values.insuranceCompany,
        office: sendOfficeId,
        series: values.series,
        assigned: false,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    }, {
        skip: !showCount || !isAssistanceCards,
    });

    const isLoading = stickersListLoading || stickersListFetching || greenCardsListLoading || greenCardsListFetching ||
        uabCardsListLoading || uabCardsListFetching || assistanceCardsListLoading || assistanceCardsListFetching;
    const error = stickersListError || greenCardsListError || uabCardsListError || assistanceCardsListError;

    const stickersCount = stickersListSuccess ? stickersList.length : 0;
    const greenCardsCount = greenCardsListSuccess ? greenCardsList.length : 0;
    const uabCardsCount = uabCardsListSuccess ? uabCardsList.length : 0;
    const assistanceCardsCount = assistanceCardsListSuccess ? assistanceCardsList.length : 0;

    const max = isStickers ? stickersCount : (
        isGreenCards ? greenCardsCount : (
            isUabCards ? uabCardsCount : (
                isAssistanceCards ? assistanceCardsCount : null
            )
        )
    );

    if (!showCount) {
        return null;
    }

    if (isLoading) {
        return (<SmallSpinner />);
    }

    if (error) {
        return (<ErrorIcon error={error} />);
    }

    if (max === 0) {
        return 'Няма налични бланки';
    }

    const min = 0;

    return (<InputGroup>
        <InputGroup.Text>Брой</InputGroup.Text>
        <FormControl as={Field} type="number" name="count" min={min} max={max} disabled={isSubmitting}
            className="text-end" />
        <div className="input-group-append">
            <Button variant="outline-success" className="rounded-start-0" onClick={() =>
                setFieldValue('count', max)}>от {max}</Button>
        </div>
    </InputGroup>);
};

BsoCount.propTypes = {
    sendOfficeId: PropTypes.string.isRequired,
};

export default BsoCount;
