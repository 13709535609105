import {sum} from "../../../utils";
import PropTypes from "prop-types";
import PremiumAmount from "./PremiumAmount.jsx";

const PremiumAmountSum = ({
    arr,
    field,
    defaultValue,
}) => {
    return <PremiumAmount amount={sum(arr.map(item => item[field] || defaultValue))} />;
};

PremiumAmountSum.defaultProps = {
    defaultValue: 0,
};

PremiumAmountSum.propTypes = {
    arr: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    field: PropTypes.string.isRequired,
    defaultValue: PropTypes.number,
};

export default PremiumAmountSum;
