import React, {useMemo} from 'react';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import {
    useGetValuationCertificateInventoryQuery,
    useListAssistanceCardsQuery,
    useListGreenCardsQuery,
    useListStickersQuery,
} from '../../../features/apiSlice';
import StickerInfoButton from '../NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from '../NavigationButtons/GreenCardInfoButton';
import ErrorIcon from '../../elements/Icons/ErrorIcon';
import PropTypes from 'prop-types';
import ValuationCertificateInfoButton from '../NavigationButtons/ValuationCertificateInfoButton';
import {allowValuationCertificates} from '../../../utils';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import AssistanceCardInfoButton from "../NavigationButtons/AssistanceCardInfoButton.jsx";

const InsuranceCompanyBsoInventoryWidget = ({
    insuranceCompany,
    office,
    defaultOffice,
}) => {
    const hasValuationCertificates = useMemo(
        () => allowValuationCertificates(insuranceCompany),
        [insuranceCompany],
    );

    const hasAssistanceCards = insuranceCompany.shortName === 'Булстрад';

    const {
        data: stickers,
        error: stickersError,
        isError: stickersIsError,
        isLoading: stickersIsLoading,
        isFetching: stickersIsFetching,
        isSuccess: stickersIsSuccess,
    } = useListStickersQuery({
        insuranceCompany: insuranceCompany.id,
        office: office ? office : defaultOffice,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    });

    const {
        data: greenCards,
        error: greenCardsError,
        isError: greenCardsIsError,
        isLoading: greenCardsIsLoading,
        isFetching: greenCardsIsFetching,
        isSuccess: greenCardsIsSuccess,
    } = useListGreenCardsQuery({
        insuranceCompany: insuranceCompany.id,
        office: office ? office : defaultOffice,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    });

    const {
        data: assistanceCards,
        error: assistanceCardsError,
        isSuccess: assistanceCardsIsSuccess,
        isError: assistanceCardsIsError,
        isLoading: assistanceCardsIsLoading,
        isFetching: assistanceCardsIsFetching,
    } = useListAssistanceCardsQuery({
        insuranceCompany: insuranceCompany.id,
        office: office ? office : defaultOffice,
        expended: false,
        inTransit: false,
        defective: false,
        lost: false,
        returned: false,
        reserved: false,
    });

    const {
        data: valuationCertificates,
        error: valuationCertificatesError,
        isSuccess: valuationCertificatesIsSuccess,
        isError: valuationCertificatesIsError,
        isLoading: valuationCertificatesIsLoading,
        isFetching: valuationCertificatesIsFetching,
    } = useGetValuationCertificateInventoryQuery([insuranceCompany.id, office ? office : defaultOffice], {
        skip: !hasValuationCertificates,
    });

    const stickersLoading = stickersIsLoading || stickersIsFetching;
    const stickersSuccess = stickersIsSuccess && !stickersIsFetching;

    const greenCardsLoading = greenCardsIsLoading || greenCardsIsFetching;
    const greenCardsSuccess = greenCardsIsSuccess && !greenCardsIsFetching;

    const assistanceCardsLoading = assistanceCardsIsLoading || assistanceCardsIsFetching;
    const assistanceCardsSuccess = assistanceCardsIsSuccess && !assistanceCardsIsFetching;

    const valuationCertificatesLoading = valuationCertificatesIsLoading || valuationCertificatesIsFetching;
    const valuationCertificatesSuccess = valuationCertificatesIsSuccess && !valuationCertificatesIsFetching;

    return (<Accordion.Item eventKey={insuranceCompany.id}>
        <Accordion.Header>
            <Container fluid className="gx-0">
                <Row>
                    <Col>
                        <strong>{insuranceCompany.shortName}</strong>
                    </Col>
                    <Col>
                        Стикери:{' '}
                        {stickersLoading && <SmallSpinner />}
                        {stickersIsError && <ErrorIcon error={stickersError} />}
                        {stickersSuccess && stickers.length}
                        {' / '}СЗК:{' '}
                        {greenCardsLoading && <SmallSpinner />}
                        {greenCardsIsError && <ErrorIcon error={greenCardsError} />}
                        {greenCardsSuccess && greenCards.length}
                        {hasValuationCertificates && <>
                            {' / '}УЗС:{' '}
                            {valuationCertificatesLoading && <SmallSpinner />}
                            {valuationCertificatesIsError && <ErrorIcon error={valuationCertificatesError} />}
                            {valuationCertificatesSuccess && valuationCertificates.length}
                        </>}
                        {hasAssistanceCards && <>
                            {' / '}АСК:{' '}
                            {assistanceCardsLoading && <SmallSpinner />}
                            {assistanceCardsIsError && <ErrorIcon error={assistanceCardsError} />}
                            {assistanceCardsSuccess && assistanceCards.length}
                        </>}
                    </Col>
                </Row>
            </Container>
        </Accordion.Header>
        <Accordion.Body>
            {stickersSuccess && stickers.length === 0 &&
                greenCardsSuccess && greenCards.length === 0 && ((
                    hasValuationCertificates && valuationCertificatesSuccess &&
                    valuationCertificates.length === 0
                ) || !hasValuationCertificates) && ((
                    hasAssistanceCards && assistanceCardsSuccess &&
                    assistanceCards.length === 0
                ) || !hasAssistanceCards) &&
                'Няма налични бланки на ' + insuranceCompany.shortName
            }
            {stickersSuccess && stickers.length > 0 && <Row>
                <Col className="col-12">Стикери:</Col>
                {stickersLoading && <SmallSpinner />}
                {stickersSuccess && stickers.map(sticker => <Col className="mb-1" key={sticker.id}>
                    <StickerInfoButton key={sticker.id} sticker={sticker} />
                </Col>)}
            </Row>}
            {greenCardsSuccess && greenCards.length > 0 && <Row>
                <Col className="col-12">СЗК:</Col>
                {greenCardsLoading && <SmallSpinner />}
                {greenCardsSuccess && greenCards.map(greenCard => <Col className="mb-1" key={greenCard.id}>
                    <GreenCardInfoButton key={greenCard.id} greenCard={greenCard} />
                </Col>)}
            </Row>}
            {hasValuationCertificates && valuationCertificatesSuccess &&
                valuationCertificates.length > 0 && <Row>
                    <Col className="col-12">УЗС:</Col>
                    {valuationCertificatesLoading && <SmallSpinner />}
                {valuationCertificates.map(valuationCertificate => <Col className="mb-1"  key={valuationCertificate.id}>
                        <ValuationCertificateInfoButton key={valuationCertificate.id}
                                                        valuationCertificate={valuationCertificate} />
                    </Col>)}
            </Row>}
            {hasAssistanceCards && assistanceCardsSuccess &&
                assistanceCards.length > 0 && <Row>
                    <Col className="col-12">АСК:</Col>
                    {assistanceCardsLoading && <SmallSpinner />}
                    {assistanceCards.map(assistanceCard => <Col className="mb-1"  key={assistanceCard.id}>
                        <AssistanceCardInfoButton key={assistanceCard.id} assistanceCard={assistanceCard} />
                    </Col>)}
                </Row>}
        </Accordion.Body>
    </Accordion.Item>);
};

InsuranceCompanyBsoInventoryWidget.propTypes = {
    insuranceCompany: PropTypes.shape({
        id: PropTypes.string.isRequired,
        shortName: PropTypes.string.isRequired,
    }).isRequired,
    office: PropTypes.string,
    defaultOffice: PropTypes.string,
};

export default InsuranceCompanyBsoInventoryWidget;
